import { Component, Input, OnInit } from "@angular/core"
import { StaticImageElement } from "@checkd-form/models/form-element"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { ImageModule } from "primeng/image"

@Component({
  selector: "app-image-element",
  templateUrl: "./image-element.component.html",
  styleUrls: ["./image-element.component.scss"],
  standalone: true,
  imports: [ImageModule],
})
export class ImageElementComponent extends DefaultElementComponent implements OnInit {
  @Input() imageUrl: string

  override ngOnInit() {
    const element = this.element as StaticImageElement
    this.imageUrl = element.url || element.value
  }
}
