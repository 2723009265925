import { NgIf } from "@angular/common"
import { Component, EventEmitter, Input, Output } from "@angular/core"
import { MatButtonModule } from "@angular/material/button"
import { MatIconModule } from "@angular/material/icon"
import { Form } from "@checkd-form/models/form"

import { GeneralReport } from "@models/common/general-report"

@Component({
  selector: "checkd-report-footer",
  templateUrl: "./report-footer.component.html",
  styleUrls: ["./report-footer.component.scss"],
  standalone: true,
  imports: [MatButtonModule, MatIconModule, NgIf],
})
export class ReportFooterComponent {
  @Input() form: Form
  @Input() report: GeneralReport
  @Input() hasReportLockFeature: boolean
  @Input() canLockAndUnlockReport: boolean
  @Input() isFormChanged: boolean
  @Input() canShareReport: boolean

  @Output() previewPdf = new EventEmitter<void>()
  @Output() lockReport = new EventEmitter<void>()
  @Output() save = new EventEmitter<void>()
  @Output() shareLink = new EventEmitter<void>()
  @Output() sendEmail = new EventEmitter<void>()

  onPreviewPdf() {
    this.previewPdf.emit()
  }

  onLockReport() {
    this.lockReport.emit()
  }

  onSaveForm() {
    this.save.emit()
  }

  onShareLink() {
    this.shareLink.emit()
  }

  onSendEmail() {
    this.sendEmail.emit()
  }
}
