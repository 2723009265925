import { Component, forwardRef } from "@angular/core"
import { getCorrectOptionValue } from "@checkd-form/models/utilities"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { FormElementComponent } from "../form-element/form-element.component"
import { NgIf, NgFor } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { MatLegacySlideToggleModule } from "@angular/material/legacy-slide-toggle"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "app-toggle-element",
  templateUrl: "./toggle-element.component.html",
  styleUrls: ["./toggle-element.component.scss"],
  standalone: true,
  imports: [FlexModule, MatLegacySlideToggleModule, FormsModule, NgIf, NgFor, forwardRef(() => FormElementComponent)],
})
export class ToggleElementComponent extends DefaultElementComponent {
  override get value() {
    return getCorrectOptionValue(this.getValue()) === true
  }

  override set value(checked: boolean) {
    this.setValue(checked)
  }

  isConditional() {
    const value = this.getOption("conditional")!.value

    return getCorrectOptionValue(value) === true
  }
}
