import { Component, OnInit } from "@angular/core"
import { MatDialogRef } from "@angular/material/dialog"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "app-add-option-dialog",
  templateUrl: "./add-option-dialog.component.html",
  styleUrls: ["./add-option-dialog.component.scss"],
  standalone: true,
  imports: [FlexModule, MatLegacyFormFieldModule, MatLegacyInputModule, MatLegacyButtonModule],
})
export class AddOptionDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AddOptionDialogComponent>) {}

  ngOnInit() {}
}
