import { Injectable } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { Observable } from "rxjs"

import { map, mergeMap } from "rxjs/operators"

import { naturalSortObjectsByProperty } from "./utilities"

import { COLLECTIONS, Company, CompanyFeatures, LABELS, Person, Project, Relation, SortingDirection } from "@models/common"
import { ModelService } from "./model.service"
// Importing directly (ignoring barrel) to prevent runtime cyclic dependencies
import { RelationService } from "./relation.service"

import { FilestackService } from "../services/filestack.service"
import { ImageService } from "../services/image.service"

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  constructor(
    private afAuth: AngularFireAuth,
    private relationService: RelationService,
    private db: AngularFirestore,
    private modelService: ModelService,
    private imageService: ImageService,
    private filestack: FilestackService
  ) {}

  listenToUid(companyUid: string): Observable<Company> {
    return this.modelService.listenTo(Company.COLLECTION, companyUid) as Observable<Company>
  }

  listenToPeople(company: Company): Observable<Person[]> {
    return this.relationService
      .listenToTargets(company, COLLECTIONS.PEOPLE)
      .pipe(map((members) => naturalSortObjectsByProperty(members, "name"))) as Observable<Person[]>
  }

  listenToProjects(company: Company): Observable<Project[]> {
    return this.relationService.listenToTargets(company, COLLECTIONS.PROJECTS) as Observable<Project[]>

    /*
    return this.modelService.queryAndListen({
      collection: COLLECTIONS.PROJECTS,
      aggregateData: { companyUid: company.uid },
    })
     */
  }

  listenToParentCompanies(company: Company) {
    return this.relationService.listenToTargets(company, Company.COLLECTION, [LABELS.HAS_PARENT_COMPANY])
  }

  listenToChildCompanies(company: Company) {
    return this.relationService.listenToTargets(company, Company.COLLECTION, [LABELS.IS_PARENT_COMPANY_FOR])
  }

  listenToAssociatedPeople(company: Company): Observable<Person[]> {
    // @ts-ignore
    return this.listenToUid(company.uid).pipe(
      map((company: Company) => {
        if (company.features.includes(CompanyFeatures.ASSOCIATES)) {
          return company.associatedPeople.map((personUid) => this.modelService.listenTo(Person.COLLECTION, personUid))
        }

        return [] as Person[]
      }),
      // @ts-ignore
      mergeMap((people) => this.relationService.combineLatestOrEmptyList(people))
    )
  }

  listenToTemplates(company: Company) {
    return this.relationService
      .listenToTargets(company, COLLECTIONS.LEGACY_TEMPLATES)
      .pipe(map((templates) => naturalSortObjectsByProperty(templates, "name", SortingDirection.ASC)))
  }

  listenToDisabledTemplates(company: Company) {
    return this.relationService.listenToDisabledTargets(company, COLLECTIONS.LEGACY_TEMPLATES)
  }

  listenToInactiveTemplates(company: Company) {
    return this.relationService.listenToDisabledTargets(company, COLLECTIONS.LEGACY_TEMPLATES, [LABELS.INACTIVE])
  }

  addProject(company: Company, project: Project): Promise<Company> {
    return company
      .add(project, [Relation.LABELS.CONTAINS])
      .then((_) => project.add(company, [Relation.LABELS.CONTAINED_BY]))
      .then((_) => company)
  }

  getCompanyRelations$(projectUid: string, collectionName: string) {
    return this.db.collection(Company.COLLECTION).doc(projectUid).collection(collectionName).valueChanges()
  }

  updateCompanyTags(tags: string[], companyUid: string) {
    return this.db.collection(COLLECTIONS.COMPANIES).doc(companyUid).ref.update({ tags })
  }

  updateCompanyData(data: any, companyUid: string) {
    return this.db.collection(COLLECTIONS.COMPANIES).doc(companyUid).ref.update(data)
  }

  createCompanyLogoThumbnailUrl(logoUrl: string) {
    return "" + this.filestack.getThumbnailUrl(logoUrl, 300, false)
  }

  logoUrlToBase64(logoUrl: string): Promise<string> {
    if (!logoUrl) {
      return Promise.resolve("")
    }

    const companyLogoUrl = this.createCompanyLogoThumbnailUrl(logoUrl)

    return new Promise((resolve, reject) => {
      this.imageService.convertFileToDataURLviaFileReaderPromise(companyLogoUrl).then((data) => {
        if (data.includes("image")) {
          resolve(data)
        } else {
          reject("Error converting company logo to base64")
        }
      })
    })
  }

  getCompanyLogoBase64(company: Company): Promise<string> {
    if (company.logo == null || company.logo.trim() === "") {
      return Promise.resolve("")
    }

    return this.logoUrlToBase64(company.logo)
  }
}
