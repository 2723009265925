import { Component, forwardRef, OnDestroy, OnInit } from "@angular/core"
import { SafeUrl } from "@angular/platform-browser"

import { DrawingData, ItemReportData } from "@models/common"
import { combineLatest, Observable, of as observableOf, Subscription } from "rxjs"
import { map, switchMap } from "rxjs/operators"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { FormElementComponent } from "../form-element/form-element.component"
import { MatLegacyProgressSpinnerModule } from "@angular/material/legacy-progress-spinner"
import { ReportDrawingComponent } from "../../../../reports/drawing-report/report-drawing/report-drawing.component"
import { FormsModule } from "@angular/forms"
import { MatLegacySlideToggleModule } from "@angular/material/legacy-slide-toggle"
import { NgIf, NgFor, AsyncPipe } from "@angular/common"

@Component({
  selector: "app-drawing-element",
  templateUrl: "./drawing-element.component.html",
  styleUrls: ["./drawing-element.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    MatLegacySlideToggleModule,
    FormsModule,
    ReportDrawingComponent,
    MatLegacyProgressSpinnerModule,
    NgFor,
    forwardRef(() => FormElementComponent),
    AsyncPipe,
  ],
})
export class DrawingElementComponent extends DefaultElementComponent implements OnInit, OnDestroy {
  drawingUrl: SafeUrl
  interactiveDrawing = false

  readonly reportSettings$: Observable<any> = this.formMessageService.config$.pipe(map((config) => config.reportSettings))

  readonly pdfFieldsSettings$: Observable<any> = this.formMessageService.config$.pipe(map((config) => config.pdfFieldsSettings))

  readonly showDrawing$: Observable<boolean> = this.pdfFieldsSettings$.pipe(map((config) => config.drawing))

  readonly drawingUrl$: Observable<string> = this.reportSettings$.pipe(
    map((config) => config.reportUid),
    switchMap((reportUid) =>
      reportUid ? this.storage.ref(`reports/${reportUid}/${this.drawing.name}.png`).getDownloadURL() : observableOf("")
    )
  )

  subscriptions: Subscription[]

  override ngOnInit() {
    this.setupSubscriptions()
  }

  setupSubscriptions() {
    this.subscriptions = [
      combineLatest([this.drawingUrl$, this.showDrawing$]).subscribe(
        ([url, showDrawing]) => {
          if (showDrawing && url) {
            this.drawingUrl = this.filestackService.getCompressedImage(url)
          }
        },
        (error) => {
          // Fix to avoid sending this exception to Sentry
          if (error.code === "storage/object-not-found") {
          } else throw error
        }
      ),
    ]
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  get drawing() {
    return this.element.value as DrawingData
  }
  get drawingWidth() {
    return this.drawing.width
  }
  get drawingHeight() {
    return this.drawing.height
  }
  get items() {
    return this.element.values![0].value as ItemReportData[]
  }
}
