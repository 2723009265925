import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import { PeopleListDialogComponent } from "@dialogs"
import {
  Company,
  CompanyFeatures,
  LegacyTemplate,
  Person,
  Project,
  ProjectData,
  ProjectDetailsData,
  Relation,
  Role,
  USER_PRIVATE_SUBCOLLECTION,
} from "@models/common"
import { TagCollection } from "@models/common/tag-collection"
import { CompanyService, ProjectService, SnackbarService, UserService } from "@services"
import { DateFormatPipe, FromUnixPipe } from "ngx-moment"
import { Observable, Subscription } from "rxjs"
import { switchMap, take } from "rxjs/operators"
import { ConnectNextProjectDialogComponent } from "../../dialogs/connect-next-project-dialog/connect-next-project-dialog.component"
import { NextExperimentalService } from "../../services/apis/next-experimental.service"
import { ProjectReportsService } from "../project-reports/project-reports.service"
import { ProjectTemplateService } from "../services"
import { PrimaryButtonDirective } from "../../next-ui/button/primary-button.directive"
import { TextFieldModule } from "@angular/cdk/text-field"
import { ProjectFormsNotificationsRulesComponent } from "../project-forms-notifications-rules/project-forms-notifications-rules.component"
import { ProjectTemplateListComponent } from "../project-template-list/project-template-list.component"
import { MatLegacyChipsModule } from "@angular/material/legacy-chips"
import { CheckdTagsComponent } from "../../checkd-ui/checkd-tags/checkd-tags.component"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { NgIf, NgFor } from "@angular/common"
import { SettingsGroupComponent } from "../../settings-group/settings-group.component"

@Component({
  selector: "checkd-project-settings",
  templateUrl: "./project-settings.component.html",
  styleUrls: ["./project-settings.component.scss"],
  standalone: true,
  imports: [
    SettingsGroupComponent,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyTooltipModule,
    CheckdTagsComponent,
    MatLegacyChipsModule,
    NgFor,
    ProjectTemplateListComponent,
    ProjectFormsNotificationsRulesComponent,
    TextFieldModule,
    PrimaryButtonDirective,
  ],
})
export class ProjectSettingsComponent implements OnInit, OnDestroy {
  @Input() project: Project
  @Input() projectTemplates: LegacyTemplate[] = []
  @Input() companyTemplates: LegacyTemplate[] = []
  @Input() projectCompanyTemplates: LegacyTemplate[] = []
  @Input() currentUser: Person
  @Input() currentCompany: Company
  @Input() projectCompany: Company
  @Input() projectOwners: Person[]
  @Input() projectOwnersAndAdmins: Person[]
  @Input() currentUserProjectRole: Role
  @Input() reportTagsCollection: TagCollection

  isInfoEditable: boolean = false
  isDescriptionEditable: boolean = false
  isDetailsEditable: boolean = false

  isTemplatesEditable: boolean = false

  projectInfo: UntypedFormGroup
  projectDescription: UntypedFormGroup
  projectDetails: UntypedFormGroup
  tags: string[] = []
  reportTagsChange: string[]

  public CompanyFeatures = CompanyFeatures

  private _templatesToRemove: LegacyTemplate[] = []
  private _templatesToAdd: LegacyTemplate[] = []
  private _hasPublicTemplates: boolean = false
  public currentlyConnectedNextProject: any

  // @ts-ignore
  public currentUserIntegrations$: Observable<IUserIntegrations> = this.userService.currentUser$.pipe(
    switchMap((user) => this.db.doc(user.ref!.path).collection(USER_PRIVATE_SUBCOLLECTION).doc("integrations").valueChanges())
  )

  subscriptions: Subscription[] = []

  constructor(
    private projectService: ProjectService,
    private projectTemplateService: ProjectTemplateService,
    private formBuilder: UntypedFormBuilder,
    private snackbarService: SnackbarService,
    public userService: UserService,
    private companyService: CompanyService,
    private projectReportsService: ProjectReportsService,
    public dialog: MatDialog,
    private db: AngularFirestore,
    public nextExperimentalService: NextExperimentalService
  ) {}

  get canAddProjectTemplates() {
    return this.project != null && this.projectTemplateService.canHaveProjectTemplates(this.project)
  }

  get canEditProjectTemplatesSection() {
    return this.currentCompany != null && this.projectCompany != null && this.currentCompany.uid === this.projectCompany.uid
  }

  get canEditProject() {
    return (
      this.currentUserProjectRole && this.currentUserProjectRole.canUpdateTarget() && !this.project.archived && !this.project.lockedByCheckd
    )
  }

  get companyNameText(): string {
    const name = this.projectCompany && this.projectCompany.name ? this.projectCompany.name : ""
    if (!name || name.trim().toLocaleLowerCase().includes("untitled")) {
      return " "
    }

    return this.currentCompany.uid === this.projectCompany.uid ? ` your company (${name}) ` : ` ${name} `
  }

  ngOnInit() {
    this.setupProjectInfo()
    this.setupProjectDescription()
    this.setupProjectDetails()
    this.setupProjectFields()
    this.setupNext()
  }

  setupNext() {
    this.subscriptions = [...this.subscriptions]
    if (this.project.nextProjectId) {
      this.nextExperimentalService.getNextProject(this.project.nextProjectId).subscribe((it) => {
        this.currentlyConnectedNextProject = it
      })
    }
  }

  async connectNextProjectClicked() {
    const result = await this.dialog.open(ConnectNextProjectDialogComponent, { data: {} }).afterClosed().toPromise()
    await this.nextExperimentalService.setNextProjectProjectId(result.id)
    this.snackbarService.showMessage(`Connected this project to ${result.name}`)
  }

  // hack to make sure that all identical template names show up
  compareTemplateChips(t1: LegacyTemplate, t2: LegacyTemplate) {
    return false
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  setupProjectFields() {
    this.projectInfo.setValue({
      address: this.project.location.address || "",
      name: this.project.name || "",
    })
    this.tags = this.project.tags || []

    this.projectDescription.setValue({
      description: this.project.description || "",
    })

    this._hasPublicTemplates = this.project.hasPublicTemplates

    if (this.project.details != null) {
      this.projectDetails.setValue({
        municipalNumber: this.project.details.municipalNumber || "",
        cadastrialUnitNumber: this.project.details.cadastrialUnitNumber || "",
        idNumber: this.project.details.idNumber || "",
        projectPhase: this.project.details.projectPhase || "",
        owner: this.project.details.owner || "",
        client: this.project.details.client || "",
        cost: this.project.details.cost || "",
      })
    }
  }

  setupProjectInfo() {
    this.projectInfo = this.formBuilder.group({
      address: "",
      name: "",
    })
    this.projectInfo.disable()
  }

  setupProjectDescription() {
    this.projectDescription = this.formBuilder.group({
      description: "",
    })
    this.projectDescription.disable()
  }

  setupProjectDetails() {
    this.projectDetails = this.formBuilder.group({
      municipalNumber: "",
      cadastrialUnitNumber: "",
      idNumber: "",
      projectPhase: "",
      owner: "",
      client: "",
      cost: "",
    })
    this.projectDetails.disable()
  }

  onEditable(event: boolean, dataType: string) {
    switch (dataType) {
      case "info":
        this.isInfoEditable = event

        return event ? this.projectInfo.enable() : this.projectInfo.disable()
      case "description":
        this.isDescriptionEditable = event

        return event ? this.projectDescription.enable() : this.projectDescription.disable()
      case "details":
        this.isDetailsEditable = event

        return event ? this.projectDetails.enable() : this.projectDetails.disable()
      case "templates":
        this.isTemplatesEditable = event

        return
      default:
        return
    }
  }

  handleError(error: any) {
    this.snackbarService.showMessage(`ERROR: ${error}`)
  }

  async save(dataType: string) {
    switch (dataType) {
      case "info":
        this.projectInfo.disable()

        if (this.reportTagsChange) {
          this.updateReportTags(this.reportTagsChange)
        }

        return this.updateInfo({
          location: { address: this.projectInfo.value.address },
          name: this.projectInfo.value.name,
          tags: this.tags,
        })
      case "description":
        this.projectDescription.disable()

        return this.updateDescription(this.projectDescription.value.description)
      case "details":
        this.projectDetails.disable()

        return this.updateDetails({
          municipalNumber: this.projectDetails.value.municipalNumber,
          cadastrialUnitNumber: this.projectDetails.value.cadastrialUnitNumber,
          idNumber: this.projectDetails.value.idNumber,
          projectPhase: this.projectDetails.value.projectPhase,
          owner: this.projectDetails.value.owner,
          client: this.projectDetails.value.client,
          cost: this.projectDetails.value.cost,
        })
      case "templates":
        await this.project.update({ hasPublicTemplates: this._hasPublicTemplates })

        if (this._templatesToAdd.length > 0 || this._templatesToRemove.length > 0) {
          this.snackbarService.showMessage("Updating project templates...")
          try {
            await this.projectTemplateService.addTemplatesToProject(this._templatesToAdd, this.project)
            await this.projectTemplateService.removeTemplatesFromProject(this._templatesToRemove, this.project)
          } catch (error) {
            this.handleError(error)
          }
          this.snackbarService.showMessage(
            `${this._templatesToAdd.length} template(s) added and
            ${this._templatesToRemove.length} template(s) removed from the project`
          )
        }

        return undefined
      default:
        return undefined
    }
  }

  updateInfo(info: ProjectData) {
    return this.project.update(info)
  }

  updateReportTags(tags: string[]) {
    this.projectReportsService.updateReportsTagCollection(this.project, this.reportTagsCollection, tags)
  }

  updateDescription(description: string) {
    return this.project.update({ description })
  }

  updateDetails(details: ProjectDetailsData) {
    return this.project.update({ details })
  }

  onTagsUpdated(tags: string[]) {
    const uniqueTags = tags.filter((tag, i, tags) => i === tags.indexOf(tag))
    this.tags = uniqueTags
  }

  onReportTagsUpdated(tags: string[]) {
    this.reportTagsChange = tags
  }

  changeProjectOwner() {
    this.companyService
      .listenToPeople(this.projectCompany)
      .pipe(take(1))
      .subscribe((companyPeople) => {
        const dialogRef = this.dialog.open(PeopleListDialogComponent, {
          width: "250px",
          data: { people: companyPeople, title: `Chose Project Owner From Comapny: ${this.projectCompany.name}` },
        })

        dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe((newProjectOwner: Person) => {
            if (this.project == null) {
              return
            }
            if (newProjectOwner == null) {
              return
            }

            const labels = [Relation.LABELS.OWNED_BY]
            // @ts-ignore
            Promise.all([this.project.add(newProjectOwner, labels), newProjectOwner.add(this.project, Relation.invertLabels(labels))]).then(
              (_) => {
                this.snackbarService.showMessage(`Successfully added ${newProjectOwner.name} as Project Owner`)
              }
            )
          })
      })
  }

  onSelectedTemplatesChange(selectedTemplates: LegacyTemplate[]) {
    const templatesToRemove: LegacyTemplate[] = []
    const newTemplatesAdded: LegacyTemplate[] = []

    selectedTemplates.forEach((selectedTemplate) => {
      if (!this.projectTemplates.some((t) => t.uid === selectedTemplate.uid)) {
        newTemplatesAdded.push(selectedTemplate)
      }
    })

    this.projectTemplates.forEach((projectTemplate) => {
      if (!selectedTemplates.some((t) => t.uid === projectTemplate.uid)) {
        templatesToRemove.push(projectTemplate)
      }
    })

    this._templatesToAdd = newTemplatesAdded
    this._templatesToRemove = templatesToRemove
  }

  onHasPublicTemplatesChange(val: boolean) {
    this._hasPublicTemplates = val
  }

  formatTimestamp(timestamp: any) {
    if (isNaN(timestamp as any)) {
      return timestamp
    }

    // In case timestamp is 0
    if (!timestamp) {
      return ""
    }

    const fromUnix = new FromUnixPipe().transform(timestamp / 1000)

    // return new DateFormatPipe().transform(fromUnix, 'D MMMM YYYY HH:mm')
    return new DateFormatPipe().transform(fromUnix, "MMM Do, YYYY")
  }
}
