import { Clipboard } from "@angular/cdk/clipboard"
import { CommonModule, DOCUMENT } from "@angular/common"
import { Component, inject, Inject, Input } from "@angular/core"
import { MatButtonModule } from "@angular/material/button"
import { MatDividerModule } from "@angular/material/divider"
import { MatIconModule } from "@angular/material/icon"
import { MatListModule } from "@angular/material/list"
import { MatMenuModule } from "@angular/material/menu"
import { MomentModule } from "ngx-moment"

import { Invitation, ModelInCollection } from "@models/common"
import { InvitationService, SnackbarService } from "@services"

@Component({
  selector: "checkd-pending-invitations-list",
  templateUrl: "./pending-invitations-list.component.html",
  styleUrls: ["./pending-invitations-list.component.scss"],
  standalone: true,
  imports: [MatButtonModule, MatDividerModule, MatListModule, MatIconModule, MatMenuModule, MomentModule, CommonModule],
})
export class PendingInvitationsListComponent {
  private readonly invitationService: InvitationService = inject(InvitationService)
  private readonly clipboard: Clipboard = inject(Clipboard)
  private readonly snackbar: SnackbarService = inject(SnackbarService)

  @Input() canRemoveInvitations: boolean = false
  @Input() invitations: Invitation[]

  constructor(@Inject(DOCUMENT) private document: Document) {}

  trackByUid(index: number, model: ModelInCollection): string {
    return model.uid!
  }

  getIcon(invitation: Invitation): "sms" | "email" {
    return invitation.deliveryMethod === "sms" ? "sms" : "email"
  }

  getTarget(invitation: Invitation): string {
    // @ts-ignore
    return invitation.deliveryMethod === "sms" ? invitation.targetPhoneNumber : invitation.targetEmail
  }

  async removePendingInvitationClicked(invitation: Invitation) {
    await this.invitationService.removeInvitationDialog(invitation)
  }

  public copyInvitationLink(invitation: Invitation) {
    const url = `${this.document.location.origin}/${invitation.ref!.path}`
    const success = this.clipboard.copy(url)

    if (success) {
      this.snackbar.showMessage("The invitation was copied to your clipboard", 5000)
    } else {
      this.snackbar.showMessage("There was an error copying the invitation to your clipboard", 5000)
    }
  }
}
