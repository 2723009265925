import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { Form } from "@checkd-form/models/form"
import { Item, ItemMenuActions } from "@models/common"
import { GeneralReport } from "@models/common/general-report"
import { RelationService } from "@services"
import { Observable, startWith } from "rxjs"
import {
  IMultiLangDialogData,
  MultiLangConfirmationDialogComponent,
} from "../../../dialogs/multi-lang-confirmation-dialog/multi-lang-confirmation-dialog.component"
import { FormMessageService } from "@checkd-form/services/form-message.service"
import { map, shareReplay } from "rxjs/operators"
import { ItemsModule } from "../../../items/items.module"
import { MatIconModule } from "@angular/material/icon"
import { FormDetailsComponent } from "../../form-view/elements/form-details/form-details.component"
import { FormHeaderComponent } from "../../form-view/elements/form-header/form-header.component"
import { MatLegacyCardModule } from "@angular/material/legacy-card"
import { NgIf, AsyncPipe } from "@angular/common"

@Component({
  selector: "checkd-report-body",
  templateUrl: "./report-body.component.html",
  styleUrls: ["./report-body.component.scss"],
  standalone: true,
  imports: [NgIf, MatLegacyCardModule, FormHeaderComponent, FormDetailsComponent, MatIconModule, ItemsModule, AsyncPipe],
})
export class ReportBodyComponent implements OnInit {
  @Input() logo: string
  @Input() form: Form
  @Input() report: GeneralReport
  @Input() fetchAttachedItems: boolean = true

  @Output() headerContentsChange = new EventEmitter()
  @Output() detailContentsChange = new EventEmitter()
  @Output() itemSelected = new EventEmitter()
  @Output() itemMenuOptionSelected = new EventEmitter()

  readonly attachedItems$: Observable<Item[]> = this.formMessageService.reportItems$.pipe(shareReplay({ bufferSize: 1, refCount: true }))
  readonly finishedLoadingAttachedItems$: Observable<boolean> = this.attachedItems$.pipe(
    map((_) => true),
    startWith(false)
  )

  public restrictedItemMenuActions: ItemMenuActions[] = [
    ItemMenuActions.REMOVE_ITEM,
    ItemMenuActions.DUPLICATE_ITEM,
    ItemMenuActions.SEE_PICTURES,
    ItemMenuActions.SET_AS_CLOSED,
    ItemMenuActions.VIEW_IMAGES,
  ]
  public additionalItemMenuActions: ItemMenuActions[] = [ItemMenuActions.REMOVE_FROM_REPORT]

  constructor(private dialogRef: MatDialog, private relationService: RelationService, private formMessageService: FormMessageService) {
    return
  }

  ngOnInit() {}

  onHeaderContentsChange(event: any) {
    this.headerContentsChange.emit(event)
  }

  onDetailsContentsChange(event: any) {
    this.detailContentsChange.emit(event)
  }

  openAttachedItemsInfoDialog() {
    this.dialogRef.open(MultiLangConfirmationDialogComponent, {
      data: <IMultiLangDialogData>{
        cancelButton: false,
        languageEntries: [
          {
            lang_button_label: "EN",
            title_text: "Attached Items",
            paragraphs: [
              "Items logged in this section have been attached/connected to this report. " +
                "These items have been created before, during or after this report was created.",
              "You can attach items to this report from the item list in the project, only on CHECKD web.",
              "Only owners and admins of a project can attach items to a check list",
            ],
          },
          {
            lang_button_label: "NO",
            title_text: "Knyttede Punkter",
            paragraphs: [
              "Punkter som er logget i denne seksjonen, har blitt knyttet til denne rapporten." +
                "Det betyr at disse punktene kan ha blitt opprettet før, under " +
                "eller etter at denne rapporten ble opprettet.",
              "Du kan knytte elementer til denne rapporten fra prosjektlisten på nettet. " +
                "Kun owners og admins av et prosjekt kan gjøre dette.",
            ],
          },
        ],
      },
    })
  }
}
