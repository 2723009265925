import { Component, forwardRef, OnInit } from "@angular/core"
import { MatLegacyRadioChange as MatRadioChange, MatLegacyRadioModule } from "@angular/material/legacy-radio"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { FormElementComponent } from "../form-element/form-element.component"
import { NgIf, NgFor } from "@angular/common"

@Component({
  selector: "app-checkbox-radio-element",
  templateUrl: "./checkbox-radio-element.component.html",
  styleUrls: ["./checkbox-radio-element.component.scss"],
  standalone: true,
  imports: [MatLegacyRadioModule, NgIf, NgFor, forwardRef(() => FormElementComponent)],
})
export class CheckboxRadioElementComponent extends DefaultElementComponent {
  override ngOnInit() {}

  override get value() {
    return this.getValue() === "true"
  }

  override set value(checked: boolean) {
    this.setValue(`${checked}`)
  }

  onValueChanged(event: MatRadioChange) {
    this.value = event.source.checked
    this.valueChanged.emit(this)
  }
}
