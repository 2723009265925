import { CheckdColors } from "@checkd-colors"

export enum Style {
  heading01 = "heading01",
  heading02 = "heading02",
  paragraph01 = "paragraph01",
  center = "center",
  left = "left",
  right = "right",
  redText = "redText",
}

export const styles = {
  styles: {
    [Style.heading01]: {
      fontSize: 40,
      bold: true,
    },

    [Style.heading02]: {
      fontSize: 20,
      bold: true,
    },

    [Style.paragraph01]: {
      fontSize: 10,
      bold: false,
    },

    [Style.center]: {
      alignment: "center",
    },

    [Style.left]: {
      alignment: "left",
    },

    [Style.right]: {
      alignment: "right",
    },

    [Style.redText]: {
      color: CheckdColors.CHECKD_RED,
    },
  },
  defaultStyle: {
    font: "Lato",
  },
}
