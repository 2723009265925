import { Component, OnInit } from "@angular/core"
import { MatLegacyProgressSpinnerModule } from "@angular/material/legacy-progress-spinner"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "app-progress-dialog",
  templateUrl: "./progress-dialog.component.html",
  styleUrls: ["./progress-dialog.component.scss"],
  standalone: true,
  imports: [FlexModule, MatLegacyProgressSpinnerModule],
})
export class ProgressDialogComponent implements OnInit {
  value = 10
  description = ""
  constructor() {}

  ngOnInit() {}

  resetValue() {
    this.value = 10
    this.description = ""
  }
}
