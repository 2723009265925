import { Component, OnInit } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { Router, RouterLink } from "@angular/router"
import { Company, CompanyFeatures, LegacyTemplate, Person } from "@models/common"
import { TemplateSharingOption } from "@models/common/legacy-template.interface"
import { CompanyService, SnackbarService, UserService } from "@services"
import firebase from "firebase/compat"
import { ConfirmationService, FilterService, MenuItem, PrimeIcons, SharedModule } from "primeng/api"
import { DialogService } from "primeng/dynamicdialog"
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, startWith } from "rxjs"
import { map, shareReplay, switchMap, tap } from "rxjs/operators"
import { PublicTemplatesViewService } from "../../services/public-templates-view.service"
import { ButtonModule } from "primeng/button"
import { MenuModule } from "primeng/menu"
import { TagModule } from "primeng/tag"
import { MultiSelectModule } from "primeng/multiselect"
import { TableModule } from "primeng/table"
import { PrimaryButtonDirective } from "../../../../next-ui/button/primary-button.directive"
import { InputTextModule } from "primeng/inputtext"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { FormsModule } from "@angular/forms"
import { SelectButtonModule } from "primeng/selectbutton"
import { CardModule } from "primeng/card"
import { SkeletonModule } from "primeng/skeleton"
import { NgIf, NgClass, NgFor, NgSwitch, NgSwitchCase, NgStyle, NgSwitchDefault, AsyncPipe, DatePipe } from "@angular/common"
import { ConfirmDialogModule } from "primeng/confirmdialog"
import firestore = firebase.firestore

type FilterType = "all" | "created" | "added"

@Component({
  selector: "checkd-company-forms-view",
  templateUrl: "./company-forms-view.component.html",
  styleUrls: ["./company-forms-view.component.scss"],
  providers: [DialogService],
  standalone: true,
  imports: [
    ConfirmDialogModule,
    NgIf,
    SkeletonModule,
    CardModule,
    SelectButtonModule,
    FormsModule,
    SharedModule,
    NgClass,
    ExtendedModule,
    InputTextModule,
    PrimaryButtonDirective,
    RouterLink,
    TableModule,
    NgFor,
    MultiSelectModule,
    TagModule,
    MenuModule,
    NgSwitch,
    NgSwitchCase,
    NgStyle,
    NgSwitchDefault,
    ButtonModule,
    AsyncPipe,
    DatePipe,
  ],
})
export class CompanyFormsViewComponent implements OnInit {
  TemplateSharingOption = TemplateSharingOption

  // Whether we are still waiting for templates to finish loading
  isLoading = true
  currentCompany: Company | null = null

  readonly formsFilter$ = new BehaviorSubject<FilterType>("all")

  readonly optionButtons: { name: string; icon: string; value: FilterType }[] = [
    { name: "All forms", icon: "", value: "all" },
    { name: "Created", icon: "pi pi-check", value: "created" },
    { name: "Added", icon: "pi pi-check", value: "added" },
  ]

  get selectedFilterType() {
    return this.formsFilter$.value
  }
  set selectedFilterType(newVal) {
    this.formsFilter$.next(newVal)
  }

  readonly publishedOptions: any[] = [
    {
      name: "Public",
      iconSrc: "../../../../../../assets/icons/forms_company/public-icon.png",
      value: 1,
    },
    {
      name: "Subscription",
      iconSrc: "../../../../../../assets/icons/forms_company/subscription-icon.png",
      value: 2,
    },
  ]

  constructor(
    public userService: UserService,
    private companyService: CompanyService,
    private publicTemplatesViewService: PublicTemplatesViewService,
    private snackbarService: SnackbarService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private db: AngularFirestore,
    private dialogService: DialogService,
    private filterService: FilterService
  ) {
    this.filterService.register("tagMatcher", (templateTags: string[], selectedTags: string[]): boolean => {
      return (selectedTags ?? []).every((selectedTag) => (templateTags ?? []).includes(selectedTag))
    })
  }

  private readonly allTemplates$: Observable<LegacyTemplate[]> = this.userService.currentCompany$.pipe(
    tap((company) => (this.currentCompany = company)),
    switchMap((company) => this.companyService.listenToTemplates(company)),
    tap((_) => (this.isLoading = false)),
    startWith([]),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public readonly hasTemplates$: Observable<boolean> = this.allTemplates$.pipe(map((templates) => templates.length > 0))

  public readonly activeTemplates$: Observable<LegacyTemplate[]> = this.allTemplates$.pipe(
    map((templates) => templates.filter((template) => template.isActive)),
    startWith([])
  )

  public readonly draftTemplates$: Observable<LegacyTemplate[]> = this.allTemplates$.pipe(
    map((templates) => templates.filter((template) => template.isDraft && !template.archived)),
    startWith([])
  )

  public readonly archivedTemplates$: Observable<LegacyTemplate[]> = this.allTemplates$.pipe(
    map((templates) => templates.filter((template) => template.archived)),
    startWith([])
  )

  readonly dataViewFilter$ = new BehaviorSubject<string>("")
  readonly activeTab$ = new BehaviorSubject<"active" | "draft" | "archived">("active")

  public currentTemplateList$: Observable<LegacyTemplate[]> = combineLatest([
    this.activeTab$,
    this.activeTemplates$,
    this.draftTemplates$,
    this.archivedTemplates$,
  ]).pipe(
    map(([activeTab, activeTemplates, draftTemplates, archivedTemplates]) => {
      // TODO: This gets fired up multiple times (16 times ish every time you change tab) optimize this
      switch (activeTab) {
        case "active":
          return activeTemplates
        case "draft":
          return draftTemplates
        case "archived":
          return archivedTemplates
        default:
          return activeTemplates
      }
    })
  )

  public readonly currentCompanyCanUseFormsBuilder$: Observable<boolean> = this.userService.currentCompanyHasFeature(CompanyFeatures.FORMS)

  public readonly filteredTemplateList$: Observable<LegacyTemplate[]> = combineLatest([
    this.currentTemplateList$,
    this.dataViewFilter$.pipe(map((word) => word.trim().toLowerCase())),
    this.formsFilter$,
    this.userService.currentCompany$,
  ]).pipe(
    map(([templates, dataViewFilter, filterType, currentCompany]) =>
      templates.filter((template) => {
        switch (filterType) {
          case "all":
            break
          case "added":
            if (template.aggregateData.templateCreatorCompanyUid === currentCompany.uid) {
              return false
            }
            break
          case "created":
            if (template.aggregateData.templateCreatorCompanyUid !== currentCompany.uid) {
              return false
            }
            break
        }

        if (!dataViewFilter) {
          return true
        }

        const name = (template.name || "").trim().toLowerCase()
        const description = (template.description || "").trim().toLowerCase()

        return name.includes(dataViewFilter) || description.includes(dataViewFilter)
      })
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  // Pipes the filtered templates shown in the UI and emits an array of their unique tags sorted
  readonly filteredTemplateTags$: Observable<string[]> = this.filteredTemplateList$.pipe(
    map((templates) =>
      Array.from(
        new Set(
          templates
            .map((template) => template.tags)
            .flat()
            .sort()
        )
      )
    )
  )

  // Emits a mapping of template uid to the menu items available for that template.
  readonly menuItems$: Observable<Map<string, MenuItem[]>> = this.activeTab$.pipe(
    switchMap(() =>
      combineLatest([
        this.allTemplates$,
        this.userService.currentUser$,
        this.userService.currentUserIsCompanyAdmin$,
        this.userService.currentCompany$,
      ]).pipe(
        map(([templates, currentUser, userIsAdmin, currentCompany]) => {
          return new Map<string, MenuItem[]>(
            templates.map((template) => [template.uid, this.createMenusItemForRow(template, currentUser, userIsAdmin, currentCompany)])
          )
        })
      )
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  /**
   * Creates menu items for each row (for the three-dot menu) and decides which actions should be available to the current user based on
   * user company role, template creator, owner company etc.
   * @param template The table row's template
   * @param currentUser
   * @param userIsCompanyAdmin
   * @param currentCompany
   */
  createMenusItemForRow(template: LegacyTemplate, currentUser: Person, userIsCompanyAdmin: boolean, currentCompany: Company): MenuItem[] {
    const userIsTemplateCreator = currentUser.uid === template.aggregateData.templateCreatorUid

    let templateIsOwnedByUserCompany = false
    if (template.aggregateData?.templateCreatorCompanyUid && currentCompany.uid) {
      templateIsOwnedByUserCompany = template.aggregateData.templateCreatorCompanyUid == currentCompany.uid
    }

    const commands: MenuItem[] = [
      {
        label: "Edit form",
        icon: "pi pi-fw pi-user-edit",
        command: this.createEditFormCommand(template),
        disabled: template.archived || !templateIsOwnedByUserCompany || !userIsCompanyAdmin,
      },
      {
        label: "Duplicate",
        icon: "pi pi-fw pi-copy",
        command: async () => {
          const batch = this.db.firestore.batch()
          const newTemplate = await this.duplicateTemplate(batch, template)
          await batch.commit()
          await this.navigateToFormsBuilder(newTemplate)
        },
        disabled: !userIsCompanyAdmin,
      },
      {
        label: "Set as draft",
        icon: "pi pi-fw pi-pencil",
        command: () => {
          this.confirmationService.confirm({
            header: "Confirm setting template as draft",
            message:
              "If any reports have been made using this template they will not be affected, but you will not be able to create a new report using this template until it is published again",
            acceptLabel: "Set as draft",
            rejectLabel: "Cancel",
            accept: async () => {
              await this.publicTemplatesViewService.setTemplateDraftState(template.uid, true)
              this.snackbarService.showMessage(`Template "${template.name}" is now set as draft.`)
            },
          })
        },
        disabled: template.isDraft || !templateIsOwnedByUserCompany || !userIsCompanyAdmin,
      },
    ]

    if (templateIsOwnedByUserCompany) {
      commands.push(this.createArchiveOrUnarchiveTemplateMenuItem(template, !templateIsOwnedByUserCompany || !userIsCompanyAdmin))
    } else {
      commands.push(this.createRemoveTemplateMenuItem(template, !userIsCompanyAdmin, currentCompany))
    }

    return commands
  }

  private createArchiveOrUnarchiveTemplateMenuItem(template: LegacyTemplate, disabled: boolean): MenuItem {
    return {
      label: template.archived ? "Unarchive" : "Archive",
      icon: "pi pi-fw pi-folder-open",
      command: () => {
        let header: string
        let message: string
        let acceptLabel: string

        if (template.archived) {
          header = "Confirm unarchiving"
          message = "Are you sure you want to unarchive this template? This will automatically set the template as draft"
          acceptLabel = "Set as draft and unarchive"
        } else {
          header = "Confirm archiving"
          message =
            "Are you sure you want to archive this template? It will no longer be available for anyone in your company. If you made any reports using this template, they will not be affected"
          acceptLabel = "Archive"
        }

        this.confirmationService.confirm({
          header,
          message,
          acceptLabel,
          rejectLabel: "Cancel",
          accept: async () => {
            const newArchivedState = !template.archived

            const batch = this.db.firestore.batch()
            this.publicTemplatesViewService.batchUpdateTemplateArchivedState(batch, template.uid, newArchivedState)
            this.publicTemplatesViewService.batchSetTemplateDraftState(batch, template.uid, true)
            await batch.commit()

            if (newArchivedState) {
              this.snackbarService.showMessage(`Template "${template.name}" is now archived.`)
            } else {
              this.snackbarService.showMessage(`Template "${template.name}" was unarchived.`)
            }
          },
        })
      },
      disabled,
    }
  }

  private createRemoveTemplateMenuItem(template: LegacyTemplate, disabled: boolean, currentCompany: Company): MenuItem {
    return {
      label: "Remove",
      icon: PrimeIcons.TRASH,
      disabled,
      command: () => {
        this.confirmationService.confirm({
          header: "Confirm removing template",
          message: "Are you sure you want to remove this template?",
          acceptLabel: "Remove",
          rejectLabel: "Cancel",

          accept: async () => {
            const batch = this.db.firestore.batch()
            try {
              await this.publicTemplatesViewService.batchUnsubscribeToTemplate(batch, template, currentCompany.uid)
              await batch.commit()
              await this.snackbarService.showMessage(`Template ${template.name} was removed`)
            } catch (e: any) {
              this.snackbarService.showMessage(e.message, 10_000)
            }
          },
        })
      },
    }
  }

  private createEditFormCommand(template: LegacyTemplate) {
    return async () => {
      /*
       isStandard handling for legacy reasons. We used to have a set of standard templates where each company with access to the
       template had a database relation to this one template (many-to-1). With the new forms library, we are moving away
       from standard templates, and instead we have a system where if you subscribe to a public template, you always get a copy of that
       template (creating a 1-to-1 relation between company and template).
       This means that if a user wants to edit a standard form, we need to make a copy of it and add it to their company before allowing
       them to edit it. If we don't do this, the changes made to a standard template will appear for all companies, that uses the standard
       template, which of course we don't want.
      */
      if (template.isStandard) {
        this.confirmationService.confirm({
          header: "Create a clone to to edit",
          message: "To edit a standard template, we will duplicate it and add it to your company. The cloned template will be set as draft",
          acceptLabel: "Clone and edit",
          rejectLabel: "Cancel",
          accept: async () => {
            const currentCompany = await firstValueFrom(this.userService.currentCompany$)

            const batch = this.db.firestore.batch()
            const newTemplate = await this.duplicateTemplate(batch, template)
            this.publicTemplatesViewService.batchHardDeleteCompanyTemplateRelationTwoWay(batch, currentCompany.uid, template.uid)
            await batch.commit()

            this.snackbarService.showMessage(`Template "${template.name}" duplicated!`)
            await this.navigateToFormsBuilder(newTemplate)
          },
        })
      } else if (template.isActive) {
        this.confirmationService.confirm({
          header: "Confirm editing active template",
          message:
            "Editing an active template will unpublish it and set it as draft. If any reports have been made using this template they will not be affected, but you will not be able to create a new report using this template until it is published again",
          acceptLabel: "Set as draft and edit",
          rejectLabel: "Cancel",
          accept: async () => {
            const batch = this.db.firestore.batch()
            this.publicTemplatesViewService.batchSetTemplateDraftState(batch, template.uid, true)
            this.publicTemplatesViewService.batchSetTemplateSharedWithStatus(batch, template.uid, TemplateSharingOption.NONE)
            await batch.commit()

            this.snackbarService.showMessage(`Template "${template.name}" is now set as draft.`)
            await this.navigateToFormsBuilder(template)
          },
        })
      } else {
        // the template is already set as draft
        await this.navigateToFormsBuilder(template)
      }
    }
  }

  trackRowByUid(index: number, template: LegacyTemplate): string {
    return template.uid
  }

  // Header row
  cols: { field: string; header: string }[] = [
    { field: "name", header: "TITLE" },
    { field: "description", header: "DESCRIPTION" },
    { field: "tags", header: "TAGS" },
    { field: "updatedAt", header: "LAST UPDATE" },
    { field: "publicVersion", header: "VERSION" },
    // This column is temporarily removed until we find out a better way to the difference between subscribed templates
    // and templates owned by your company
    // { field: "type", header: "TYPE" },
    { field: "templateCreatorCompanyName", header: "CREATOR COMPANY" },
    { field: "published", header: "PUBLISHED" },
    { field: "templateUpdateInfo", header: "UPDATED" },
  ]

  // Forms options

  ngOnInit(): void {}

  private async navigateToFormsBuilder(rowData: LegacyTemplate) {
    await this.publicTemplatesViewService.navigateToFormsBuilderWithTemplate(rowData.uid)
  }

  async navigateToTemplateLandingPage(rowData: LegacyTemplate) {
    // Navigate to original template if this is a copy to ensure the correct view inside landing page
    await this.publicTemplatesViewService.navigateToLandingPageForTemplate(
      rowData.data.originalTemplateUid ? rowData.data.originalTemplateUid : rowData.uid
    )
  }

  private async duplicateTemplate(batch: firestore.WriteBatch, rowData: LegacyTemplate) {
    const currentUser = await firstValueFrom(this.userService.currentUser$)
    return this.publicTemplatesViewService.batchDuplicateTemplate(batch, rowData.uid, currentUser.aggregateData["companyUid"], currentUser)
  }

  getRowAggregateDataField(field: string, row: LegacyTemplate): string {
    return ((row?.aggregateData as any)[field] as string) ?? ""
  }

  templateIsCreatedByCurrentCompany(currentCompany: Company | null, template: LegacyTemplate): boolean {
    if (!currentCompany) return false
    return template.aggregateData.templateCreatorCompanyUid === currentCompany.uid
  }
}
