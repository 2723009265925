import { Component, forwardRef } from "@angular/core"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { FormElementComponent } from "../form-element/form-element.component"
import { NgIf, NgFor } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox"

@Component({
  selector: "app-checkbox-element",
  templateUrl: "./checkbox-element.component.html",
  styleUrls: ["./checkbox-element.component.scss"],
  standalone: true,
  imports: [MatLegacyCheckboxModule, FormsModule, NgIf, NgFor, forwardRef(() => FormElementComponent)],
})
export class CheckboxElementComponent extends DefaultElementComponent {
  override get value() {
    return this.getValue() === "true"
  }

  override set value(checked: boolean) {
    this.setValue(`${checked}`)
  }
}
