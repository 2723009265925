import { inject, Injectable } from "@angular/core"
import { AngularFireFunctions } from "@angular/fire/compat/functions"
import { firstValueFrom, Observable } from "rxjs"
import { take } from "rxjs/operators"
import { environment } from "../../../environments/environment"

type TPreFilledOneLoginRegistrationInfo = {
  email: string
  locked: true
  givenName: string
  familyName: string
  username: string
  localUserId: string
}

@Injectable({
  providedIn: "root",
})
export class OneLoginService {
  private readonly afns = inject(AngularFireFunctions)
  private readonly storageInvitationKey = "oneLoginInvitationUid"

  private readonly _createOneLoginInvite: (data: Partial<TPreFilledOneLoginRegistrationInfo>) => Observable<{ inviteId: string }> =
    this.afns.httpsCallable("invitations-createOneLoginInvite")

  setInvitationUid(invitationUid: string) {
    sessionStorage.setItem(this.storageInvitationKey, invitationUid)
  }

  getInvitationUid(): string | null {
    return sessionStorage.getItem(this.storageInvitationKey)
  }

  clearInvitationUid() {
    sessionStorage.removeItem(this.storageInvitationKey)
  }

  createOneLoginInvite(data: Partial<TPreFilledOneLoginRegistrationInfo>): Promise<{ inviteId: string }> {
    const observable = this._createOneLoginInvite(data).pipe(take(1))

    return firstValueFrom(observable)
  }

  redirectToOneLoginWithInvite(fieldInvitationUid: string, oneLoginInviteId: { inviteId: string }) {
    this.setInvitationUid(fieldInvitationUid)

    const oneLoginBaseUrl = environment.oneLogin.oneLoginBaseUrl
    if (!oneLoginBaseUrl) {
      throw new Error("One Login Base URL missing from environment")
    }
    const query = new URLSearchParams(oneLoginInviteId).toString()
    window.location.href = `${oneLoginBaseUrl}/connect/?${query}`
  }
}
