import { NgIf } from "@angular/common"
import { Component } from "@angular/core"
import { DefaultElementComponent } from "../default-element/default-element.component"

@Component({
  selector: "app-main-and-subfield-element",
  templateUrl: "./main-and-subfield-element.component.html",
  styleUrls: ["./main-and-subfield-element.component.scss"],
  standalone: true,
  imports: [NgIf],
})
export class MainAndSubfieldElementComponent extends DefaultElementComponent {}
