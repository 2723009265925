import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { FormsLibraryViewService } from "../../../../services/forms-library-view.service"
import { FormsModule } from "@angular/forms"
import { CheckboxModule } from "primeng/checkbox"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { NgStyle } from "@angular/common"

@Component({
  selector: "checkd-tag-component",
  templateUrl: "./tag.component.html",
  styleUrls: ["./tag.component.scss"],
  standalone: true,
  imports: [NgStyle, ExtendedModule, CheckboxModule, FormsModule],
})
export class TagComponent implements OnInit {
  @Input() label: string = ""
  constructor(private flViewService: FormsLibraryViewService) {}

  @Output() onChange = new EventEmitter<{ checked: boolean; label: string }>()

  checked: boolean = false

  onTagCheckedChange(event: any) {
    this.onChange.emit({ checked: event.checked, label: this.label })
  }

  // This function is created in case that it's necessary to add a random color to the tags.

  // colorRandom = this.getRandomColor();

  // getRandomColor() {
  //   var color = Math.floor(0x1000000 * Math.random()).toString(16);
  //   return '#' + ('000000' + color).slice(-6);

  // }

  ngOnInit(): void {
    const filters = this.flViewService.getCurrentFilterData()

    if (filters.predefinedTags.includes(this.label)) {
      this.checked = true
    }
  }
}
