import { NgFor } from "@angular/common"
import { Component, EventEmitter, Output } from "@angular/core"
import { FlexModule } from "@angular/flex-layout/flex"

import { FormElementComponent } from "../form-element/form-element.component"

@Component({
  selector: "app-form-header",
  templateUrl: "./form-header.component.html",
  styleUrls: ["./form-header.component.scss"],
  standalone: true,
  imports: [NgFor, FlexModule, FormElementComponent],
})
export class FormHeaderComponent extends FormElementComponent {
  @Output() contentChange = new EventEmitter()

  get values() {
    if (this.element == null || this.element.values == null) return []

    return this.element.values
  }

  onContentChange(element: any) {
    this.contentChange.emit(this.element.transformAttributes().values)
  }
}
