import { NgFor } from "@angular/common"
import { Component, EventEmitter, Input, Output } from "@angular/core"
import { FormElementComponent } from "@checkd-form/form-view/elements/form-element/form-element.component"

import type { FormElement } from "@checkd-form/models/form-element"

@Component({
  selector: "app-form-details",
  templateUrl: "./form-details.component.html",
  styleUrls: ["./form-details.component.scss"],
  standalone: true,
  imports: [NgFor, FormElementComponent],
})
export class FormDetailsComponent {
  @Input() element: FormElement
  @Input() config: Record<string, any>
  @Input() readOnly: boolean = false

  @Output() contentChange = new EventEmitter<void>()

  get values() {
    if (this.element == null || this.element.values == null) {
      return []
    }

    return this.element.values
  }

  onContentChange(element: any) {
    this.contentChange.emit(this.element.transformAttributes().values)
  }
}
