import { Component } from "@angular/core"
import { FormsLibraryViewService } from "../../services/forms-library-view.service"
import { TemplateShowcaseComponent } from "./components/template-showcase/template-showcase.component"
import { BrowseAllTemplatesComponent } from "./components/browse-all-templates/browse-all-templates.component"
import { NgSwitch, NgSwitchCase } from "@angular/common"

@Component({
  selector: "checkd-forms-library-view",
  templateUrl: "./forms-library-view.component.html",
  styleUrls: ["./forms-library-view.component.scss"],
  providers: [FormsLibraryViewService],
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, BrowseAllTemplatesComponent, TemplateShowcaseComponent],
})
export class FormsLibraryViewComponent {
  constructor(public flViewService: FormsLibraryViewService) {}
}
