import { Component, OnDestroy, OnInit } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import { ActivatedRoute, Router } from "@angular/router"
import { ConfirmDialogComponent } from "@dialogs"
import { Company, Invitation, InvitationType, LoginProviders, Person, PersonData, Project, UserRegistrationData } from "@models/common"
import { ICheckdAgreements } from "@models/common/agreements.interface"
import { InvitationService, RelationService, SnackbarService, UserService } from "@services"
import { AgreementsService } from "@services/agreements.service"
import { combineLatest, from as observableFrom, Observable, of as observableOf, Subscription } from "rxjs"
import { map, shareReplay, switchMap, take } from "rxjs/operators"
import { InvitationLoginDialogComponent } from "../dialogs/invitation-login-dialog/invitation-login-dialog.component"
import { MatIconModule } from "@angular/material/icon"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { EmailAddressSubmissionComponent } from "../email-address-submission/email-address-submission.component"
import { CheckdButtonComponent } from "../../checkd-ui/checkd-button/checkd-button.component"
import { FlexModule } from "@angular/flex-layout/flex"
import { NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet, NgFor, AsyncPipe } from "@angular/common"

@Component({
  selector: "app-invitation-view",
  templateUrl: "./invitation-view.component.html",
  styleUrls: ["./invitation-view.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    FlexModule,
    NgSwitch,
    NgSwitchCase,
    CheckdButtonComponent,
    NgTemplateOutlet,
    NgFor,
    EmailAddressSubmissionComponent,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyCheckboxModule,
    MatLegacyButtonModule,
    ExtendedModule,
    MatIconModule,
    AsyncPipe,
  ],
})
export class InvitationViewComponent implements OnInit, OnDestroy {
  readonly invitation$: Observable<Invitation> = this.route.params.pipe(
    map((params) => params["invitationUid"]),
    switchMap((uid: string) => this.invitationService.listenToUid(uid)),
    take(1)
  )

  invitationProjects$: Observable<Project[]> = this.invitation$.pipe(
    switchMap((invitation) => this.invitationService.listenToProjects(invitation))
  )

  invitationProjectNames$: Observable<string[]> = this.invitation$.pipe(
    switchMap((invitation) =>
      invitation.aggregateData && invitation.aggregateData["resourceProjectName"]
        ? observableOf([invitation.aggregateData["resourceProjectName"]])
        : this.invitationProjects$.pipe(map((projects) => projects.map((project) => project.name)))
    )
  )

  invitationCompany$: Observable<Company> = this.invitation$.pipe(
    switchMap((invitation) => this.invitationService.listenToCompany(invitation))
  )

  currentUser$: Observable<Person> = this.userService.currentUser$

  //////////////////////////////////////////////////////////////////////////////////////
  // NB: sourceUser$ and sourceCompany$ can probably be removed once we've made sure that
  //     all invitations are created with the required aggregate data. This way we don't
  //     need to provide read access to much else than the actual invitation document.
  sourceUser$: Observable<Person> = this.invitation$.pipe(switchMap((invitation) => this.userService.listenToUid(invitation.sourceUid)))

  sourceCompany$: Observable<Company> = this.sourceUser$.pipe(switchMap((person) => this.userService.listenToMainCompany(person)))
  //////////////////////////////////////////////////////////////////////////////////////

  sourceUserEmail$: Observable<string> = this.invitation$.pipe(
    switchMap((invitation) =>
      invitation.aggregateData && invitation.aggregateData["sourceEmail"]
        ? observableOf(invitation.aggregateData["sourceEmail"])
        : // TODO remove once we are sure we create the required aggregate data
          this.sourceUser$.pipe(map((user) => user.email))
    )
  )

  sourceUserName$: Observable<string> = this.invitation$.pipe(
    switchMap((invitation) =>
      invitation.aggregateData && invitation.aggregateData["sourceName"]
        ? observableOf(invitation.aggregateData["sourceName"])
        : this.sourceUser$.pipe(map((user) => user.name))
    )
  )

  sourceCompanyName$: Observable<string> = this.invitation$.pipe(
    switchMap((invitation) =>
      invitation.aggregateData && invitation.aggregateData["sourceCompanyName"]
        ? observableOf(invitation.aggregateData["sourceCompanyName"])
        : // TODO remove once we are sure we create the required aggregate data
          this.sourceCompany$.pipe(map((company) => company.name))
    )
  )

  targetEmailExists$: Observable<boolean> = this.invitation$.pipe(
    switchMap((invitation) =>
      invitation.targetEmail ? observableFrom(this.userService.checkIfUserEmailExists(invitation.targetEmail)) : observableOf(false)
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  get projectRole() {
    return this.invitation.data.targetRoleTitle ? this.invitation.data.targetRoleTitle : ""
  }

  get isTargetEmailMatch(): boolean {
    // @ts-ignore
    return (
      this.currentUser &&
      this.currentUser.email &&
      this.targetEmail &&
      this.currentUser.email.toLowerCase() === this.targetEmail.toLowerCase()
    )
  }

  get targetEmail() {
    return this.invitation ? this.invitation.targetEmail : null
  }

  subscriptions: Subscription[] = []

  userInputs: UntypedFormGroup = this.fb.group({
    password: [""],
  })

  userRegistrationInputs: UntypedFormGroup = this.fb.group({
    companyName: [""],
    password: ["", [Validators.required, Validators.minLength(6)]],
    userName: ["", Validators.required],
  })

  invitation: Invitation
  currentUser: Person

  isRegistrationInProgress: boolean = false
  nextButtonInProgress: boolean = false

  ready: boolean = false

  checkdAgreements: ICheckdAgreements
  userHasAgreed: boolean = false

  invitationCompany: Company

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private invitationService: InvitationService,
    private snackbarService: SnackbarService,
    private relationService: RelationService,
    protected dialogRef: MatDialog,
    protected fb: UntypedFormBuilder,
    private db: AngularFirestore,
    private agreementsService: AgreementsService,
    private dialog: MatDialog
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  ngOnInit() {
    this.setupSubscriptions()
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.currentUser$.subscribe((currentUser) => {
        this.currentUser = currentUser
      }),
      this.invitation$.subscribe((invitation) => {
        this.invitation = invitation
      }),
      combineLatest([this.invitation$, this.targetEmailExists$])
        .pipe(take(1))
        .subscribe((_) => {
          this.ready = true
        }),
      this.agreementsService.checkdAgreements$.subscribe((checkdAgreements) => {
        this.checkdAgreements = checkdAgreements
      }),
      this.invitationCompany$.subscribe((company) => {
        this.invitationCompany = company
      }),
    ]
  }

  // Enables microsoft login/registration in HTML
  canLogInWithMicrosoft(): boolean {
    return this.invitation && this.invitation.loginMethods.includes(LoginProviders.MICROSOFT)
  }

  // Enables username/password login/registration in HTML
  canLogInWithUsernamePassword(): boolean {
    return this.invitation && this.invitation.loginMethods.includes(LoginProviders.USERNAME_PASSWORD)
  }

  public async loginWithMicrosoft(): Promise<void> {
    if (!this.targetEmail) {
      return this.snackbarService.showMessage("Target email was not found", 5000)
    }

    let signInResult
    try {
      signInResult = await this.userService.loginWithMicrosoft()
    } catch (error) {
      if (error.code === "auth/popup-blocked") {
        this.snackbarService.showMessage("Sign in popup was blocked by your browser", 50000)
      } else if (error.message && error.message.includes("Unauthorized email")) {
        this.snackbarService.showMessage(
          "Access Denied: This is a beta feature enabled for selected customers only. Please contact support for more information.",
          5000
        )
      } else {
        this.snackbarService.showMessage(error.message, 5000)
      }
      await this.userService.logout()

      return
    }

    const cleanedTargetEmail = this.targetEmail.trim().toLowerCase()
    const authEmail: string | null | undefined = signInResult.user?.email
    if (!authEmail) {
      return this.snackbarService.showMessage("could not get email from auth", 5000)
    }

    if (cleanedTargetEmail === authEmail || this.invitation.deliveryMethod === "sms") {
      await this.userService.createOrFetchUserDocForMicrosoftAdUser(signInResult)
    } else {
      this.snackbarService.showMessage(`This invitation seems to be for another user. You are logged in with ${signInResult.user!.email}`)
    }
  }

  async acceptCompanyInvitation() {
    const dialog = this.dialogRef.open(ConfirmDialogComponent, {
      width: "300px",
      data: {
        content:
          'NB: If you are part of another company in the system, you will be removed from that company and moved to this one when you accept the invite. Press "Yes" to confirm.',
      },
    })

    const result = await dialog.afterClosed().pipe(take(1)).toPromise()

    if (result) {
      this.nextButtonInProgress = true
      try {
        await this.invitationService.acceptInvitation(this.invitation, this.currentUser)
        this.nextButtonInProgress = false
        this.doneAcceptingInvitation()
      } catch (err) {
        this.nextButtonInProgress = false
        this.snackbarService.showMessage(err.message)
      }
    }
  }

  async acceptInvitation() {
    if (this.invitation.invitationType === InvitationType.COMPANY) {
      return this.acceptCompanyInvitation()
    }

    try {
      this.nextButtonInProgress = true
      await this.invitationService.acceptInvitation(this.invitation, this.currentUser)
      this.nextButtonInProgress = false

      return this.doneAcceptingInvitation()
    } catch (err) {
      this.snackbarService.showMessage(err.message)
      this.nextButtonInProgress = false
    }
  }

  doneAcceptingInvitation() {
    this.snackbarService.showMessage("You have accepted the invitation!")
    this.router.navigateByUrl("/projects")
  }

  async registerUser() {
    if (!this.userRegistrationInputs.valid) {
      this.snackbarService.showMessage("ERROR: Invalid registration data")

      return
    }

    this.isRegistrationInProgress = true

    const values = this.userRegistrationInputs.value

    const personData: PersonData = {
      name: values.userName.trim(),
      fullName: values.userName.trim(),
      email: this.targetEmail!.trim(),
      companyName: values.companyName.trim() || "Untitled Company",
    }

    const registrationData: UserRegistrationData = {
      company: values.companyName.trim() || "Untitled Company",
      password: values.password.trim(),
      personData,
    }

    try {
      this.nextButtonInProgress = true
      await this.userService.createAuthUserAndUserData(registrationData)
      this.nextButtonInProgress = false
    } catch (err) {
      this.snackbarService.showMessage(err.message)
      this.nextButtonInProgress = false
    }
  }

  public async triggerLoginDialog() {
    await this.dialog.open(InvitationLoginDialogComponent).afterClosed().pipe(take(1)).toPromise()
  }

  async login() {
    const email = this.targetEmail!.trim()
    const password = this.userInputs.get("password")!.value

    try {
      this.nextButtonInProgress = true
      await this.userService.login(email, password)
      this.nextButtonInProgress = false
    } catch (err) {
      this.snackbarService.showMessage(err.message)
      this.nextButtonInProgress = false
    }
  }
}
