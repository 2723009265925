import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core"

import { MatDialog } from "@angular/material/dialog"
import { FormMessageService } from "@checkd-form/services/form-message.service"
import { Subscription } from "rxjs"

import { FormElement } from "../../../models/form-element"
import { InfoDialogComponent } from "./info-dialog/info-dialog.component"
import { TableElementComponent } from "../table-element/table-element.component"
import { TextElementComponent } from "../text-element/text-element.component"
import { FormsItemsElementComponent } from "../forms-items-element/forms-items-element.component"
import { ItemListElementComponent } from "../item-list-element/item-list-element.component"
import { DrawingElementComponent } from "../drawing-element/drawing-element.component"
import { FieldReportElementComponent } from "../field-report-element/field-report-element.component"
import { EmptyElementComponent } from "../empty-element/empty-element.component"
import { ImageElementComponent } from "../image-element/image-element.component"
import { ToggleElementComponent } from "../toggle-element/toggle-element.component"
import { CounterElementComponent } from "../counter-element/counter-element.component"
import { TabularFormElementComponent } from "../tabular-form-element/tabular-form-element.component"
import { CameraElementComponent } from "../camera-element/camera-element.component"
import { SliderElementComponent } from "../slider-element/slider-element.component"
import { AddressElementComponent } from "../address-element/address-element.component"
import { SubfieldElementComponent } from "../main-and-subfield-element/subfield-element/subfield-element.component"
import { MainfieldElementComponent } from "../main-and-subfield-element/mainfield-element/mainfield-element.component"
import { MainAndSubfieldElementComponent } from "../main-and-subfield-element/main-and-subfield-element.component"
import { SignatureElementComponent } from "../signature-element/signature-element.component"
import { TextfieldElementComponent } from "../textfield-element/textfield-element.component"
import { CheckboxRadioElementComponent } from "../checkbox-radio-element/checkbox-radio-element.component"
import { CheckboxElementComponent } from "../checkbox-element/checkbox-element.component"
import { CheckboxCollectionElementComponent } from "../checkbox-collection-element/checkbox-collection-element.component"
import { DropdownItemElementComponent } from "../dropdown-element/dropdown-item-element/dropdown-item-element.component"
import { DropdownElementComponent } from "../dropdown-element/dropdown-element.component"
import { GpsCoordinateElementComponent } from "../gps-element/gps-coordinate-element/gps-coordinate-element.component"
import { GpsElementComponent } from "../gps-element/gps-element.component"
import { DateElementComponent } from "../date-element/date-element.component"
import { TimeElementComponent } from "../time-element/time-element.component"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { MatIconModule } from "@angular/material/icon"
import { NgIf } from "@angular/common"

const componentMap = new Map([
  ["default", DefaultElementComponent],
  ["time", TimeElementComponent],
  ["date", DateElementComponent],
  ["gps", GpsElementComponent],
  ["latitude", GpsCoordinateElementComponent],

  // We need both longtitude and longitude due to spelling errors in the data
  ["longtitude", GpsCoordinateElementComponent],
  ["longitude", GpsCoordinateElementComponent],
  ["dropdown", DropdownElementComponent],
  ["dropdown-item", DropdownItemElementComponent],
  ["checkbox", CheckboxCollectionElementComponent],
  ["checkbox-item", CheckboxElementComponent],
  ["checkbox-radio-item", CheckboxRadioElementComponent],
  ["text", TextElementComponent],
  ["textfield", TextfieldElementComponent],
  ["textfieldint", TextfieldElementComponent],
  ["multilinetextfield", TextfieldElementComponent],
  ["signature", SignatureElementComponent],

  // NB These elements are defined differently in different forms.
  ["mainandsubfield", MainAndSubfieldElementComponent],
  ["main", MainfieldElementComponent],
  ["subfield", SubfieldElementComponent],
  ["header", MainfieldElementComponent],
  ["subject", SubfieldElementComponent],

  ["address", AddressElementComponent],
  ["slider", SliderElementComponent],
  ["camera", CameraElementComponent],
  ["tabularform", TabularFormElementComponent],
  ["table", TableElementComponent],
  ["updown", CounterElementComponent],
  ["yesno", ToggleElementComponent],
  ["image", ImageElementComponent],
  ["empty", EmptyElementComponent],
  ["fieldreport", FieldReportElementComponent],
  ["drawing", DrawingElementComponent],
  ["itemlist", ItemListElementComponent],
  ["formsitems", FormsItemsElementComponent],
])

@Component({
  selector: "app-form-element",
  templateUrl: "./form-element.component.html",
  styleUrls: ["./form-element.component.scss"],
  standalone: true,
  imports: [NgIf, MatIconModule],
})
export class FormElementComponent implements OnInit, OnDestroy {
  @ViewChild("elementContainer", { read: ViewContainerRef, static: true }) container: ViewContainerRef

  @Input() element: FormElement
  @Input() elementType: string
  @Input() parentElement: FormElement
  @Input() config: { [key: string]: any }
  @Input() readOnly = false
  @Output() valueChanged = new EventEmitter()

  private subs = new Subscription()

  constructor(protected formMessageService: FormMessageService, protected dialogRef: MatDialog) {}

  ngOnInit() {
    const elementTypeName = this.getElementType()
    const elementComponent = componentMap.get(elementTypeName) ?? DefaultElementComponent
    const componentRef = this.container.createComponent(elementComponent)

    componentRef.setInput("element", this.element)
    componentRef.setInput("readOnly", this.readOnly)
    componentRef.setInput("parentElement", this.parentElement)

    this.subs.add(componentRef.instance.valueChanged.subscribe((event) => this.valueChanged.emit(event)))
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  public getElementType() {
    // NB The element types are described differently in different
    // reports (either in the type or name attribute).
    // NB2 We also need to convert to lowercase to handle different case
    // variations in the data
    return (this.elementType || this.element.type || this.element.name || "default").toLowerCase()
  }

  hasInfo() {
    return this.element && this.element.info ? this.element.info.length > 0 : false
  }

  openInfoDialog() {
    this.dialogRef.open(InfoDialogComponent, {
      data: this.element.info,
    })
  }
}
