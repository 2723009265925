import { animate, style, transition, trigger } from "@angular/animations"
import { Location, NgIf } from "@angular/common"
import { Component, OnDestroy, OnInit } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { ActivatedRoute, ParamMap, Router } from "@angular/router"
import { Company, Person } from "@models/common"
import { CompanyService, SnackbarService, UserService } from "@services"
import { DocumentHistoryService } from "@services/document-history.service"
import { combineLatest, Observable, of as observableOf, Subscription } from "rxjs"
import { debounceTime, map, switchMap, take } from "rxjs/operators"
import { IEditFormsTemplateDialogSaveData } from "../../../features/public-template-library/edit-forms-template-dialog/edit-forms-template-dialog/edit-forms-template-dialog.component"
import { ConfirmDialogComponent } from "../../components/confirm-dialog/confirm-dialog.component"
import { TemplateOptionsLogoType } from "../../components/legacy-report-preview/legacy-report-preview.component"
import { SaveTemplateDialogComponent, SaveTemplateDialogResult } from "../../components/save-template-dialog/save-template-dialog.component"
import { Template } from "../../models/template"
import { TemplateElement } from "../../models/template-elements/template-element"
import { LegacyTemplateService } from "../../services/legacy-template.service"
import { TemplateCreationService } from "../../services/template-creation.service"
import { transformLegacyToInternal } from "../../../../../../functions/src/exports/forms-exports/transformations/legacy-to-internal"
import { transformInternalToExternal } from "../../../../../../functions/src/exports/forms-exports/transformations/internal-to-external"
import * as FileSaver from "file-saver"
import sanitize from "sanitize-filename"
import { TemplateCreationActionBarComponent } from "../../components/template-creation-action-bar/template-creation-action-bar.component"
import { TemplateCreationComponent } from "../../components/template-creation/template-creation.component"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { MatLegacyProgressBarModule } from "@angular/material/legacy-progress-bar"

@Component({
  selector: "checkd-template-creation-view",
  templateUrl: "./template-creation-view.component.html",
  styleUrls: ["./template-creation-view.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [style({ opacity: 0 }), animate("1s ease-out", style({ opacity: 1 }))]),
      transition(":leave", [style({ opacity: 1 }), animate("1s ease-in", style({ opacity: 0 }))]),
    ]),
  ],
  standalone: true,
  imports: [
    NgIf,
    MatLegacyProgressBarModule,
    MatLegacyButtonModule,
    MatIconModule,
    TemplateCreationComponent,
    TemplateCreationActionBarComponent,
  ],
})
export class TemplateCreationViewComponent implements OnInit, OnDestroy {
  currentUser$: Observable<Person>
  currentCompany$: Observable<Company>
  currentUser: Person
  currentCompany: Company

  template$: Observable<Template>

  templateElements: TemplateElement[] = this.templateCreationService.getDefaultTemplateElements()
  droppedElements: any[] = []
  previewData: any[] = []
  template: Template = this.templateCreationService.getNewTemplate()
  previousTemplate: Template = this.templateCreationService.getNewTemplate()

  subscriptions: Subscription[] = []
  showProgressBar: boolean = true

  get isNewTemplate() {
    return this.template != null && this.template.uid == null && this.template.ref == null
  }

  constructor(
    private legacyTemplateService: LegacyTemplateService,
    public templateCreationService: TemplateCreationService,
    private userService: UserService,
    private companyService: CompanyService,
    private snackbarService: SnackbarService,
    private documentHistoryService: DocumentHistoryService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public location: Location
  ) {}

  ngOnInit() {
    this.setupListeners()
    this.setupSubscriptions()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  setupListeners() {
    this.currentUser$ = this.userService.currentUser$
    this.currentCompany$ = this.userService.currentCompany$
    this.template$ = this.route.paramMap.pipe(
      map((params: ParamMap) => params.get("templateUid") || ""),
      switchMap((templateUid) =>
        templateUid
          ? this.templateCreationService.listenToUid(templateUid)
          : observableOf(this.templateCreationService.getNewLegacyTemplate())
      ),
      map((legacyTemplate) => this.templateCreationService.transformLegacyTemplate(legacyTemplate))
    ) as Observable<Template>
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.currentUser$.subscribe((user) => (this.currentUser = user)),
      this.currentCompany$.subscribe((company) => {
        this.currentCompany = company
        this.templateElements = [
          ...this.templateCreationService.getDefaultTemplateElements(),
          ...this.templateCreationService.getFeatureBasedTemplateElements(company),
        ]
      }),
      combineLatest([this.currentUser$, this.currentCompany$])
        .pipe(take(1))
        .subscribe((_) => (this.showProgressBar = false)),
      this.template$.pipe(take(1)).subscribe((template) => (template ? this.newTemplate(template) : this.newTemplate())),

      this.templateCreationService.messageListener$.pipe(debounceTime(1000)).subscribe((_) => {
        this.templateCreationService.checkIfTemplatesAreEqual(this.template, this.previousTemplate)
      }),
    ]
  }

  async newTemplate(template?: Template) {
    this.clear()
    this.template =
      template != null ? this.templateCreationService.updateExistingTemplate(template) : this.templateCreationService.getNewTemplate()

    if (this.template.logo == null) {
      this.template.logo = this.currentCompany.logo ?? ""
    }
    this.previousTemplate = this.templateCreationService.cloneTemplate(this.template)
    this.droppedElements = this.template.elements
  }

  clear() {
    this.droppedElements = []
    this.previewData = []
  }

  get templateCreatorUrlPrefix(): string {
    return "/formsBuilder"
  }

  async openSaveTemplateDialog(template?: Template): Promise<SaveTemplateDialogResult> {
    const data = await this.dialog.open(SaveTemplateDialogComponent, { data: { template } }).afterClosed().pipe(take(1)).toPromise()

    return data
  }

  async new() {
    const hasUnsavedData = await this.templateCreationService.templateHasUnsavedData$.toPromise()

    if (hasUnsavedData) {
      const result = await this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: "Are you sure?",
            content: "You have unsaved data. Press no if you want to go back to your unsaved template.",
          },
        })
        .afterClosed()
        .pipe(take(1))
        .toPromise()

      if (!result) {
        return
      }
    }
    await this.newTemplate()
    await this.router.navigate([this.templateCreatorUrlPrefix])
  }

  async save(template: Template) {
    if (this.isNewTemplate) {
      const data = await this.openSaveTemplateDialog()

      if (!data) {
        return
      }
      template.name = data.name
      template.description = data.description
    }

    if (template.name !== "") {
      try {
        this.showProgressBar = true
        this.snackbarService.showMessage(`Saving template ${template.name || ""}...`)
        const savedTemplate = await this.templateCreationService.saveTemplate({
          template,
          creatorOrEditor: this.currentUser,
          company: this.currentCompany,
        })
        this.snackbarService.showMessage(`Template ${template.name || ""} is saved!`)
        await this.newTemplate(savedTemplate)
        await this.router.navigate([`${this.templateCreatorUrlPrefix}/${savedTemplate.uid}`])
        this.templateCreationService.sendMessage("saveDone", savedTemplate)
      } catch (err) {
        this.snackbarService.showMessage(err.message)
      } finally {
        this.showProgressBar = false
      }
    }
  }

  async publish(data: IEditFormsTemplateDialogSaveData) {
    const template = this.template

    try {
      this.showProgressBar = true

      template.isDraft = false
      template.name = data.reportName
      template.description = data.description
      template.sharingType = data.sharedWith
      template.publicVersion = data.publicVersion
      template.tags = data.tags

      const savedTemplate = await this.templateCreationService.saveTemplate({
        template,
        creatorOrEditor: this.currentUser,
        company: this.currentCompany,
        publishData: {
          changelog: data.changelogText,
          publicVersion: data.publicVersion,
        },
      })
      await this.newTemplate(savedTemplate)
      this.snackbarService.showMessage(`Template ${template.name || ""} is saved and published!`)

      // Navigate back to company templates list
      await this.router.navigate(["forms/company"])
    } catch (err) {
      this.snackbarService.showMessage(err.message)
    } finally {
      if (this.previousTemplate) {
        this.previousTemplate.isDraft = false
      }
      this.showProgressBar = false
    }
  }

  async edit() {
    const data = await this.openSaveTemplateDialog(this.template)
    if (data) {
      this.template.name = data.name
      this.template.description = data.description
      await this.save(this.template)
    }
  }

  async uploadLogo(templateOptionsLogoType: TemplateOptionsLogoType) {
    const logoUrl = await this.templateCreationService.uploadLogo()
    if (logoUrl != null) {
      switch (templateOptionsLogoType) {
        case TemplateOptionsLogoType.TEMPLATE_LOGO:
          this.template.logo = logoUrl
          break
        case TemplateOptionsLogoType.FOOTER_LOGO:
          this.template.footerLogo = logoUrl
          break
        default:
          break
      }

      this.templateCreationService.checkIfTemplatesAreEqual(this.template, this.previousTemplate)
    }
  }

  exportTemplateToJson() {
    const legacyFormat = this.legacyTemplateService.transformTemplate(this.template)
    const internalFormat = transformLegacyToInternal(legacyFormat.data)
    const externalFormat = transformInternalToExternal(internalFormat)
    const filename = sanitize(`${this.template.name || "template_export"}.json`)

    FileSaver.saveAs(new Blob([JSON.stringify(externalFormat)]), filename)
  }
}
