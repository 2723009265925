import { inject } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router"
import { map } from "rxjs/operators"

import { FieldAuthService } from "@services"

export const authGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(FieldAuthService)
  const router = inject(Router)

  // We *may* be signed in already, but we need to have certain values in sessionStorage for the top-bar to be initialized correctly.
  // Check for 'one-login:issuer' here, assume others are missing if this key is missing.
  if (!sessionStorage.getItem("one-login:issuer")) {
    return router.parseUrl("/login")
  }

  return authService.isLoggedIn().pipe(
    map((isLoggedIn) => {
      if (!isLoggedIn) {
        return router.parseUrl("/login")
      }

      return true
    })
  )
}
