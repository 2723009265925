import { Component, EventEmitter, Input, Output } from "@angular/core"
import { Person, Project } from "@models/common"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { NgIf, NgClass } from "@angular/common"
import { MatRippleModule } from "@angular/material/core"
import { MatLegacyCardModule } from "@angular/material/legacy-card"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { TooltipModule } from "primeng/tooltip"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "app-project-list-item",
  templateUrl: "./project-list-item.component.html",
  styleUrls: ["./project-list-item.component.scss"],
  standalone: true,
  imports: [
    FlexModule,
    TooltipModule,
    MatLegacyButtonModule,
    MatIconModule,
    MatLegacyCardModule,
    MatRippleModule,
    NgIf,
    NgClass,
    ExtendedModule,
  ],
})
export class ProjectListItemComponent {
  @Input() project: Project
  @Input() currentUser: Person

  @Output()
  favoriteToggled = new EventEmitter()

  @Input()
  isFavorite: boolean = false

  @Output() projectSelected = new EventEmitter()

  get details() {
    return this.project.details || {}
  }
  get aggregateData() {
    return this.project.aggregateData || {}
  }

  get projectType() {
    return this.details.projectType || ""
  }
  get municipalNumber() {
    return this.details.municipalNumber || ""
  }
  get cadastrialUnitNumber() {
    return this.details.cadastrialUnitNumber || ""
  }
  get idNumber() {
    return this.details.idNumber || ""
  }
  get projectPhase() {
    return this.details.projectPhase || ""
  }
  get owner() {
    return this.details.owner || ""
  }
  get client() {
    return this.details.client || ""
  }
  get cost() {
    return this.details.cost || ""
  }

  get usersCount() {
    return this.aggregateData.usersCount || 0
  }
  get drawingsCount() {
    return this.aggregateData.drawingsCount || 0
  }
  get itemsCount() {
    return this.aggregateData.itemsCount || 0
  }
  get reportsCount() {
    return this.aggregateData.reportsCount || 0
  }
  get legacyReportsCount() {
    return this.aggregateData.legacyReportsCount || 0
  }
  get totalReportsCount() {
    return this.reportsCount + this.legacyReportsCount
  }
  get usersCountStr() {
    return `${this.usersCount} ${this.usersCount > 1 ? "people" : "person"}`
  }
  get drawingsCountStr() {
    return `${this.drawingsCount} ${this.drawingsCount > 1 ? "drawings" : "drawing"}`
  }
  get itemsCountStr() {
    return `${this.itemsCount} ${this.itemsCount > 1 ? "items" : "item"}`
  }
  get totalReportsCountStr() {
    return `${this.totalReportsCount} ${this.totalReportsCount > 1 ? "reports" : "report"}`
  }
  get currentUserActiveItemsStr() {
    return `${this.currentUserActiveItems} active ${this.currentUserActiveItems === 1 ? "task" : "tasks"}`
  }

  get hasDetails() {
    return this.details
      ? this.projectType ||
          this.municipalNumber ||
          this.cadastrialUnitNumber ||
          this.idNumber ||
          this.projectPhase ||
          this.owner ||
          this.client ||
          this.cost
      : false
  }

  get totalAndActiveItemsPerUser() {
    return this.aggregateData.totalAndActiveItemsPerUser || {}
  }

  get currentUserActiveItems() {
    if (this.currentUser != null && this.currentUser.uid != null && this.totalAndActiveItemsPerUser[this.currentUser.uid] != null) {
      return this.totalAndActiveItemsPerUser[this.currentUser.uid].active || 0
    }

    return null
  }

  get tooltipText(): string {
    const parts = []

    if (this.projectType) {
      parts.push(`Project type: ${this.projectType}`)
    }
    if (this.municipalNumber) {
      parts.push(`Municipal number: ${this.municipalNumber}`)
    }
    if (this.cadastrialUnitNumber) {
      parts.push(`Cadastral unit number: ${this.cadastrialUnitNumber}`)
    }
    if (this.idNumber) {
      parts.push(`ID number: ${this.idNumber}`)
    }
    if (this.projectPhase) {
      parts.push(`Phase: ${this.projectPhase}`)
    }
    if (this.owner) {
      parts.push(`Owner: ${this.owner}`)
    }
    if (this.client) {
      parts.push(`Client: ${this.client}`)
    }
    if (this.cost) {
      parts.push(`Cost: ${this.cost}`)
    }

    return parts.join("\n")
  }
}
