import { Component, Input, OnInit } from "@angular/core"
import { SafeUrl } from "@angular/platform-browser"
import { IImage } from "@checkd-form/form-view/elements/item-list-element/item-list-element.component"
import { FilestackService } from "@services/filestack.service"
import { Lightbox } from "ngx-lightbox"
import { MatBadgeModule } from "@angular/material/badge"
import { FlexModule } from "@angular/flex-layout/flex"
import { NgFor, NgIf, NgTemplateOutlet, SlicePipe } from "@angular/common"

@Component({
  selector: "checkd-report-thumbnail-row",
  templateUrl: "./report-thumbnail-row.component.html",
  styleUrls: ["./report-thumbnail-row.component.scss"],
  standalone: true,
  imports: [NgFor, NgIf, FlexModule, NgTemplateOutlet, MatBadgeModule, SlicePipe],
})
export class ReportThumbnailRowComponent implements OnInit {
  @Input() images: IImage[]
  @Input() galleryID: number

  // max images displayed in thumbnail row
  readonly MAX_IMAGES = 3
  // Square thumbnails
  readonly THUMBNAIL_SIZE = 80

  _album: { src: string; caption: string; thumb: SafeUrl }[] = []
  constructor(public fileStack: FilestackService, private _lightbox: Lightbox) {}

  ngOnInit() {
    this._album = this.images.map((image) => {
      return {
        src: image.url,
        caption: image.caption,
        thumb: this.getThumbnail(image.url),
      }
    })
  }

  open(index: number): void {
    // open lightbox
    // @ts-ignore
    this._lightbox.open(this._album, index, {
      showImageNumberLabel: true,
      wrapAround: true,
      disableScrolling: true,
    })
  }

  getThumbnail(imageURL: string) {
    return this.fileStack.getThumbnailUrl(imageURL, this.THUMBNAIL_SIZE, undefined, undefined, "crop")
  }

  get numberOfHiddenThumbnails(): number {
    if (this.images.length <= this.MAX_IMAGES) {
      return 0
    }

    return this.images.length - this.MAX_IMAGES
  }

  get hasHiddenThumbnails(): boolean {
    return this.numberOfHiddenThumbnails > 0
  }

  get badgeLabel(): string {
    return `+${this.numberOfHiddenThumbnails}`
  }
}
