import { ProjectAggregateData } from "./aggregate-data"
import { BaseModelData } from "./base-model.interface"
import { RelationInterface } from "./relation.interface"

export interface ProjectLocationData {
  address?: string
  latitude?: number
  longitude?: number
}

export interface ProjectDetailsData {
  projectType?: string
  municipalNumber?: string
  cadastrialUnitNumber?: string
  idNumber?: string
  projectPhase?: string
  owner?: string
  client?: string
  cost?: string

  [propName: string]: any
}

export interface ProjectUserData {
  position?: string
}

export interface ProjectData extends BaseModelData {
  name: string
  description?: string
  location: ProjectLocationData
  createdDateTime?: Date
  modifiedDateTime?: Date

  details?: ProjectDetailsData
  users?: RelationInterface[]

  hasPublicTemplates?: boolean

  uid?: string
  lastTaskNumber?: number
  tags?: string[]

  [key: string]: any

  peopleData?: {
    [key: string]: ProjectUserData
  }
  aggregateData?: ProjectAggregateData

  bcfGuid?: string
}
