import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from "@angular/platform-browser"
import { Injectable, NgModule } from "@angular/core"

import { CommonModule } from "@angular/common"

import { ReportDrawingComponent } from "./report-drawing.component"
import { CheckdDrawingComponent } from "./checkd-drawing/checkd-drawing.component"

import { LeafletModule } from "@asymmetrik/ngx-leaflet"

import { LeafletService } from "./services"
import { LeafletCheckdDrawingDirective } from "./directives/leaflet-checkd-drawing.directive"
import { LeafletCheckdItemDirective } from "./directives/leaflet-checkd-item.directive"

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    press: { time: 500 },
  }
}

@NgModule({
  imports: [
    CommonModule,
    LeafletModule,
    ReportDrawingComponent,
    CheckdDrawingComponent,
    LeafletCheckdDrawingDirective,
    LeafletCheckdItemDirective,
  ],
  exports: [LeafletCheckdDrawingDirective, LeafletCheckdItemDirective, ReportDrawingComponent, CheckdDrawingComponent],
  providers: [
    LeafletService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ], // ,
})
export class ReportDrawingModule {}
