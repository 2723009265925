<!--
Mainfield and Subfield elements are inside values array as it should be
But their respective values are not inside each element (instead they are empty).
The values are stored in the top level and are mapped as following:
title -> Mainfield value
info -> Subfield  value
Really weird and inconsistent!
-->
<h2 *ngIf="element.title" class="checkd-dark">{{ element.title }}</h2>
<h3 *ngIf="element.info">{{ element.info }}</h3>
