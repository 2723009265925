import { Component } from "@angular/core"
import { ElementOptionCode } from "../../../../template-creator/models/template-elements/template-element"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { TextFieldModule } from "@angular/cdk/text-field"
import { FormsModule } from "@angular/forms"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { NgIf } from "@angular/common"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"

@Component({
  selector: "app-textfield-element",
  templateUrl: "./textfield-element.component.html",
  styleUrls: ["./textfield-element.component.scss"],
  standalone: true,
  imports: [MatLegacyTooltipModule, NgIf, MatLegacyFormFieldModule, MatLegacyInputModule, FormsModule, TextFieldModule],
})
export class TextfieldElementComponent extends DefaultElementComponent {
  readonly MAX_LENGTH_OPT_CODE = ElementOptionCode.TEXT_FIELD_MAX_LENGTH

  // TODO: Add auto-resizing on textarea
  getType() {
    if (this.element.type === "textfieldint") {
      return "number"
    }

    return "text"
  }

  getHintLabel() {
    if (!this.hasOption(this.MAX_LENGTH_OPT_CODE)) {
      return null
    }

    const maxLenObj = this.getOption(this.MAX_LENGTH_OPT_CODE)

    return `Max ${maxLenObj!.value} characters`
  }
}
