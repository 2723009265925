<ng-container *ngIf="navigationService.showTopBar$ | async">
  <next-app-bar *ngIf="oneLogin$ | async" client-id="field" [authority]="authority$ | async" [target]="target$ | async">
    <mat-toolbar [ngClass]="{ dark: darkTopBar$ | async, shadow: useShadow$ | async }">
      <button
        type="button"
        aria-label="Toggle sidenav"
        class="hamburger-menu"
        mat-icon-button
        (click)="sideNavService.toggle()"
        *ngIf="sideNavService.isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="top-bar__left-side">
        <!-- Show the icon when the apps are ready -->
        <div class="top-bar__dot-menu-container" *ngIf="false">
          <img src="./assets/icons/top-bar/dot-menu.svg" alt="dot-menu" class="top-bar__dot-menu" />
        </div>
        <a [routerLink]="'/'" class="top-bar__logo-link">
          <img class="top-bar__company-logo" src="/assets/logos/logoNextField.svg" alt="Next logo" />
        </a>
      </div>

      <!-- Pushes everything after this to the right -->
      <div class="top-bar__right-side">
        <!--      <a (click)="onChatBubbleClicked()" *ngIf="shouldDisplayChatBubbleIcon$ | async" class="tw-relative">-->
        <!--        <div-->
        <!--          [matBadge]="fieldChatDashboardService.currentUserUnreadCount$ | async"-->
        <!--          [matBadgeHidden]="((fieldChatDashboardService.currentUserUnreadCount$ | async) ?? 0) <= 0"-->
        <!--          matBadgeSize="small"-->
        <!--          matBadgeColor="warn"-->
        <!--        >-->
        <!--          <checkd-chat-bubble-icon class="cursor-pointer"></checkd-chat-bubble-icon>-->
        <!--        </div>-->
        <!--      </a>-->

        <checkd-notifications *ngIf="userName$ | async" id="notifications_btn"></checkd-notifications>

        <button mat-icon-button [mat-menu-trigger-for]="helpMenu" id="help_menu">
          <mat-icon>help_outline</mat-icon>
        </button>
      </div>

      <mat-menu #helpMenu="matMenu">
        <a mat-menu-item href="https://intercom.help/checkd" id="intercom_guides" target="_blank">Guides</a>
        <a mat-menu-item id="intercom">Help & Support</a>
      </mat-menu>
    </mat-toolbar>
  </next-app-bar>
</ng-container>
