import { Component, OnInit } from "@angular/core"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "app-counter-element",
  templateUrl: "./counter-element.component.html",
  styleUrls: ["./counter-element.component.scss"],
  standalone: true,
  imports: [FlexModule, MatLegacyButtonModule, MatIconModule],
})
export class CounterElementComponent extends DefaultElementComponent implements OnInit {
  override ngOnInit() {
    this.element.value = this.element.value || 0
  }

  increase() {
    this.setValue(this.element.value + 1)
  }

  decrease() {
    if (this.getValue() > 0) {
      this.setValue(this.getValue() - 1)
    }
  }
}
