import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { CheckdStatusFilterComponent } from "@checkd-ui"
import { Person, WorkflowStatusFilter } from "@models/common"
import { ProjectItemsDisplayed } from "../../project-items/project-items-displayed.enum"
import { ProjectService, UserService } from "@services"
import { BehaviorSubject } from "rxjs"
import { IItemCount, ItemTableService } from "../../../items/item-table.service"
import { PrimaryButtonDirective } from "../../../next-ui/button/primary-button.directive"
import { CheckdStatusFilterComponent as CheckdStatusFilterComponent_1 } from "../../../checkd-ui/checkd-status-filter/checkd-status-filter.component"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { AvatarModule } from "ngx-avatars"
import { MatRippleModule } from "@angular/material/core"
import { NgIf, AsyncPipe } from "@angular/common"

@Component({
  selector: "checkd-project-items-top-action-bar",
  templateUrl: "./project-items-top-action-bar.component.html",
  styleUrls: ["./project-items-top-action-bar.component.scss"],
  standalone: true,
  imports: [NgIf, MatRippleModule, AvatarModule, ExtendedModule, CheckdStatusFilterComponent_1, PrimaryButtonDirective, AsyncPipe],
})
export class ProjectItemsTopActionBarComponent implements OnInit {
  @Input() canSeeAllItems: boolean
  @Input() currentUser: Person

  readonly projectItemsDisplayedEnum = ProjectItemsDisplayed
  @Input() projectItemsDisplayed$: BehaviorSubject<ProjectItemsDisplayed>
  @Input() projectItemsStatusCounts: { [status: string]: number }

  @Output() notifyParentFilterStateChanged = new EventEmitter<WorkflowStatusFilter>()
  @Output() clearFiltersButtonClicked = new EventEmitter()

  @ViewChild("statusFilter", { static: true }) statusFilter: CheckdStatusFilterComponent

  constructor(public userService: UserService, public projectService: ProjectService, public itemTableService: ItemTableService) {
    return
  }

  ngOnInit() {}

  itemsHaveBeenFiltered(count: IItemCount) {
    return count.itemsShown !== count.total
  }

  resetFilters() {
    this.statusFilter.clear()
    this.clearFiltersButtonClicked.emit()
  }

  // i.e. when 'my items', 'all items', or 'my company items' was clicked
  onItemsDisplayedButtonClicked(value: ProjectItemsDisplayed) {
    const current = this.projectItemsDisplayed$?.value

    if (current === undefined || value === current) {
      return
    }

    this.projectItemsDisplayed$.next(value)
  }
}
