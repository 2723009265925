import { Component, EventEmitter, forwardRef, Input, NgZone, OnDestroy, OnInit, Output } from "@angular/core"

import { Company, Person } from "@models/common"

import { Observable, Subscription } from "rxjs"
import { debounceTime, map } from "rxjs/operators"
import { Template } from "../../models/template"
import { TemplateElement } from "../../models/template-elements/template-element"
import { TemplateCreationService } from "../../services/template-creation.service"
import { TemplateOptionsLogoType, LegacyReportPreviewComponent } from "../legacy-report-preview/legacy-report-preview.component"
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag } from "@angular/cdk/drag-drop"
import { TemplateElementConfigComponent } from "../template-element-config/template-element-config.component"
import { TemplateElementComponent } from "../template-element/template-element.component"
import { NgFor } from "@angular/common"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"

@Component({
  selector: "checkd-template-creation",
  templateUrl: "./template-creation.component.html",
  styleUrls: ["./template-creation.component.scss"],
  standalone: true,
  imports: [
    MatLegacyTooltipModule,
    NgFor,
    CdkDrag,
    CdkDropList,
    TemplateElementComponent,
    TemplateElementConfigComponent,
    LegacyReportPreviewComponent,
  ],
})
export class TemplateCreationComponent implements OnInit, OnDestroy {
  @Input() currentUser: Person
  @Input() currentCompany: Company
  @Input() template: Template
  @Input() templateElements: TemplateElement[] = []
  @Input() droppedElements: TemplateElement[] = []
  @Input() previewData: any[] = []

  @Output() onNewTemplate = new EventEmitter()
  @Output() onSave = new EventEmitter()
  @Output() onPublish = new EventEmitter()
  @Output() onEdit = new EventEmitter()
  @Output() uploadLogo = new EventEmitter<TemplateOptionsLogoType>()
  @Output() onTemplateOptionsTextChanged = new EventEmitter<void>()

  // Used for reducing the preview update calls to once per e.g. 1000 milliseconds
  updateDebounceTimeInMs: number = 1000
  previewEnabled: boolean = true

  subscriptions: Subscription[] = []

  // templateConfigOptions: Options = {
  //   group: {
  //     name: "templateConfig",
  //     put: ["templates"],
  //   },
  //   onSort: (event: any) => {
  //     this.templateCreationService.sendMessage("onSort", event)
  //   },
  //   onUpdate: (event: any) => {
  //     this.templateCreationService.sendMessage("onUpdate", event)
  //   },
  //   handle: ".handle",
  // }

  // templateOptions: Options = {
  //   group: {
  //     name: "templates",
  //     pull: "clone",
  //   },
  //   sort: false,
  // }

  constructor(private templateCreationService: TemplateCreationService) {}

  ngOnInit() {
    this.setupSubscriptions()
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.templateCreationService.messageListener$.pipe(debounceTime(this.updateDebounceTimeInMs)).subscribe((_) => {
        this.template.elements = this.droppedElements || []
        this.generatePreviewData()
      }),
    ]
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  addNewElement(element: TemplateElement) {
    this.droppedElements.push(this.deepCopy(element))
    this.templateCreationService.sendMessage("onUpdate", event)
  }

  drop(event: CdkDragDrop<TemplateElement[]>) {
    moveItemInArray(this.droppedElements, event.previousIndex, event.currentIndex)
    this.templateCreationService.sendMessage("onUpdate", event)
  }

  deepCopy = (item: TemplateElement): TemplateElement => {
    return JSON.parse(JSON.stringify(item))
  }

  onElementRemoved(source: TemplateElement[], element: TemplateElement) {
    const index = source.indexOf(element)
    if (index > -1) {
      source.splice(index, 1)
    }
  }

  onDuplicate(element: TemplateElement, index: number) {
    const elementClone = JSON.parse(JSON.stringify(element))
    this.droppedElements.splice(index, 0, elementClone)
  }

  onPreviewEnabledChanged(value: boolean) {
    // When re enabling update preview with all the changes
    if (!this.previewEnabled && value == true) {
      this.previewEnabled = value
      this.template.elements = this.droppedElements || []
      this.generatePreviewData()

      return
    }
    this.previewEnabled = value
  }

  generatePreviewData() {
    if (this.previewEnabled) {
      this.previewData = this.templateCreationService.generatePreviewData(this.template.elements)
    }
  }

  newTemplate() {
    this.onNewTemplate.emit()
  }

  save() {
    this.onSave.emit(this.template)
  }

  publish() {
    this.onPublish.emit()
  }

  edit() {
    this.onEdit.emit()
  }
}
