import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { NextExperimentalService } from "@services/apis/next-experimental.service"
import { MatLegacyProgressSpinnerModule } from "@angular/material/legacy-progress-spinner"
import { MatIconModule } from "@angular/material/icon"
import { MatLineModule } from "@angular/material/core"
import { MatLegacyListModule } from "@angular/material/legacy-list"
import { NgIf, NgFor, AsyncPipe } from "@angular/common"
import { MatLegacyInputModule } from "@angular/material/legacy-input"

@Component({
  selector: "checkd-connect-next-project-dialog",
  templateUrl: "./connect-next-project-dialog.component.html",
  styleUrls: ["./connect-next-project-dialog.component.scss"],
  standalone: true,
  imports: [
    MatDialogModule,
    MatLegacyInputModule,
    NgIf,
    MatLegacyListModule,
    NgFor,
    MatLineModule,
    MatIconModule,
    MatLegacyProgressSpinnerModule,
    AsyncPipe,
  ],
})
export class ConnectNextProjectDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    public nextExperimentalService: NextExperimentalService,
    public dialogRef: MatDialogRef<any>
  ) {
    this.nextExperimentalService.nextProjectProjectSearch$.next("")
  }

  ngOnInit(): void {}
}
