import { SelectionModel } from "@angular/cdk/collections"
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox"
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator"
import { MatSort } from "@angular/material/sort"
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table"
import { UserService } from "@services"
import { CheckdAcSearchComponent } from "src/app/dashboard/checkd-ui/checkd-ac-search/checkd-ac-search.component"
import { CheckdDaterangeComponent } from "src/app/dashboard/checkd-ui/checkd-daterange/checkd-daterange.component"
import * as moment from "moment"
import { Subscription } from "rxjs"
import { IMultiselect } from "../../checkd-ui/checkd-multiselect/checkd-multiselect.component"
import { IMultiLangDialogData } from "../../dialogs/multi-lang-confirmation-dialog/multi-lang-confirmation-dialog.component"
import { Item, ItemMenuActions, Person, Role } from "../../models/common"
import { formatTimestamp } from "../../utilities"
import { ItemTableService } from "../item-table.service"
import { ActionTypeSelected } from "../items-table-action-bar/items-table-action-bar.component"
import { allItemTableColumns, getDefaultTable, IColumnConfig, IDefaultTableConfig, IUserTableConfig } from "./table-configs"

interface ItemRow {
  item: Item
  uid: string
  numberAndStatus: { number: number; status: string }
  name: string
  description: string
  tags: string[]
  itemCreatorName: string
  taskAssignerName: string
  numberOfCollaborators: string
  currentUserIsTaskCollaborator: boolean
  taskAssignerCompanyName: string
  latestMessage: string
  createdAt: number
  updatedAt: number
  dueDate: number
  drawingName: string
  projectName: string
  reportName: string
  taskAssigneeName: string
}

interface ItemRowFilter {
  number: string
  itemNumber: string
  title: string
  description: string
  latestMessage: string
  tags: IMultiselect
  itemCreatorNames: IMultiselect
  assignerNames: string[]
  taskAssignerNames: IMultiselect
  taskAssignerCompanyNames: IMultiselect
  taskAssigneeNames: IMultiselect
  drawingNames: IMultiselect
  projectNames: IMultiselect
  reportNames: IMultiselect
  createdAt?: { from?: number; to?: number }
  updatedAt?: { from?: number; to?: number }
  dueDate?: { from?: number; to?: number }
}

function createDefaultItemRowFilter(): ItemRowFilter {
  return {
    number: "",
    itemNumber: "",
    title: "",
    description: "",
    latestMessage: "",
    tags: { selected: [], deselected: [] },
    assignerNames: [],
    itemCreatorNames: { selected: [], deselected: [] },
    taskAssignerNames: { selected: [], deselected: [] },
    taskAssignerCompanyNames: { selected: [], deselected: [] },
    taskAssigneeNames: { selected: [], deselected: [] },
    drawingNames: { selected: [], deselected: [] },
    projectNames: { selected: [], deselected: [] },
    reportNames: { selected: [], deselected: [] },
  }
}

export type TableFor = "projectItems" | "drawings" | "reports" | "myItems"

@Component({
  selector: "checkd-item-list",
  templateUrl: "./item-list.component.html",
  styleUrls: ["./item-list.component.scss"],
})
export class ItemListComponent implements OnInit, OnDestroy {
  //Empty state for items in general
  @Input() noItemsTitle: string = "No items matching your search or filter"
  @Input() noItemsDescription: string = "Try using fewer words and letters, or search using the table columns."
  @Input() noItemsImgSrc: string = "./assets/images/ilustrations/not-found-search.svg"

  @Input() isItemListReady: boolean

  @Input() set items(items: Item[]) {
    this._items = items
    this.transformedItems = (this._items || []).map((item) => this.transformItemData(item))
    this.dataSource.data = this.transformedItems
  }

  @Input() set itemTags(tags: string[]) {
    this._itemTags = Array.from(new Set((tags || []).map((tag) => tag.trim().toUpperCase())).values()).sort()
  }

  get itemTags() {
    return this._itemTags || []
  }

  @Input() set numbers(numbers: number[]) {
    this._numbers = numbers
    this.stringNumbers = numbers.map((it) => it.toString())
  }

  get displayedColumns(): string[] {
    let columnConfig: IColumnConfig[]

    columnConfig = this._userTableConfig ? this._userTableConfig.columns : this._defaultTableConfig.defaultColumns

    let columnNames: string[] = columnConfig.filter((value) => value.checked).map((value) => value.column.columnDef)

    if (this._defaultTableConfig.selectItemsColumn) {
      columnNames = ["select", ...columnNames]
    }

    if (this._defaultTableConfig.menuColumn) {
      columnNames = [...columnNames, "menu"]
    }

    return columnNames
  }

  get editableColumns(): IColumnConfig[] {
    if (!this._userTableConfig) {
      return [...this._defaultTableConfig.defaultColumns]
    }

    return this._userTableConfig.columns
  }

  set editableColumns(columns: IColumnConfig[]) {
    const newConfig = { pageSize: this.pageSize, columns }
    const _ = this.cleanUserTableConfig(newConfig)

    this._userTableConfig = newConfig

    if (this._writeTableConfigToDB) {
      const __ = this.userService.updateItemsTablePreferences(this.currentUser, this._tableFor, newConfig)
    }
  }

  /**
   * Removes or adds columns to make it have the same columns as defined in table-configs.ts
   * @param {IUserTableConfig} config - The object we want to clean
   * @returns Whether the columns were cleaned
   */
  cleanUserTableConfig(config: IUserTableConfig): boolean {
    const known_columns: string[] = this._defaultTableConfig.defaultColumns.map((value) => value.column.columnDef)
    const user_columns: string[] = config.columns.map((val) => val.column.columnDef)

    // TODO: this can be in its own method and should be tested
    // first find columns that exists in known columns that does not exist in user columns
    const columns_to_add = known_columns.filter((column) => !user_columns.includes(column))

    // then push those columns to user columns. By default, add them to the end, and leave unchecked TODO: ask Mikel
    columns_to_add.forEach((column) => {
      // @ts-ignore
      const newColumn: IColumnConfig = { checked: false, column: { ...allItemTableColumns[column] } }
      config.columns.push(newColumn)
    })

    // then find whether columns exists in user columns that does not exist in known columns
    const columns_to_remove = user_columns.filter((column) => !known_columns.includes(column))

    // then remove those from user_columns
    const indexes = columns_to_remove.map((columnDef) => config.columns.findIndex((colConfig) => colConfig.column.columnDef === columnDef))

    indexes.reverse().forEach((idx) => {
      if (idx !== -1) {
        config.columns.splice(idx, 1)
      }
    })

    return !!(columns_to_add || columns_to_remove)
  }

  get pageSize(): number {
    return this._userTableConfig?.pageSize || this._defaultTableConfig.pageSize
  }

  checkPageSize(event: PageEvent) {
    if (event.pageSize === this.pageSize) {
      return
    }

    if (!this._userTableConfig) {
      this._userTableConfig = { pageSize: event.pageSize, columns: [...this._defaultTableConfig.defaultColumns] }
    } else {
      this._userTableConfig = { ...this._userTableConfig, pageSize: event.pageSize }
    }

    if (this._writeTableConfigToDB) {
      const _ = this.userService.updateItemsTablePreferences(this.currentUser, this._tableFor, this._userTableConfig)
    }
  }

  get pageSizes(): number[] {
    return this._defaultTableConfig.pageSizes
  }

  get displayedSearchColumns() {
    return this.displayedColumns.map((col) => col + "Search")
  }

  public get maxPageLength() {
    return Math.ceil((this.itemsCount || 1) / (this.pageSize || 1))
  }

  public get itemsCount() {
    return (this.items || []).length
  }

  public get pageNumber() {
    return Math.min(this._pageNumber, this.maxPageLength)
  }

  public set pageNumber(n: number) {
    this._pageNumber = Math.min(n, this.maxPageLength)
  }

  // Just need a reference for the table column definitions in the HTML file
  _allItemTableColumns = allItemTableColumns

  @Input() itemCreatorNames: string[] = []

  _numbers: number[] = []
  stringNumbers: string[] = []
  @Input() taskAssignerNames: string[] = []
  @Input() taskAssignerCompanyNames: string[] = []
  @Input() taskAssigneeNames: string[] = []
  @Input() drawingNames: string[] = []
  @Input() projectNames: string[] = []
  @Input() reportNames: string[] = []

  @Input() currentUser: Person
  @Input() userProjectRole: Role

  @Input() restrictedItemMenuActions: ItemMenuActions[] = []
  @Input() additionalItemMenuActions: ItemMenuActions[] = []
  @Input() isProjectLockedOrArchived: boolean = false
  @Input() tooManyItemsForBulkActions: boolean = true
  @Input() maxItemsForBulkActions: number
  @Input() canUseItemFlow: boolean
  @Input() canExportToBcf: boolean
  @Input() areAllItemsAttachable: boolean
  @Input() hideNewItemButton: boolean = false
  @Input() hideItemsSelectedText: boolean = false
  @Input() hideItemsActions: boolean = false

  _writeTableConfigToDB = false
  _defaultTableConfig: IDefaultTableConfig
  _userTableConfig: IUserTableConfig
  _tableFor: TableFor
  @Input() set tableFor(val: TableFor) {
    this._tableFor = val

    switch (val) {
      case "projectItems":
      case "drawings":
      case "myItems":
        this._writeTableConfigToDB = true
        this._defaultTableConfig = getDefaultTable(val)
        break
      case "reports":
        this._defaultTableConfig = getDefaultTable(val)
        break
      default:
        throw Error(`'${val}' is not a recognized value for tableFor`)
    }
  }

  // Needed for checkd-items-table-action-bar
  selectedItems: Item[] = []

  @Output() itemsSelected = new EventEmitter<Item[]>()
  @Output() itemsFiltered = new EventEmitter<Item[]>()
  @Output() itemSelected = new EventEmitter<Item>()
  @Output() itemMenuClicked = new EventEmitter<Item>()
  @Output() resetFilters = new EventEmitter()

  @Output() menuOptionSelected = new EventEmitter()
  @Output() itemActionClicked = new EventEmitter<ActionTypeSelected>()

  private _paginator: MatPaginator
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this._paginator = paginator
    this.setDataSourceAttributes()
  }

  private _sort: MatSort
  @ViewChild(MatSort) set sort(sort: MatSort) {
    this._sort = sort
    this.setDataSourceAttributes()
  }

  private setDataSourceAttributes() {
    this.dataSource.paginator = this._paginator
    this.dataSource.sort = this._sort
  }

  @ViewChild("numberFilter") numberFilter: CheckdAcSearchComponent
  @ViewChild("itemNumberFilter") itemNumberFilter: CheckdAcSearchComponent
  @ViewChild("nameFilter") nameFilter: CheckdAcSearchComponent
  @ViewChild("descriptionFilter") descriptionFilter: CheckdAcSearchComponent
  @ViewChild("latestMessageFilter") latestMessageFilter: CheckdAcSearchComponent
  @ViewChild("tagsFilter") tagsFilter: CheckdAcSearchComponent
  @ViewChild("itemCreatorNameFilter") itemCreatorNameFilter: CheckdAcSearchComponent
  @ViewChild("taskAssignerNameFilter") taskAssignerNameFilter: CheckdAcSearchComponent
  @ViewChild("taskAssignerCompanyNameFilter") taskAssignerCompanyNameFilter: CheckdAcSearchComponent
  @ViewChild("createdAtFilter") createdAtFilter: CheckdDaterangeComponent
  @ViewChild("updatedAtFilter") updatedAtFilter: CheckdDaterangeComponent
  @ViewChild("dueDateFilter") dueDateFilter: CheckdDaterangeComponent
  @ViewChild("taskAssigneeNameFilter") taskAssigneeNameFilter: CheckdAcSearchComponent
  @ViewChild("drawingNameFilter") drawingNameFilter: CheckdAcSearchComponent
  @ViewChild("projectNameFilter") projectNameFilter: CheckdAcSearchComponent
  @ViewChild("reportNameFilter") reportNameFilter: CheckdAcSearchComponent

  dataSource = new MatTableDataSource<ItemRow>()
  selection = new SelectionModel<ItemRow>(true, [])

  _pageNumber: number = 1

  _items: Item[]
  _itemTags: string[]

  transformedItems: ItemRow[]

  filterValues: ItemRowFilter = createDefaultItemRowFilter()

  subscriptions: Subscription = new Subscription()

  constructor(private userService: UserService, private itemTableService: ItemTableService) {
    return
  }

  onAcFilterChange(type: string, event: { from: number; to: number }) {
    // @ts-ignore
    this.filterValues[type] = event
    this.dataSource.filter = JSON.stringify(this.filterValues)
  }

  get shouldDisplayEmptySearchResult(): boolean {
    const hasInitialData = (this.dataSource?.data?.length ?? 0) > 0
    const emptyAfterFiltering = (this.dataSource?.filteredData?.length ?? 0) === 0

    return hasInitialData && emptyAfterFiltering
  }

  ngOnInit() {
    if (!this._defaultTableConfig) {
      throw Error("'[tableFor]' is required but was not provided")
    }

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "itemNumber":
        case "numberAndStatus":
          return item.numberAndStatus.number
        case "itemDescription":
          return item.description
        default:
          // @ts-ignore
          return item[property]
      }
    }
    this.dataSource.filterPredicate = this.tableFilter()
    this.setupSubscriptions()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  setupSubscriptions() {
    this.subscriptions.add(
      this.dataSource.connect().subscribe((_) => {
        this.itemTableService.updateCount((this.dataSource.filteredData || []).length, (this.dataSource.data || []).length)
        this.itemsFiltered.emit((this.dataSource.filteredData || []).map((row) => row.item))
      })
    )

    if (this._tableFor === "projectItems") {
      this.subscriptions.add(
        this.userService.currentUserProjectItemsTablePreferences$.subscribe((prefs) => {
          if (prefs) {
            const _ = this.cleanUserTableConfig(prefs)
            this._userTableConfig = prefs
          }
        })
      )
    }

    if (this._tableFor === "drawings") {
      this.subscriptions.add(
        this.userService.currentUserDrawingsItemsTablePreferences$.subscribe((prefs) => {
          if (prefs) {
            const _ = this.cleanUserTableConfig(prefs)
            this._userTableConfig = prefs
          }
        })
      )
    }

    if (this._tableFor === "myItems") {
      this.subscriptions.add(
        this.userService.currentUserMyItemsTablePreferences$.subscribe((prefs) => {
          if (prefs) {
            const _ = this.cleanUserTableConfig(prefs)
            this._userTableConfig = prefs
          }
        })
      )
    }
  }

  getLegacyReportUid(item: Item) {
    return item.aggregateData.legacyReportUid || ""
  }

  formatTimestamp(timestamp: any, withTime = false) {
    return formatTimestamp(timestamp, withTime)
  }

  tableFilter(): (itemRow: ItemRow, filter: string) => boolean {
    const checkDateRange = (timestamp: number, timestampFrom: number, timestampTo: number) => {
      if (!timestampFrom && !timestampTo) {
        return true
      }
      if (!timestamp) {
        return false
      }
      if (timestampFrom && timestamp < timestampFrom) {
        return false
      }
      if (timestampTo && timestamp > timestampTo) {
        return false
      }

      return true
    }

    return function (itemRow: ItemRow, filter: string): boolean {
      const searchTerms = JSON.parse(filter) as ItemRowFilter

      const title = (searchTerms.title || "").trim().toLowerCase()
      const titleMatch = title === "" || (itemRow.name || "").trim().toLowerCase().includes(title)

      const description = (searchTerms.description || "").trim().toLowerCase()
      const descriptionMatch = description === "" || (itemRow.description || "").trim().toLowerCase().includes(description)

      const latestMessage = (searchTerms.latestMessage || "").trim().toLowerCase()
      const latestMessageMatch = latestMessage === "" || (itemRow.latestMessage || "").trim().toLowerCase().includes(latestMessage)

      const num = searchTerms.number.trim()
      const numMatch = (itemRow.numberAndStatus.number || -1).toString().includes(num)

      const itemNumber = searchTerms.itemNumber.trim()
      const itemNumberMatch = (itemRow.numberAndStatus.number || -1).toString().includes(itemNumber)

      const selectedTags = searchTerms.tags.selected || []
      const tagsMatch = selectedTags.length < 1 || selectedTags.every((tag) => (itemRow.tags || []).indexOf(tag) > -1)

      const deselectedTags = searchTerms.tags.deselected || []
      const deselectedTagsMatch = deselectedTags.some((tag) => (itemRow.tags || []).includes(tag))

      const selectedCreatorNames = searchTerms.itemCreatorNames.selected || []
      const selectedCreatorNamesMatch = selectedCreatorNames.length < 1 || selectedCreatorNames.indexOf(itemRow.itemCreatorName) > -1

      const deselectedCreatorNames = searchTerms.itemCreatorNames.deselected || []
      const deselectedCreatorNamesMatch = deselectedCreatorNames.includes(itemRow.itemCreatorName)

      const selectedAssignerNames = searchTerms.taskAssignerNames.selected || []
      const selectedAssignerNamesMatch = selectedAssignerNames.length < 1 || selectedAssignerNames.indexOf(itemRow.taskAssignerName) > -1

      const deselectedAssignerNames = searchTerms.taskAssignerNames.deselected || []
      const deselectedAssignerNamesMatch = deselectedAssignerNames.includes(itemRow.taskAssignerName)

      const selectedAssignerCompanyNames = searchTerms.taskAssignerCompanyNames.selected || []
      const selectedAssignerCompanyNamesMatch =
        selectedAssignerCompanyNames.length < 1 || selectedAssignerCompanyNames.indexOf(itemRow.taskAssignerCompanyName) > -1

      const deselectedAssignerCompanyNames = searchTerms.taskAssignerCompanyNames.deselected || []
      const deselectedAssignerCompanyNamesMatch = deselectedAssignerCompanyNames.includes(itemRow.taskAssignerCompanyName)

      // @ts-ignore
      const createdAtMatch = checkDateRange(itemRow.createdAt, (searchTerms.createdAt || {}).from, (searchTerms.createdAt || {}).to)
      // @ts-ignore
      const updatedAtMatch = checkDateRange(itemRow.updatedAt, (searchTerms.updatedAt || {}).from, (searchTerms.updatedAt || {}).to)
      // @ts-ignore
      const dueDateMatch = checkDateRange(itemRow.dueDate, (searchTerms.dueDate || {}).from, (searchTerms.dueDate || {}).to)

      const selectedAssigneeNames = searchTerms.taskAssigneeNames.selected || []
      const selectedAssigneeNamesMatch = selectedAssigneeNames.length < 1 || selectedAssigneeNames.indexOf(itemRow.taskAssigneeName) > -1

      const deselectedAsigneeNames = searchTerms.taskAssigneeNames.deselected || []
      const deselectedAsigneeNamesMatch = deselectedAsigneeNames.includes(itemRow.taskAssigneeName)

      const selectedDrawingNames = searchTerms.drawingNames.selected || []
      const selectedDrawingNamesMatch = selectedDrawingNames.length < 1 || selectedDrawingNames.indexOf(itemRow.drawingName) > -1

      const deselectedDrawingNames = searchTerms.drawingNames.deselected || []
      const deselectedDrawingNamesMatch = deselectedDrawingNames.includes(itemRow.drawingName)

      const selectedProjectNames = searchTerms.projectNames.selected || []
      const selectedProjectNamesMatch = selectedProjectNames.length < 1 || selectedProjectNames.indexOf(itemRow.projectName) > -1

      const deselectedProjectNames = searchTerms.projectNames.deselected || []
      const deselectedProjectNamesMatch = deselectedProjectNames.includes(itemRow.projectName)

      const selectedReportNames = searchTerms.reportNames.selected || []
      const selectedReportNamesMatch = selectedReportNames.length < 1 || selectedReportNames.indexOf(itemRow.reportName) > -1

      const deselectedReportNames = searchTerms.reportNames.deselected || []
      const deselectedReportNamesMatch = deselectedReportNames.includes(itemRow.reportName)

      return (
        numMatch &&
        itemNumberMatch &&
        titleMatch &&
        descriptionMatch &&
        latestMessageMatch &&
        tagsMatch &&
        !deselectedTagsMatch &&
        selectedCreatorNamesMatch &&
        !deselectedCreatorNamesMatch &&
        selectedAssignerNamesMatch &&
        !deselectedAssignerNamesMatch &&
        selectedAssignerCompanyNamesMatch &&
        !deselectedAssignerCompanyNamesMatch &&
        createdAtMatch &&
        updatedAtMatch &&
        dueDateMatch &&
        selectedAssigneeNamesMatch &&
        !deselectedAsigneeNamesMatch &&
        selectedDrawingNamesMatch &&
        !deselectedDrawingNamesMatch &&
        selectedProjectNamesMatch &&
        !deselectedProjectNamesMatch &&
        selectedReportNamesMatch &&
        !deselectedReportNamesMatch
      )
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.filteredData.length

    return numSelected === numRows
  }

  masterToggle(event: MatCheckboxChange) {
    if (event.checked) {
      this.dataSource.filteredData.forEach((row) => this.selection.select(row))
    } else {
      this.selection.clear()
    }

    this.selectedItems = this.selection.selected.map((row) => row.item)
    this.itemsSelected.emit(this.selectedItems)
  }

  selectRow(event: MatCheckboxChange, row: ItemRow) {
    if (event) {
      const itemId = row.item.uid
      const selectedItems = this.selection.selected.filter((selectedRow) => selectedRow.item.uid === itemId)
      if (selectedItems.length > 0) {
        selectedItems.forEach((selectedRow) => this.selection.toggle(selectedRow))
      } else {
        this.selection.toggle(row)
      }

      this.selectedItems = this.selection.selected.map((selectedRow) => selectedRow.item)
      this.itemsSelected.emit(this.selectedItems)
    }
  }

  checkRowSelected(row: ItemRow) {
    const itemId = row.item.uid

    return this.selection.selected.filter((selectedRow) => selectedRow.item.uid === itemId).length > 0
  }

  itemClicked(row: ItemRow) {
    this.itemSelected.emit(row.item)
  }

  onItemMenuClicked(row: ItemRow) {
    this.itemMenuClicked.emit(row.item)
  }

  trackByItemRowByUid(index: number, item: ItemRow): string {
    return item.uid
  }

  transformItemData(item: Item): ItemRow {
    return {
      item,
      uid: item.ref?.id || item.uid,
      numberAndStatus: { number: item.number, status: item.status || "OPEN" },
      name: item.name || "",
      description: item.description || "",
      tags: (item.tags || []).filter((it) => it).map((tag) => tag.trim().toUpperCase()),
      itemCreatorName: item.aggregateData.itemCreatorName || "",
      taskAssignerName: item.aggregateData.taskAssignerName || "",
      latestMessage: item.aggregateData.latestMessage || "",
      numberOfCollaborators: item.numberOfCollaborators ? `${item.numberOfCollaborators}` : "",
      // @ts-ignore
      currentUserIsTaskCollaborator: this.currentUser?.uid
        ? item.aggregateData?.taskCollaboratorUids?.includes(this.currentUser.uid)
        : false,
      taskAssignerCompanyName: item.aggregateData.taskAssignerCompanyName || "",
      createdAt: item.createdAt || 0,
      updatedAt: item.updatedAt || 0,
      dueDate: item.dueDate!,
      drawingName: item.aggregateData.drawingName || "",
      projectName: item.aggregateData.projectName || "",
      reportName: item.aggregateData.legacyReportName || "",
      taskAssigneeName: item.aggregateData.taskAssigneeName || "",
    }
  }

  onResetFilters() {
    this.filterValues = createDefaultItemRowFilter()
    this.dataSource.filter = JSON.stringify(this.filterValues)

    const filters = [
      this.numberFilter,
      this.nameFilter,
      this.descriptionFilter,
      this.tagsFilter,
      this.itemCreatorNameFilter,
      this.taskAssignerNameFilter,
      this.taskAssignerCompanyNameFilter,
      this.createdAtFilter,
      this.updatedAtFilter,
      this.dueDateFilter,
      this.taskAssignerCompanyNameFilter,
      this.taskAssigneeNameFilter,
      this.drawingNameFilter,
      this.projectNameFilter,
    ].filter((it) => it)

    filters.forEach((filter) => filter.clear())

    this.selection.clear()
    this.selectedItems = []
    this.itemsSelected.emit([])
  }

  public clear() {
    this.onResetFilters()
  }

  onDateRangeSelection(key: string, val: [string, string]) {
    if (!val) {
      return
    }

    const fromDate = val[0] ? moment(val[0]).unix() : null
    const toDate = val[1] ? moment(val[1]).unix() : null

    this.onAcFilterChange(key, { from: fromDate!, to: toDate! })
  }
}
