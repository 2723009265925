import { HttpClient } from "@angular/common/http"
import { Component, HostListener, Input, OnDestroy, OnInit } from "@angular/core"
import { MatDialogRef } from "@angular/material/dialog"
import { ActivatedRoute } from "@angular/router"
import { SpinnerDialogComponent } from "@dialogs"
import { Item, Person, Project } from "@models/common"
import { Image } from "@models/common/image"
import { DummyRole, Role, RoleType } from "@models/common/roles-and-permissions"
import { ImageService, SnackbarService } from "@services"
import { FilestackService } from "@services/filestack.service"
import { SideNavService } from "@services/side-nav.service"
import { Subscription } from "rxjs"
import { take } from "rxjs/operators"
import { environment } from "../../../../environments/environment"
import { DialogService } from "../../dialogs/dialog.service"
import { ItemDialogService } from "../../items/item-dialog.service"
import { ProjectGalleryService } from "../services/project-gallery.service"
import { ItemStatusPipe } from "../../utilities/pipes/item-status.pipe"
import { MomentModule } from "ngx-moment"
import { EmptyStateComponent } from "../../checkd-ui/components/empty-state/empty-state.component"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { PrimaryButtonDirective } from "../../next-ui/button/primary-button.directive"
import { DropdownModule } from "primeng/dropdown"
import { CheckboxModule } from "primeng/checkbox"
import { DataViewModule } from "primeng/dataview"
import { SharedModule } from "primeng/api"
import { GalleriaModule } from "primeng/galleria"
import { FormsModule } from "@angular/forms"
import { CalendarModule } from "primeng/calendar"
import { ButtonModule } from "primeng/button"
import { NgIf, NgClass, AsyncPipe, LowerCasePipe, DatePipe } from "@angular/common"

@Component({
  selector: "checkd-project-gallery",
  templateUrl: "./project-gallery.component.html",
  styleUrls: ["./project-gallery.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    ButtonModule,
    CalendarModule,
    FormsModule,
    GalleriaModule,
    SharedModule,
    DataViewModule,
    CheckboxModule,
    DropdownModule,
    PrimaryButtonDirective,
    NgClass,
    ExtendedModule,
    EmptyStateComponent,
    AsyncPipe,
    LowerCasePipe,
    DatePipe,
    MomentModule,
    ItemStatusPipe,
  ],
})
export class ProjectGalleryComponent implements OnInit, OnDestroy {
  @Input() userProjectRole: Role = new DummyRole()
  @Input() project: Project
  @Input() projectMembers: Person[] = []
  @Input() projectItems: Item[] = []

  noAccessToGalleryTitle = "No access to project gallery."
  noAccessToGalleryText = "Only project admins and owners can view the gallery. Contact them for access."
  noAccessToGalleryImgSrc = "./assets/images/ilustrations/not-found-gallery.svg"

  public imageWidth = 200
  public selectedImages: Image[] = []
  public layout: "grid" | "list" = "grid"
  public menuIcons = {
    grid: "assets/icons/project-gallery/dataview-grid-icon.svg",
    gridFilled: "assets/icons/project-gallery/dataview-grid-icon-filled.svg",
    list: "assets/icons/project-gallery/dataview-list-icon.svg",
    listFilled: "assets/icons/project-gallery/dataview-list-icon-filled.svg",
  }

  public dateFormat = "d MMM, y HH:mm"

  currentProjectUid: string
  isHandset: boolean
  activeIndexFullScreenImage: number = 0

  filterDate: Date[]
  allImagesArray: any[]
  imageCount: number

  subscriptions: Subscription[]

  _showFullScreen: boolean = false
  set showFullScreen(val) {
    this._showFullScreen = val
    if (!this.isHandset) this.sideNavService.open()
  }

  get showFullScreen() {
    return this._showFullScreen
  }

  public imagesPerPageOptions = [10, 20, 50]

  public get userHasGalleryAccess() {
    return (
      this.userProjectRole &&
      (this.userProjectRole.roleType === RoleType.PROJECT_ADMINISTRATOR || this.userProjectRole.roleType === RoleType.PROJECT_OWNER)
    )
  }

  constructor(
    public projectGalleryService: ProjectGalleryService,
    public filestackService: FilestackService,
    public sideNavService: SideNavService,
    private route: ActivatedRoute,
    protected httpClient: HttpClient,
    private imageService: ImageService,
    private dialogService: DialogService,
    private snackBar: SnackbarService,
    private itemDialogService: ItemDialogService
  ) {}

  ngOnInit() {
    // Reset date filter when gallery appears
    // @ts-ignore
    this.filterDate = null
    // @ts-ignore
    this.projectGalleryService.setDateFilter(null, null)

    // FIXME Ugly hack to make sure the gallery loads the first time the tab is selected
    //       I have no idea why this works, might be a race condition in the observables somewhere
    this.projectGalleryService.currentProjectItemImages$.pipe(take(1)).subscribe((it) => {})

    this.route.paramMap.pipe(take(1)).subscribe((paramMap: any) => {
      this.currentProjectUid = paramMap.params.projectId
      this.projectGalleryService.setCurrentProjectUid(paramMap.params.projectId)
    })
    this.setupSubscriptions()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  async setupSubscriptions() {
    this.subscriptions = [
      this.sideNavService.isHandset$.subscribe((isHandset) => {
        this.isHandset = isHandset
      }),
      this.projectGalleryService.currentProjectItemImages$.subscribe((images) => {
        this.allImagesArray = images
        this.imageCount = this.allImagesArray.length
      }),
    ]
  }

  showFullScreenGallery(index: number) {
    this.showFullScreen = !this.showFullScreen
    this.activeIndexFullScreenImage = index
    if (this.isHandset) {
      return
    }
    this.sideNavService.close()
  }

  // HostListener listens for any key pressed down.
  // Usual practice is to listen for 'keypressed' but that only listens for typed characters.
  // Arrows are not characters, so 'keydown' is needed
  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case "ArrowLeft":
        if (this.activeIndexFullScreenImage > 0) {
          this.activeIndexFullScreenImage--
        }
        break
      case "ArrowRight":
        if (this.activeIndexFullScreenImage < this.imageCount - 1) {
          this.activeIndexFullScreenImage++
        }
        break
      default:
        break
    }
  }

  _isAllSelected: boolean = false
  get isAllSelected() {
    return this._isAllSelected
  }

  set isAllSelected(selected: boolean) {
    this._isAllSelected = selected
  }

  selectAllImages(images: Image[]) {
    // this.selectedImages =  this.selectedImages.length ? [] : [...images]

    if (this._isAllSelected) {
      this.selectedImages = []
      this.isAllSelected = false
    } else {
      this.selectedImages = [...images]
      this.isAllSelected = true
    }
  }

  public async launchImageUploader() {
    if (this.currentProjectUid == null) return

    // @ts-ignore
    let dialog: MatDialogRef<SpinnerDialogComponent, any> = null
    try {
      const uploads = await this.filestackService.pickImages()
      dialog = this.dialogService.showSpinner("Uploading images to project", { disableClose: true })
      const images = await Promise.all(uploads.filesUploaded.map((upload) => this.filestackService.createImageDataFromUploadData(upload)))
      const projectImages = images.map((image) => ({ ...image, aggregateData: { projectUid: this.currentProjectUid }, disabled: false }))
      const batch = this.imageService.batchAddImages(projectImages)
      await batch.commit()
      dialog.close()
      this.snackBar.showMessage(`${projectImages.length} image(s) uploaded to project!`)
    } catch (e) {
      if (dialog) dialog.close()
      this.snackBar.showMessage("Error uploading images! ", e)
    }
  }

  public async onItemNameClicked(image?: Image) {
    const itemUid = image?.aggregateData?.itemUid

    if (!itemUid) {
      return this.snackBar.showMessage("Could not find image UID")
    }

    const item = this.projectItems.find((it) => it.uid === itemUid)

    if (!item) {
      return this.snackBar.showMessage("Could not find the item for this image")
    }

    await this.itemDialogService.openItem(item, this.project, this.projectMembers, this.userProjectRole)
  }

  public async downloadSelectedImages() {
    if (this.selectedImages.length === 0) {
      return
    }

    const imageUrlArray = this.selectedImages.map((image) => image.url)

    // TODO: Make this cloud function not suck, it reaches max http response limit
    // Until we fix aforementioned to-do, keep the CF turned off
    // const returnedValue = await this.projectGalleryService
    //   .cloudFunctionService
    //   .zipImages(imageUrlArray)
    //   .toPromise()

    const key = environment.FILESTACK_KEY
    const url = `https://cdn.filestackcontent.com/${key}/zip/[${imageUrlArray}]`

    // get URL and return as a blob
    this.projectGalleryService.download(url).subscribe((blob) => {
      const a = document.createElement("a")
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = "imageCollection.zip"
      a.click()
      URL.revokeObjectURL(objectUrl)
    })
  }

  public clearDateFilter() {
    // @ts-ignore
    this.projectGalleryService.setDateFilter(null, null)
  }

  public filterImagesByDate() {
    const [startDate, endDate] = this.filterDate

    if (startDate) {
      this.projectGalleryService.setDateFilter(startDate, endDate)
    }
  }
}
