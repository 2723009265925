import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { Template } from "../../models/template"
import { PrimaryButtonDirective } from "../../../next-ui/button/primary-button.directive"
import { FormsModule } from "@angular/forms"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"

export interface SaveTemplateDialogResult {
  name: string
  description: string
}

@Component({
  selector: "checkd-save-template-dialog",
  templateUrl: "./save-template-dialog.component.html",
  styleUrls: ["./save-template-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule, MatLegacyFormFieldModule, MatLegacyInputModule, FormsModule, PrimaryButtonDirective],
})
export class SaveTemplateDialogComponent implements OnInit {
  name: string = ""
  description: string = ""

  constructor(public dialogRef: MatDialogRef<SaveTemplateDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { template: Template }) {}

  ngOnInit() {
    if (this.data && this.data.template) {
      this.name = this.data.template.name
      this.description = this.data.template.description
    }
  }

  getResult(): SaveTemplateDialogResult {
    return { name: this.name.trim(), description: this.description.trim() }
  }

  cancel() {
    this.dialogRef.close()
  }

  save() {
    this.dialogRef.close(this.getResult())
  }
}
