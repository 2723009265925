import { Component, forwardRef } from "@angular/core"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { FormElementComponent } from "../form-element/form-element.component"
import { NgFor } from "@angular/common"

@Component({
  selector: "app-field-report-element",
  templateUrl: "./field-report-element.component.html",
  styleUrls: ["./field-report-element.component.scss"],
  standalone: true,
  imports: [NgFor, forwardRef(() => FormElementComponent)],
})
export class FieldReportElementComponent extends DefaultElementComponent {}
