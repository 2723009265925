import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { FormsModule, ReactiveFormsModule } from "@angular/forms"

import { FlexLayoutModule } from "@angular/flex-layout"
import { OwlMomentDateTimeModule } from "@danielmoncada/angular-datetime-picker-moment-adapter"

import { CheckdButtonComponent } from "./checkd-button/checkd-button.component"
import { CheckdExpansionPanelComponent } from "./checkd-expansion-panel/checkd-expansion-panel.component"
import { CheckdDrawingComponent } from "./checkd-drawing/checkd-drawing.component"
import { CheckdStatusFilterComponent } from "./checkd-status-filter/checkd-status-filter.component"

import { CheckdStatusIconComponent } from "./checkd-status-icon/checkd-status-icon.component"
import { CheckdStatusButtonComponent } from "./checkd-status-button/checkd-status-button.component"

import { PersonToCompanyNamePipe } from "./pipes/person-to-company-name.pipe"

import { DisabledOverlayService } from "./services"

import { LeafletCheckdDrawingDirective } from "./directives/leaflet-checkd-drawing.directive"
import { LeafletCheckdItemDirective } from "./directives/leaflet-checkd-item.directive"

import { LeafletModule } from "@asymmetrik/ngx-leaflet"
import { FilestackUploaderComponent } from "../../dashboard/filestack-uploader/filestack-uploader.component"

import { CheckdTagComponent } from "./checkd-tag/checkd-tag.component"
import { CheckdAcSearchComponent } from "./checkd-ac-search/checkd-ac-search.component"
import { CheckdMultiselectComponent } from "./checkd-multiselect/checkd-multiselect.component"
import { CheckdDaterangeComponent } from "./checkd-daterange/checkd-daterange.component"
import { CheckdTagsComponent } from "./checkd-tags/checkd-tags.component"

import { SidenavComponent, TopbarComponent } from "./../../dashboard/navigation"
import { EmptyStateComponent } from "./components/empty-state/empty-state.component"

import {
  PeopleListComponent,
  PeopleListItemComponent,
  EmailInvitationComponent,
  ProfileViewComponent,
  ProfilePersonalComponent,
  ProfileSettingsComponent,
  ProfileImageComponent,
  PeopleRoleSelectorComponent,
  PeoplePositionItemComponent,
} from "@people"

import { SettingsGroupComponent } from "./../../dashboard/settings-group/settings-group.component"

import { AvatarModule } from "ngx-avatars"

import { CheckdRoutingModule } from "./checkd-router.module"
import { PlaceholderComponent } from "./../../dashboard/placeholder/placeholder.component"

import { NgMultiSelectDropDownModule } from "./ng-multiselect-dropdown"

import { CalendarModule } from "primeng/calendar"

import { GalleryModule } from "@ks89/angular-modal-gallery"
import { CheckdAutocompleteEmailsComponent } from "./checkd-autocomplete-emails/checkd-autocomplete-emails.component"
import { CheckdDisabledOverlayComponent } from "./checkd-disabled-overlay/checkd-disabled-overlay.component"
import { CheckdAgreementsComponent } from "./checkd-agreements/checkd-agreements.component"
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from "@danielmoncada/angular-datetime-picker"

const PRIME_NG_MODULES = [CalendarModule]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    LeafletModule,
    CheckdRoutingModule,
    AvatarModule,
    NgMultiSelectDropDownModule.forRoot(),
    GalleryModule,
    OwlMomentDateTimeModule,
    OwlDateTimeModule,
    ...PRIME_NG_MODULES,
    CheckdButtonComponent,
    CheckdExpansionPanelComponent,
    CheckdDrawingComponent,
    CheckdStatusFilterComponent,
    CheckdStatusIconComponent,
    CheckdStatusButtonComponent,
    LeafletCheckdDrawingDirective,
    LeafletCheckdItemDirective,
    FilestackUploaderComponent,
    CheckdTagComponent,
    CheckdAcSearchComponent,
    CheckdMultiselectComponent,
    CheckdDaterangeComponent,
    CheckdTagsComponent,
    SidenavComponent,
    TopbarComponent,
    PeopleListComponent,
    PeopleListItemComponent,
    EmailInvitationComponent,
    ProfileViewComponent,
    ProfilePersonalComponent,
    ProfileSettingsComponent,
    ProfileImageComponent,
    SettingsGroupComponent,
    PeopleRoleSelectorComponent,
    PlaceholderComponent,
    PeoplePositionItemComponent,
    CheckdAutocompleteEmailsComponent,
    CheckdDisabledOverlayComponent,
    CheckdAgreementsComponent,
    PersonToCompanyNamePipe,
    EmptyStateComponent,
  ],
  exports: [
    CommonModule,
    CheckdButtonComponent,
    CheckdExpansionPanelComponent,
    CheckdDrawingComponent,
    CheckdStatusFilterComponent,
    CheckdStatusIconComponent,
    CheckdStatusButtonComponent,
    FormsModule,
    LeafletCheckdDrawingDirective,
    LeafletCheckdItemDirective,
    FilestackUploaderComponent,
    CheckdTagComponent,
    CheckdAcSearchComponent,
    CheckdMultiselectComponent,
    CheckdDaterangeComponent,
    CheckdTagsComponent,
    SidenavComponent,
    TopbarComponent,
    PeopleListComponent,
    PeopleListItemComponent,
    EmailInvitationComponent,
    ProfileViewComponent,
    ProfilePersonalComponent,
    ProfileSettingsComponent,
    ProfileImageComponent,
    SettingsGroupComponent,
    PeopleRoleSelectorComponent,
    PlaceholderComponent,
    PeoplePositionItemComponent,
    CheckdAutocompleteEmailsComponent,
    CheckdDisabledOverlayComponent,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    FlexLayoutModule,
    PersonToCompanyNamePipe,
    EmptyStateComponent,
    ...PRIME_NG_MODULES,
  ],
  providers: [
    DisabledOverlayService,
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: {
        parseInput: "l LT",
        fullPickerInput: "DD/MM/YYYY HH:mm",
        datePickerInput: "DD/MM/YYYY",
        timePickerInput: "HH:mm",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
      },
    },
  ],
})
export class CheckdCommonModule {}
