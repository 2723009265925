import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Company, LegacyTemplate } from "@models/common"
import { TemplateSharingOption } from "@models/common/legacy-template.interface"
import { UserService } from "@services"
import { Observable, startWith } from "rxjs"
import { map } from "rxjs/operators"
import { PrimaryButtonDirective } from "../../../../next-ui/button/primary-button.directive"
import { SharedModule } from "primeng/api"
import { RadioButtonModule } from "primeng/radiobutton"
import { TagSelectionComponent } from "../components/tag-selection/tag-selection.component"
import { InputTextareaModule } from "primeng/inputtextarea"
import { InputTextModule } from "primeng/inputtext"
import { NgIf, AsyncPipe } from "@angular/common"
import { DialogModule } from "primeng/dialog"

export interface IEditFormsTemplateDialogSaveData {
  reportName: string
  description: string
  sharedWith: TemplateSharingOption
  changelogText: string
  publicVersion: string
  tags: string[]
}

@Component({
  selector: "checkd-edit-forms-template-dialog",
  templateUrl: "./edit-forms-template-dialog.component.html",
  styleUrls: ["./edit-forms-template-dialog.component.scss"],
  standalone: true,
  imports: [
    DialogModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    InputTextareaModule,
    TagSelectionComponent,
    RadioButtonModule,
    SharedModule,
    PrimaryButtonDirective,
    AsyncPipe,
  ],
})
export class EditFormsTemplateDialogComponent implements OnInit {
  _open = false

  // Whether the dialog is open
  @Input()
  set open(newValue: boolean) {
    if (newValue) {
      this.initDialogForm()
    }

    this._open = newValue
  }
  get open(): boolean {
    return this._open
  }

  // Removes requirement that form cannot be saved if it is pristine
  @Input() disabledPristineFormCheck = false

  @Output() openChange = new EventEmitter<boolean>()
  @Output() onSave = new EventEmitter<IEditFormsTemplateDialogSaveData>()

  @Input() currentCompany?: Company
  @Input() template?: LegacyTemplate

  dialogForm: UntypedFormGroup | null = null
  readonly TemplateSharingOption = TemplateSharingOption

  constructor(private fb: UntypedFormBuilder, private userService: UserService) {}

  readonly currentUserCompanyName$: Observable<string> = this.userService.currentCompany$.pipe(
    map((company) => company.name),
    startWith("Your company")
  )

  ngOnInit(): void {}

  private initDialogForm() {
    if (!this.template) {
      throw new Error("Template was undefined")
    }

    if (!this.currentCompany) {
      throw new Error("Current company was undefined")
    }

    this.dialogForm = this.fb.group({
      title: [this.template.name, Validators.required],
      description: [this.template.description],
      changelog: "",
      sharedWith: [this.template.sharedWith, { disabled: !this.currentCompany.isTemplatePublisher }],
      publicVersion: this.template.publicVersion ?? "",
      tags: [this.template.tags],
    })
  }

  saveButtonDisabled(): boolean {
    if (this.dialogForm?.invalid) {
      return true
    }

    return !!(!this.disabledPristineFormCheck && this.dialogForm?.pristine)
  }

  closeDialog() {
    this.open = false
    this.openChange.emit(false)
  }

  editFormDialogCancelButtonClicked() {
    if (!this.open) {
      return
    }

    this.closeDialog()
  }

  saveAndPublishFormDialogButtonClicked(form: UntypedFormGroup) {
    const returnData: IEditFormsTemplateDialogSaveData = {
      reportName: form.value.title,
      description: form.value.description,
      sharedWith: form.value.sharedWith,
      publicVersion: form.value.publicVersion,
      changelogText: form.value.changelog,
      tags: form.value.tags,
    }
    this.closeDialog()
    this.onSave.emit(returnData)
  }
}
