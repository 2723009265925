import { Component, forwardRef } from "@angular/core"
import { DefaultElementComponent } from "../../default-element/default-element.component"
import { FormElementComponent } from "../../form-element/form-element.component"
import { NgFor } from "@angular/common"

@Component({
  selector: "app-subfield-element",
  templateUrl: "./subfield-element.component.html",
  styleUrls: ["./subfield-element.component.scss"],
  standalone: true,
  imports: [NgFor, forwardRef(() => FormElementComponent)],
})
export class SubfieldElementComponent extends DefaultElementComponent {}
