import { map, share, shareReplay, switchMap } from "rxjs/operators"
import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { combineLatest, Observable, of as observableOf, ReplaySubject } from "rxjs"

import { COLLECTIONS, Drawing, DrawingData, DrawingMenuActions, Item, LABELS, Person, Project, Relation, Role } from "@models/common"
// Importing directly (ignoring barrel) to prevent runtime cyclic dependencies
import { RelationService } from "./relation.service"
import { ItemService } from "./item.service"
import { ModelService } from "./model.service"
import { CheckdColors } from "@checkd-colors"
import { createDrawingMenuActionData } from "@models/common/actions/drawing-menu-actions"

@Injectable({
  providedIn: "root",
})
export class DrawingService {
  private _currentDrawingUid$ = new ReplaySubject<string>(1)
  public currentDrawing$: Observable<Drawing>
  public currentDrawingItems$: Observable<Item[]>

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private modelService: ModelService,
    private relationService: RelationService,
    private itemService: ItemService
  ) {
    this.setupListeners()
  }

  public setCurrentDrawing(drawingUid: string) {
    this._currentDrawingUid$.next(drawingUid)
  }

  private setupListeners() {
    this.currentDrawing$ = this._currentDrawingUid$.pipe(
      switchMap((drawingUid) => this.listenToUid(drawingUid)),
      shareReplay({ bufferSize: 1, refCount: true })
    )
    this.currentDrawingItems$ = this.currentDrawing$.pipe(
      switchMap((drawing) => this.listenToItems(drawing)),
      shareReplay({ bufferSize: 1, refCount: true })
    )
  }

  listenToUid(drawingUid: string): Observable<Drawing> {
    return this.modelService.listenTo(COLLECTIONS.DRAWINGS, drawingUid) as Observable<Drawing>
  }

  listenToItems(drawing: Drawing): Observable<Item[]> {
    return this.relationService
      .listenToTargets(drawing, COLLECTIONS.ITEMS)
      .pipe(map((items: Item[]) => this.orderItemsByUpdatedAt(items))) as Observable<Item[]>
  }

  orderItemsByUpdatedAt(items: Item[]) {
    return items.sort((a: Item, b: Item): number => {
      return (b.updatedAt || 0) - (a.updatedAt || 0)
    })
  }

  setDrawingCreator(drawing: Drawing, creator: Person) {
    return Promise.all([drawing.add(creator, [LABELS.CREATED_BY]), creator.add(drawing, [LABELS.CREATOR])]).then((_) => drawing)
  }

  createDrawing(drawingData: DrawingData): Promise<Drawing> {
    return Drawing.create(drawingData)
  }

  async createDrawingInProject(drawingData: DrawingData, project: Project, creator: Person): Promise<Drawing> {
    const batch = this.db.firestore.batch()
    const drawing = Drawing.batchCreate(batch, Drawing, drawingData)
    drawing.batchAdd(batch, project, [Relation.LABELS.CONTAINED_BY])
    // @ts-ignore
    project.batchAdd(batch, drawing, Relation.invertLabels([Relation.LABELS.CONTAINED_BY]))
    drawing.batchAdd(batch, creator, [LABELS.CREATED_BY])
    creator.batchAdd(batch, drawing, [LABELS.CREATOR])
    drawing.batchUpdateAggregateData(batch, {
      projectUid: project.uid || null,
      projectName: project.name || null,
    })
    await batch.commit()

    return drawing
  }

  async disableDrawing(drawing: Drawing) {
    const items = await drawing.getItems()
    const tasks = await Promise.all(items.map((item) => item.getTask()))

    return Promise.all([
      drawing.disable(),
      items.map((item) => item.disable()),
      tasks.filter((task) => task != null).map((task) => task.disable()),
    ])
  }

  getMenuOptions(drawing: Drawing, userProjectRole: Role) {
    const menuOptions = []

    // Due to the async behavior of userProjectRole it sometimes is null
    // This is a workaround until we refactor some of the listener usages.
    if (userProjectRole == null) {
      return []
    }

    if (!drawing.archived) {
      if (userProjectRole.canUpdateTargetDocuments(COLLECTIONS.DRAWINGS)) {
        menuOptions.push(createDrawingMenuActionData(drawing, DrawingMenuActions.RENAME_DRAWING))
      }

      if (
        userProjectRole.canDeleteTargetDocuments(COLLECTIONS.DRAWINGS) &&
        userProjectRole.canDeleteTargetRelations(COLLECTIONS.DRAWINGS)
      ) {
        menuOptions.push(createDrawingMenuActionData(drawing, DrawingMenuActions.REMOVE_DRAWING))
      }
    }

    return menuOptions
  }
}
