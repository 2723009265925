export const COLLECTIONS = {
  ADMINS: "admins",
  COMPANIES: "companies",
  DRAWINGS: "drawings",
  FOO: "foo",
  IMAGES: "images",
  INVITATIONS: "invitations",
  ITEMS: "items",
  NOTIFICATIONS: "notifications",
  PENDING_USERS: "pendingUsers",
  PROJECTS: "projects",
  REPORTS: "reports",
  TASKS: "tasks",
  USERS: "users",
  PEOPLE: "users",
  TIMELINE: "timeline",
  LEGACY_TEMPLATES: "legacyTemplates",
  LEGACY_REPORTS: "legacyReports",
  FIELD_REPORTS: "reports",
  HISTORY: "history",
  AGREEMENTS: "agreements",
  CHECKD_CONFIG: "checkdConfig",
  OPEN_REPORTS: "openReports",
  ALERTS: "alerts",
  FORMS_LIBRARY_MEMBERSHIPS: "formsLibraryMemberShips",
  FORMS_LIBRARY_SUBSCRIBERS: "formsLibrarySubscribers",
  FORMS_LIBRARY_CONFIG: "formsLibraryConfig",
  CHANGELOG: "changelog",
  SESSIONS: "sessions",
}

export const enum AlertTypes {
  Forms = "forms",
}

export const RELATION_COLLECTIONS = [
  COLLECTIONS.COMPANIES,
  COLLECTIONS.DRAWINGS,
  COLLECTIONS.IMAGES,
  COLLECTIONS.INVITATIONS,
  COLLECTIONS.ITEMS,
  COLLECTIONS.PENDING_USERS,
  COLLECTIONS.PROJECTS,
  COLLECTIONS.REPORTS,
  COLLECTIONS.TASKS,
  COLLECTIONS.USERS,
  COLLECTIONS.PEOPLE,
  COLLECTIONS.LEGACY_TEMPLATES,
  COLLECTIONS.LEGACY_REPORTS,
  COLLECTIONS.FIELD_REPORTS,
  COLLECTIONS.FORMS_LIBRARY_MEMBERSHIPS,
  COLLECTIONS.FORMS_LIBRARY_SUBSCRIBERS,
]

export const SUB_COLLECTIONS = {
  TAG_COLLECTIONS: "tagCollections",
}

export const USER_SUB_COLLECTIONS = {
  PRIVATE: "private",
}
