import { Component, OnInit } from "@angular/core"
import { CardModule } from "primeng/card"

@Component({
  selector: "checkd-subscriptor-card-component",
  templateUrl: "./subscriptor-card.component.html",
  styleUrls: ["./subscriptor-card.component.scss"],
  standalone: true,
  imports: [CardModule],
})
export class SubscriptorCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
