import { Component } from "@angular/core"
import { Router } from "@angular/router"
import { SnackbarService, UserService } from "@services"
import { PrimaryButtonDirective } from "../next-ui/button/primary-button.directive"
import { MatIconModule } from "@angular/material/icon"
import { FormsModule } from "@angular/forms"

@Component({
  selector: "app-login-view",
  templateUrl: "./login-view.component.html",
  styleUrls: ["./login-view.component.scss"],
  standalone: true,
  imports: [FormsModule, MatIconModule, PrimaryButtonDirective],
})
export class LoginViewComponent {
  email: string = ""
  password: string = ""

  constructor(private userService: UserService, private router: Router, private snackBar: SnackbarService) {}

  login(username: string, password: string) {
    if (username && password) {
      const emailPattern = /^[^\s]+@[^\s]+\.[^\s]+$/i

      if (!emailPattern.test(username)) {
        return this.showErrorMessage("Please enter a valid email address.")
      }

      this.userService
        .login(username, password)
        .then((_) => {
          this.router.navigate(["projects"])
        })
        .catch((err) => {
          switch (err.code) {
            case "auth/user-not-found": // User not created on firebase auth
              return this.showErrorMessage("User not found, Please register or contact support")
            case "auth/wrong-password":
              return this.showErrorMessage("Wrong username or password")
            default:
              return this.showErrorMessage("There was an error trying to log you in. Please contact support.")
          }
        })
    }
  }

  public async loginWithMicrosoft() {
    try {
      const authResult = await this.userService.loginWithMicrosoft()
      const userDocSnap = await this.userService.fetchUserDocSnap(authResult.user!.uid)
      if (userDocSnap.exists) {
        await this.router.navigate(["projects"])
      } else {
        this.showErrorMessage("Access Denied: This is a beta feature enabled for selected customers only.")
        await this.userService.logout()
      }
    } catch (e) {
      this.showErrorMessage("Access Denied: This is a beta feature enabled for selected customers only.")
    }
  }

  showErrorMessage(errorMessage?: string) {
    this.snackBar.showMessage(errorMessage == null ? SnackbarService.LOGIN_ERROR : errorMessage)
  }

  onForgotPassword() {
    this.router.navigateByUrl("/passwordrecovery")
  }

  get loginFieldsContainEmptyInputs(): boolean {
    return this.email.trim() === "" || this.password.trim() === ""
  }
}
