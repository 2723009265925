import { Component, OnInit, Input } from "@angular/core"
import { DefaultElementComponent } from "../../default-element/default-element.component"
import { MatLegacyOptionModule } from "@angular/material/legacy-core"

@Component({
  selector: "app-dropdown-item-element",
  templateUrl: "./dropdown-item-element.component.html",
  styleUrls: ["./dropdown-item-element.component.scss"],
  standalone: true,
  imports: [MatLegacyOptionModule],
})
export class DropdownItemElementComponent extends DefaultElementComponent {
  test: string = "TEST"

  override ngOnInit() {}

  override get value() {
    return this.element.name
  }
}
