import { Component, OnInit } from "@angular/core"

import { take } from "rxjs/operators"
import { ImageElement, SignatureElement } from "../../../models/form-element"
import { FormMessageService } from "../../../services/form-message.service"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { SignatureDialogComponent } from "./signature-dialog/signature-dialog.component"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { NgClass, NgIf } from "@angular/common"
import { MatIconModule } from "@angular/material/icon"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "app-signature-element",
  templateUrl: "./signature-element.component.html",
  styleUrls: ["./signature-element.component.scss"],
  standalone: true,
  imports: [FlexModule, MatIconModule, NgClass, ExtendedModule, NgIf],
})
export class SignatureElementComponent extends DefaultElementComponent implements OnInit {
  signatureUrl: string = ""
  signatureImage: string

  override ngOnInit() {
    if (this.value && this.value.length > 0) {
      // check between blob storage or filestack
      this.signatureImage = ImageElement.processUrl(this.value)
      this.signatureUrl = this.signatureImage
    } else {
      // empty signature
      this.signatureImage = ""
    }
  }

  openSignaturePad() {
    // This prevents changing the signature when its added!
    // Removed for now
    // if (this.signatureUrl){
    //   return;
    // }
    if (this.signatureUrl && this.readOnly) {
      return
    }

    const dialog = this.dialogRef.open(SignatureDialogComponent)

    dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((signatureData) => {
        if (signatureData) {
          if (!signatureData.isEmpty) {
            this.filestackService.uploadSignature(signatureData.signatureImage).then((metaData: { url: string }) => {
              this.signatureUrl = metaData.url
              this.signatureImage = signatureData.signatureImage
              this.element.value = metaData.url
              const element = this.element as SignatureElement
              element.imageBlob = signatureData.signatureImage
              this.formMessageService.sendMessage(FormMessageService.SIGNATURE_ADDED, this)
            })
          }
        }
      })
  }
}
