import { Pipe, PipeTransform } from "@angular/core"
import { Person, Project } from "@models/common"

export type SortOption = "favorite" | "name" | "people" | "drawings" | "items" | "activeItems"

@Pipe({
  name: "orderBy",
  standalone: true,
})
export class OrderbyPipe implements PipeTransform {
  transform(value: Project[], currentUser: Person, favorites: String[], sortBy?: SortOption): Project[] {
    if (!sortBy) {
      return value
    }

    switch (sortBy) {
      case "favorite":
        return [...value].sort((a, b) => {
          const aIsFavorite = favorites.includes(a.uid)
          const bIsFavorite = favorites.includes(b.uid)

          const aName = (a.name || "").toLowerCase()
          const bName = (b.name || "").toLowerCase()

          if (aIsFavorite && bIsFavorite) {
            if (aName > bName) {
              return 1
            }
            if (aName < bName) {
              return -1
            }
          } else if (aIsFavorite && !bIsFavorite) {
            return -1
          } else if (!aIsFavorite && bIsFavorite) {
            return 1
          }

          if (aName > bName) {
            return 1
          }
          if (aName < bName) {
            return -1
          }

          return 0
        })
      case "name":
        return [...value].sort((a, b) => {
          return (a.name || "").toLowerCase() > (b.name || "").toLowerCase() ? 1 : -1
        })
      case "people":
        return [...value].sort((a, b) => {
          const aCount = a.aggregateData.usersCount || 0
          const bCount = b.aggregateData.usersCount || 0

          if (aCount > bCount) {
            return -1
          }
          if (aCount < bCount) {
            return 1
          }

          const aName = (a.name || "").toLowerCase()
          const bName = (b.name || "").toLowerCase()

          if (aName > bName) {
            return 1
          }
          if (aName < bName) {
            return -1
          }

          return 0
        })
      case "drawings":
        return [...value].sort((a, b) => {
          const aCount = a.aggregateData.drawingsCount || 0
          const bCount = b.aggregateData.drawingsCount || 0

          if (aCount > bCount) {
            return -1
          }
          if (aCount < bCount) {
            return 1
          }

          const aName = (a.name || "").toLowerCase()
          const bName = (b.name || "").toLowerCase()

          if (aName > bName) {
            return 1
          }
          if (aName < bName) {
            return -1
          }

          return 0
        })
      case "items":
        return [...value].sort((a, b) => {
          const aCount = a.aggregateData.itemsCount || 0
          const bCount = b.aggregateData.itemsCount || 0

          if (aCount > bCount) {
            return -1
          }
          if (aCount < bCount) {
            return 1
          }

          const aName = (a.name || "").toLowerCase()
          const bName = (b.name || "").toLowerCase()

          if (aName > bName) {
            return 1
          }
          if (aName < bName) {
            return -1
          }

          return 0
        })
      case "activeItems":
        const currentUserUid = (currentUser && currentUser.uid) || ""

        return [...value].sort((a, b) => {
          const aActive =
            a.aggregateData.totalAndActiveItemsPerUser &&
            a.aggregateData.totalAndActiveItemsPerUser[currentUserUid] &&
            a.aggregateData.totalAndActiveItemsPerUser[currentUserUid].active
          const bActive =
            b.aggregateData.totalAndActiveItemsPerUser &&
            b.aggregateData.totalAndActiveItemsPerUser[currentUserUid] &&
            b.aggregateData.totalAndActiveItemsPerUser[currentUserUid].active

          if (!isNaN(aActive) && !isNaN(bActive)) {
            if (aActive > bActive) {
              return -1
            }
            if (aActive < bActive) {
              return 1
            }

            return 0
          } else if (!isNaN(aActive) && isNaN(bActive)) {
            return -1
          } else if (isNaN(aActive) && !isNaN(bActive)) {
            return 1
          }

          const aName = (a.name || "").toLowerCase()
          const bName = (b.name || "").toLowerCase()

          if (aName > bName) {
            return 1
          }
          if (aName < bName) {
            return -1
          }

          return 0
        })
      default:
        const _: never = sortBy
    }

    return value
  }
}
