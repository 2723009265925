import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"

import { Person } from "@models/common"
import { PrimaryButtonDirective } from "../../next-ui/button/primary-button.directive"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "profile-image",
  templateUrl: "./profile-image.component.html",
  styleUrls: ["./profile-image.component.scss"],
  standalone: true,
  imports: [FlexModule, PrimaryButtonDirective],
})
export class ProfileImageComponent implements OnInit {
  @Input() person: Person
  @Output("uploadProfileImage") onUploadProfileImage = new EventEmitter()

  readonly defaultImageURL: string = "/assets/images/default_user.jpg"

  ngOnInit() {}

  get imageURL() {
    if (this.person && this.person.image) return this.person.image

    return this.defaultImageURL
  }

  uploadProfileImage() {
    this.onUploadProfileImage.emit()
  }
}
