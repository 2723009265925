import { Component, Inject, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { GeneralReport } from "@models/common/general-report"
import { DEFAULT_PDF_GENERATION_SETTINGS } from "@models/common/pdf/settings.interface"
import { ProjectService } from "@services"
import { Subscription } from "rxjs"
import { map } from "rxjs/operators"
import { GeneralReportViewService } from "../../reports/general-report-view/general-report-view.service"
import { RadioButtonModule } from "primeng/radiobutton"
import { FormsModule } from "@angular/forms"
import { InputSwitchModule } from "primeng/inputswitch"
import { ButtonModule } from "primeng/button"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyProgressSpinnerModule } from "@angular/material/legacy-progress-spinner"
import { NgSwitch, NgSwitchCase, NgTemplateOutlet, NgStyle, NgIf } from "@angular/common"
import { OverlayPanelModule } from "primeng/overlaypanel"

interface IGeneralReportData {
  report: GeneralReport
}

@Component({
  selector: "checkd-generate-pdf-settings-dialog",
  templateUrl: "./generate-pdf-settings-dialog.component.html",
  styleUrls: ["./generate-pdf-settings-dialog.component.scss"],
  standalone: true,
  imports: [
    OverlayPanelModule,
    NgSwitch,
    NgSwitchCase,
    NgTemplateOutlet,
    MatLegacyProgressSpinnerModule,
    MatIconModule,
    NgStyle,
    ExtendedModule,
    ButtonModule,
    InputSwitchModule,
    FormsModule,
    NgIf,
    RadioButtonModule,
  ],
})
export class GeneratePdfSettingsDialogComponent implements OnInit {
  size: "small" | "medium" | "large" = "small"

  pdfGenerationState: "initial" | "generatingPdf" | "success" | "error" = "initial"

  readonly iconSize = "100px"
  downloadUrl = ""

  constructor(
    private dialogRef: MatDialogRef<GeneratePdfSettingsDialogComponent>,
    public generalReportViewService: GeneralReportViewService,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) public data: IGeneralReportData,
  ) {}
  pdfGenerationSettings = DEFAULT_PDF_GENERATION_SETTINGS
  subscriptions: Subscription[] = []
  canUploadToNextProject = false

  ngOnInit() {
    this.getCurrentProject()
  }

  async getCurrentProject() {
    await this.projectService
      .listenToUid(this.data.report.aggregateData["projectUid"])
      .pipe(
        map((project) => {
          if (project.nextProjectId) {
            this.canUploadToNextProject = true
          }
        }),
      )
      .toPromise()
  }

  async generatePdfClicked() {
    this.downloadUrl = ""
    this.pdfGenerationState = "generatingPdf"
    this.dialogRef.disableClose = true
    this.pdfGenerationSettings.timezone = Intl?.DateTimeFormat().resolvedOptions().timeZone ?? null

    try {
      const result: any = await this.generalReportViewService.fetchReportPdf(this.data.report, this.pdfGenerationSettings)

      if (result.downloadUrl) {
        this.downloadUrl = result.downloadUrl
        this.pdfGenerationState = "success"
      } else {
        this.pdfGenerationState = "error"
        console.error(`PDF generation returned a result, but result.downloadUrl was missing`)
        console.error(result)
      }
    } catch (err) {
      this.pdfGenerationState = "error"
      console.log(err)
    } finally {
      this.dialogRef.disableClose = false
    }
  }

  openPdfClicked(url: string) {
    window.open(url, "_blank")
  }
}
