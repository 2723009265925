import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import {
  CompanyFeatures,
  Drawing,
  DrawingData,
  DrawingMenuActions,
  FilestackUploadResult,
  IMenuOption,
  Person,
  Project,
  Role,
} from "@models/common"
import { FilestackUploadData } from "@models/common/filestack"
import { DrawingService, FilestackService, ProjectService, SnackbarService, UserService } from "@services"
import { Observable, Subscription } from "rxjs"
import { DialogService } from "../../dialogs/dialog.service"
import { switchMap } from "rxjs/operators"
import { FeatureCheckerService } from "../../features/feature-checker/services/feature-checker.service"
import { PrimaryButtonDirective } from "../../next-ui/button/primary-button.directive"
import { DrawingListComponent } from "../../drawings/components/drawing-list/drawing-list.component"
import { NgIf } from "@angular/common"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "checkd-project-drawings",
  templateUrl: "./project-drawings.component.html",
  styleUrls: ["./project-drawings.component.scss"],
  standalone: true,
  imports: [FlexModule, NgIf, DrawingListComponent, PrimaryButtonDirective],
})
export class ProjectDrawingsComponent implements OnInit, OnDestroy, OnChanges {
  public currentCompanyFeatures: string[] = []
  public CompanyFeatures = CompanyFeatures

  get hasCRUDRights() {
    return this.canAddDrawings || this.canDeleteDrawings
  }

  get canAddDrawings() {
    return (
      this.currentUserProjectRole &&
      this.currentUserProjectRole.canCreateTargetRelations(Drawing.COLLECTION) &&
      this.currentUserProjectRole.canCreateTargetDocuments(Drawing.COLLECTION)
    )
  }

  get canDeleteDrawings() {
    return (
      this.currentUserProjectRole &&
      this.currentUserProjectRole.canDeleteTargetRelations(Drawing.COLLECTION) &&
      this.currentUserProjectRole.canDeleteTargetDocuments(Drawing.COLLECTION)
    )
  }
  @Input() projectReady: boolean = false
  @Input() project: Project
  @Input() projectDrawings: Drawing[]
  @Input() currentUser: Person
  @Input() currentUserProjectRole: Role

  private subscriptions = new Subscription()

  constructor(
    private filestackService: FilestackService,
    private drawingService: DrawingService,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    public projectService: ProjectService,
    public featureCheckerService: FeatureCheckerService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.userService.currentCompanyFeatures$.subscribe((features) => {
        this.currentCompanyFeatures = features
      })
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["project"] && changes["projectReady"]) {
      const project: Project = changes["project"].currentValue
      const projectReady: boolean = changes["projectReady"].currentValue
      const feature = CompanyFeatures.DRAWINGS

      if (projectReady && !project.aggregateData.companyFeatures!.includes(feature)) {
        this.featureCheckerService.displayMissingFeatureOverlay(feature, `projects/${project.uid}`)
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  async addDrawing() {
    let fileStackResult: FilestackUploadResult

    try {
      fileStackResult = await this.filestackService.pick({ storeTo: { location: "gcs", path: "drawings/" } })
    } catch (e) {
      console.error(e)
      const errDialog = this.dialogService.showSpinner()
      errDialog.componentInstance.finishWithError({
        message: "Something went wrong...",
        disableClose: false,
      })

      return
    }

    const pluralizedDrawing = fileStackResult.filesUploaded.length > 1 ? "drawings" : "drawing"

    const dialog = this.dialogService.showSpinner(`Adding ${pluralizedDrawing}`, {
      disableClose: true,
      subTitle: "Please wait...",
    })

    let uploads
    try {
      uploads = await this.handleFilesUpload(fileStackResult)
    } catch (e) {
      console.error(`Error while creating drawings`)
      console.error(e)
      dialog.componentInstance.finishWithError({
        message: "Something went wrong...",
        disableClose: false,
      })

      return
    }

    dialog.componentInstance.finishWithSuccess({
      message: `${uploads.length} ${pluralizedDrawing} uploaded!`,
      disableClose: false,
    })
  }

  openDrawing(drawing: Drawing) {
    this.router.navigate([`../drawings/${drawing.uid}`], { relativeTo: this.route })
  }

  async onMenuOptionSelected(event: { drawing: Drawing; option: IMenuOption }) {
    const { drawing, option } = event
    switch (option.action) {
      case DrawingMenuActions.RENAME_DRAWING: {
        return this.renameDrawing(drawing)
      }
      case DrawingMenuActions.REMOVE_DRAWING: {
        return this.disableDrawing(drawing)
      }
    }
  }

  private handleFilesUpload(files: FilestackUploadResult) {
    return Promise.all(
      files.filesUploaded.map((file) => {
        switch (file.mimetype) {
          case "application/pdf":
            return this.handlePdfUpload(file)
          default:
            return this.addDrawingToProject(file)
        }
      })
    )
  }

  private async handlePdfUpload(file: FilestackUploadData): Promise<Drawing[]> {
    return this.filestackService.getPdfPages(file).then((pdfPageUrls) => {
      return Promise.all(
        pdfPageUrls.map((page) => {
          const newFile = { ...file, url: page.url }

          return this.addPdfDrawingToProject(newFile, page.width, page.height)
        })
      )
    })
  }

  private addDrawingToProject(file: FilestackUploadData): Promise<Drawing> {
    return this.filestackService.getExifRotatedImage(file.handle).then((result: any) => {
      const metadataRequest = { width: true, height: true }

      return this.filestackService.client.metadata(result.handle, metadataRequest).then((metadata: { width: number; height: number }) => {
        const drawingData: DrawingData = {
          name: result.filename,
          width: metadata.width,
          height: metadata.height,
          storage: {
            url: result.url,
            fileType: result.type,
          },
        }
        if (result.type.includes("image")) {
          drawingData.drawingType = "image"
        }
        if (result.type.includes("application/pdf")) {
          drawingData.drawingType = "pdf"
        }

        return this.drawingService.createDrawingInProject(drawingData, this.project, this.currentUser)
      })
    })
  }

  private addPdfDrawingToProject(file: FilestackUploadData, width: number, height: number): Promise<Drawing> {
    const drawingData: DrawingData = {
      name: file.filename,
      width,
      height,
      storage: {
        url: file.url,
        fileType: file.mimetype,
      },
    }
    if (file.mimetype.includes("image")) {
      drawingData.drawingType = "image"
    }
    if (file.mimetype.includes("application/pdf")) {
      drawingData.drawingType = "pdf"
    }

    return this.drawingService.createDrawingInProject(drawingData, this.project, this.currentUser)
    // .catch((error) => this.snackbarService.showMessage(`ERROR: ${error}`))
  }

  private async renameDrawing(drawing: Drawing) {
    const result = await this.dialogService.showTextfield({
      title: "Rename drawing",
      content: `Enter the new name for "${drawing.name}":`,
      value: drawing.name || "",
      okValue: "Rename",
    })

    if (result && result.value && result.value.trim && result.value.trim() !== "") {
      const name = result.value.trim()
      await drawing.update({ name })
      this.snackbarService.showMessage(`Successfully renamed drawing ${name}`)
    }
  }

  private disableDrawing(drawing: Drawing) {
    this.drawingService.disableDrawing(drawing)
  }
}
