import { Component, EventEmitter, Input, Output } from "@angular/core"
import { VisibilitySelection } from "@checkd-form/components/report-visibility-toggle/report-visibility-toggle.component"
import { Form } from "@checkd-form/models/form"
import { ReportType, SortingDirection, SortingType } from "@models/common"
import { GeneralReport } from "@models/common/general-report"
import { ReportVisibilityToggleComponent } from "../report-visibility-toggle/report-visibility-toggle.component"
import { ReportTopbarInfoComponent } from "../report-topbar-info/report-topbar-info.component"
import { FlexModule } from "@angular/flex-layout/flex"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { MatLegacyMenuModule } from "@angular/material/legacy-menu"
import { NgIf } from "@angular/common"
import { MatToolbarModule } from "@angular/material/toolbar"

@Component({
  selector: "checkd-report-header",
  templateUrl: "./report-header.component.html",
  styleUrls: ["./report-header.component.scss"],
  standalone: true,
  imports: [
    MatToolbarModule,
    NgIf,
    MatLegacyMenuModule,
    MatLegacyButtonModule,
    MatIconModule,
    FlexModule,
    ReportTopbarInfoComponent,
    ReportVisibilityToggleComponent,
  ],
})
export class ReportHeaderComponent {
  @Input() form: Form
  @Input() report: GeneralReport
  @Input() reportNumber: number
  @Input() reportName: string
  @Input() creatorName: string
  @Input() projectTitle: string
  @Input() createdAt: number
  @Input() lastTimelineElementMessage: string
  @Input() hasSortableItems: boolean = false
  @Input() editable: boolean = false
  @Input() canEditStatus: boolean = false
  @Input() showVisibilityToggle: boolean = true
  @Input() reportStatus: string = "unknown"
  @Input() reportType: ReportType = ReportType.NONE

  @Output() markReportAsClosed = new EventEmitter()
  @Output() editReportTitle = new EventEmitter()
  @Output() generateDocx = new EventEmitter()
  @Output() sortItemsBy = new EventEmitter<{ sortingType: SortingType; sortingDirection: SortingDirection }>()
  @Output() visibilityChange = new EventEmitter<VisibilitySelection>()

  // Used for gaining access to these enums in the template
  readonly sortingType = SortingType
  readonly sortingDirection = SortingDirection

  onSortItemsBy(sortingType: SortingType, sortingDirection: SortingDirection) {
    this.sortItemsBy.emit({ sortingType, sortingDirection })
  }
}
