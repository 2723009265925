import { Component, Input, OnInit } from "@angular/core"
import { Person } from "@models/common"
import { IFormsLibraryMemberCompanyEmailInvitation } from "@models/common/forms-library-member-company-invitation.interface"
import { CloudFunctionsService, SnackbarService, UserService } from "@services"
import { firstValueFrom } from "rxjs"
import { Router } from "@angular/router"
import { map } from "rxjs/operators"
import { ProgressSpinnerModule } from "primeng/progressspinner"
import { ButtonModule } from "primeng/button"
import { SharedModule } from "primeng/api"
import { CardModule } from "primeng/card"
import { NgIf, AsyncPipe } from "@angular/common"

@Component({
  selector: "checkd-forms-library-accept-invitation-with-signed-in-user",
  templateUrl: "./accept-invitation-with-signed-in-user.component.html",
  styleUrls: ["./accept-invitation-with-signed-in-user.component.scss"],
  standalone: true,
  imports: [NgIf, CardModule, SharedModule, ButtonModule, ProgressSpinnerModule, AsyncPipe],
})
export class AcceptInvitationWithSignedInUserComponent implements OnInit {
  @Input() invitation!: IFormsLibraryMemberCompanyEmailInvitation
  @Input() invitationUid!: string

  // Null if the targetUser could not be found in the system (by email).
  // This either means that the user is new or that they spelled the email wrong (or used the wrong email, perhaps)
  @Input() targetUser!: Person | null

  isInProgress = false

  constructor(
    public userService: UserService,
    private cfService: CloudFunctionsService,
    private snackbar: SnackbarService,
    private router: Router
  ) {}

  // If the user just signed up, wait for the company to be properly set up by DB trigger.
  // This prevents users from clicking accept too fast, which will just return an error
  readonly backendReady$ = this.userService.currentUser$.pipe(map((user) => user.aggregateData && user.aggregateData["companyUid"] != null))

  ngOnInit(): void {}

  userCanAcceptInvite(targetUser: Person | null, currentUser: Person): boolean {
    if (!targetUser) {
      return false
    }

    return targetUser.uid === currentUser.uid
  }

  async acceptInvitation() {
    this.isInProgress = true
    const err = await firstValueFrom(
      this.cfService.updateFormsLibraryCompanyMemberInvite({ invitationUid: this.invitationUid, action: "accept" })
    )

    if (err) {
      this.snackbar.showMessage(err)
    } else {
      await this.router.navigate(["/forms/company"])
    }
  }

  async declineInvitation() {
    this.isInProgress = true
    const err = await firstValueFrom(
      this.cfService.updateFormsLibraryCompanyMemberInvite({ invitationUid: this.invitationUid, action: "cancel" })
    )

    if (err) {
      this.snackbar.showMessage(err)
    }
  }
}
