import { Component, forwardRef } from "@angular/core"
import { FormElement } from "../../../models/form-element"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { FormElementComponent } from "../form-element/form-element.component"
import { NgFor, SlicePipe } from "@angular/common"
import { MatExpansionModule } from "@angular/material/expansion"

@Component({
  selector: "app-tabular-form-element",
  templateUrl: "./tabular-form-element.component.html",
  styleUrls: ["./tabular-form-element.component.scss"],
  standalone: true,
  imports: [MatExpansionModule, NgFor, forwardRef(() => FormElementComponent), MatLegacyButtonModule, MatIconModule, SlicePipe],
})
export class TabularFormElementComponent extends DefaultElementComponent {
  addTabularElement() {
    this.addChild(this.tabularElementDeepCopy(this.element.child![0]))
  }

  removeTabularElement(index: number) {
    this.removeChild(index)
  }

  private tabularElementDeepCopy(element: FormElement): FormElement {
    const keysToCopy = ["type", "values"]
    const formElementCopy = FormElement.fromJson(JSON.parse(JSON.stringify(element)))

    for (const key of Object.keys(formElementCopy)) {
      if (keysToCopy.includes(key)) {
        continue
      }

      // @ts-ignore
      delete formElementCopy[key]
    }

    return formElementCopy
  }
}
