import { Injectable, OnDestroy } from "@angular/core"
import { Title } from "@angular/platform-browser"
import { NavigationEnd, NavigationStart, Router } from "@angular/router"
import { BehaviorSubject, Observable, Subscription } from "rxjs"
import { distinctUntilChanged, filter, map, shareReplay } from "rxjs/operators"

const TITLES_AND_PATHS = [
  { title: "Items", paths: ["items"] },
  { title: "Forms Builder", paths: ["formsbuilder", "templatecreator"] },
  { title: "Profile", paths: ["profile"] },
  { title: "Page not Found", paths: ["404"] },
  { title: "Login", paths: ["login"] },
  { title: "Drawings", paths: ["Drawings"] },
  { title: "Reports", paths: ["fieldreports", "legacyreports", "reports"] },
  { title: "Projects", paths: ["", "projects"] },
  { title: "Invitations", paths: ["invitations"] },
  { title: "Company", paths: ["company"] },
  { title: "Registration", paths: ["registration", "register"] },
]

@Injectable()
export class NavigationService implements OnDestroy {
  private topBar = new BehaviorSubject<boolean>(false)
  private sideNav = new BehaviorSubject<boolean>(false)

  private subscriptions = new Subscription()

  readonly showTopBar$ = this.topBar.asObservable().pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }))

  readonly showSideNav$ = this.sideNav.asObservable().pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }))

  private splitUrlSegments(url: string): string[] {
    return url.split("/").filter((segment) => segment.length > 0)
  }

  public pathComponents$: Observable<string[]> = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map((event: NavigationEnd) => event.url),
    distinctUntilChanged(),
    map((url) => this.splitUrlSegments(url)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  constructor(private router: Router, private titleService: Title) {
    this.subscriptions.add(
      this.router.events
        .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
        .subscribe((e: NavigationEnd) => this.setWebPageTitle(e.urlAfterRedirects))
    )

    this.subscriptions.add(
      this.router.events
        .pipe(filter((e): e is NavigationStart => e instanceof NavigationStart))
        .subscribe((e: NavigationStart) => this.listenToRouteChanges(e))
    )
  }

  ngOnDestroy() {
    this.topBar.complete()
    this.sideNav.complete()
    this.subscriptions.unsubscribe()
  }

  public hideTopAndSideBar() {
    this.topBar.next(false)
    this.sideNav.next(false)
  }

  private listenToRouteChanges(routeChange: NavigationStart) {
    const { url } = routeChange

    this.hideNavElement(
      [
        "/404",
        "404",
        "/login",
        "/drawings/",
        "/reports/",
        "registration",
        "/invitations/",
        "password",
        "legacyReports",
        "fieldReports",
        "templateCreator",
        "formsBuilder",
        "/projects/new",
        "openTemplates",
        "openReports",
        "forms/builder",
        "/chat",
        "/auth",
      ],
      url,
      this.sideNav
    )
    this.hideNavElement(
      [
        "fieldReports",
        "/drawings/",
        "/legacyReports/",
        "/404",
        "404",
        "/projects/new",
        "/login",
        "openTemplates",
        "openReports",
        "/chat",
        "/auth",
      ],
      url,
      this.topBar
    )
  }

  private setWebPageTitle(inputURL: string) {
    const path = inputURL
      .toLocaleLowerCase()
      .replace(/^\/+|\/+$/, "") // remove leading and trailing '/'
      .split("/")

    const [firstPathItem] = path

    let title = "Next Field Dashboard"

    // special case
    if (path.length === 1 && firstPathItem === "items") {
      title = "My Items"
    } else if (firstPathItem) {
      title = (TITLES_AND_PATHS.find((x) => x.paths.includes(firstPathItem)) || { title }).title
    }

    this.titleService.setTitle(title)
  }

  /**
   * Hides the navigation element if the current URL path contains any of the target URLs
   * @param targetUrls
   * @param currentPath
   * @param navElement
   * @private
   */
  private hideNavElement(targetUrls: string[], currentPath: string, navElement: BehaviorSubject<boolean>) {
    const currentUrlContainsSomeTargetUrl = targetUrls.some((substring) => currentPath.includes(substring))
    navElement.next(!currentUrlContainsSomeTargetUrl)
  }

  goToOpenReportReceiptPage(openReportUid: string) {
    return this.router.navigate([`/openReports/${openReportUid}/receipt`])
  }
}
