import { Component, OnInit } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { COLLECTIONS } from "@models/common"
import { ILegacyTemplateChangelogData } from "@models/common/legacy-template-changelog.interface"
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { DividerModule } from "primeng/divider"
import { TagModule } from "primeng/tag"
import { NgIf, NgFor, AsyncPipe } from "@angular/common"

@Component({
  selector: "checkd-changelog-dialog",
  templateUrl: "./changelog-dialog.component.html",
  styleUrls: ["./changelog-dialog.component.scss"],
  standalone: true,
  imports: [NgIf, NgFor, TagModule, DividerModule, AsyncPipe],
})
export class ChangelogDialogComponent implements OnInit {
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, public afs: AngularFirestore) {}

  oGTemplateUid!: string
  changelog$!: Observable<ILegacyTemplateChangelogData[]>
  testChangelog!: ILegacyTemplateChangelogData[]

  currentVersion!: number | null
  latestVersion!: number

  ngOnInit(): void {
    this.oGTemplateUid = this.config.data.OGTemplateUid
    this.currentVersion = this.config.data.currentVersion

    this.changelog$ = this.afs
      .collection(COLLECTIONS.LEGACY_TEMPLATES)
      .doc(this.oGTemplateUid)
      .collection<ILegacyTemplateChangelogData>(COLLECTIONS.CHANGELOG)
      .valueChanges()
      .pipe(
        map((changes) => {
          const sortedList = changes.sort((a, b) => {
            return b.internalVersion - a.internalVersion
          })

          if (sortedList.length > 0) {
            this.latestVersion = sortedList[0].internalVersion
          }

          return sortedList
        })
      )
  }

  closeDialog() {
    this.ref.close()
  }

  isNewestVersion(change: ILegacyTemplateChangelogData): boolean {
    if (change.internalVersion === this.latestVersion && !this.isCurrentVersion(change)) {
      return true
    }

    return false
  }

  isCurrentVersion(change: ILegacyTemplateChangelogData): boolean {
    if (!this.currentVersion) return false

    return change.internalVersion === this.currentVersion
  }
}
