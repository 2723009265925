import { Pipe, PipeTransform } from "@angular/core"
import { Item } from "../models/common"

@Pipe({
  name: "itemFilter",
  standalone: true,
})
export class ItemFilterPipe implements PipeTransform {
  transform(items: Item[], searchString: string, length?: number): any {
    if (!items) return []
    let processedSearchString = ItemFilterPipe.processString(searchString)

    if (searchString && Array.isArray(items)) {
      return items.filter((item) => ItemFilterPipe.isMatchingItemName(item, processedSearchString)).slice(0, length)
    }
    return items.slice(0, length)
  }

  static isMatchingItemName(item: Item, searchString: string) {
    let s = ItemFilterPipe.processString(searchString)
    if (s == "") return true

    // @ts-ignore
    return ItemFilterPipe.processString(item.name!).includes(s)
  }

  static processString(s: string) {
    return s != null ? s.trim().toLocaleLowerCase() : ""
  }
}
