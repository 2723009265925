import { Component } from "@angular/core"
import { DefaultElementComponent } from "@checkd-form/form-view/elements/default-element/default-element.component"

@Component({
  selector: "checkd-text-element",
  templateUrl: "./text-element.component.html",
  styleUrls: ["./text-element.component.scss"],
  standalone: true,
})
export class TextElementComponent extends DefaultElementComponent {}
