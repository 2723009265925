export const environment = {
  production: false,
  emulator: false,
  API_URL: "http://test.checkd.it/api/",
  FILESTACK_KEY: "ADavG0I0S0ipVtXTjzbxVz",
  COMPANY_LOGO_URL: "http://test.checkd.it/admin/images/users/original/",
  GOOGLE_MAPS_API_KEY: "AIzaSyByOpcHcTuk6szYwKa9cu5TE7XII2yxBXs",
  INTERCOM_APP_ID: "tdfw2qcz",
  PDF_GENERATOR_CF: "https://europe-west1-checkdstageu.cloudfunctions.net/pdf-generatePdf",
  DOCX_GENERATOR_CF: "https://europe-west1-checkdstageu.cloudfunctions.net/generateDocxFromItemReport",
  PDF_EMAIL_CF: "https://europe-west1-checkdstageu.cloudfunctions.net/sendPdfEmails",
  STREAM_ACCESS_KEY: "ytnm2td8qc38",

  firebase: {
    apiKey: "AIzaSyCFNFGACR9JdwYbBF7BC7ecEoMv87qmdn0",
    authDomain: "checkdstageu.firebaseapp.com",
    projectId: "checkdstageu",
    storageBucket: "checkdstageu.appspot.com",
    messagingSenderId: "903287235851",
    appId: "1:903287235851:web:00a25512558993dc9b2e0e",
    measurementId: "G-9NZZNFLL1R",
    functions: {
      url: "https://europe-west1-checkdstageu.cloudfunctions.net/",
    },
  },

  sentry: {
    environment: "stag",
    version: "8fadb486ecc81e3a7e2d9af30a6df0f538895f61",
  },

  itemIcons: {
    OPEN: "open.svg",
    CREATED: "open.svg",
    CLOSED: "closed.svg",
    COMPLETED: "closed.svg",
    INPROGRESS: "inprogress.svg",
    DELEGATED: "delegated.svg",
    ACCEPTED: "inprogress.svg",
    REJECTED: "open.svg",
    FIXED: "fixed.svg",
    CANCELLED: "open.svg",
    FIXED_REJECTED: "delegated.svg",
  },
  oneLogin: {
    authConfig: {
      client_id: "field",
      authority: "https://auth-test.next-tech.com",
      response_type: "code", // authorization code flow
      scope: "openid email offline offline_access",
      redirect_uri: `${window.location.origin}/auth`,
    },
    oneLoginBaseUrl: "https://account-test.next-tech.com",
  },
}
