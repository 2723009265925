import { Component, OnInit, Input, OnDestroy } from "@angular/core"
import { SharedConfigService } from "../../services/shared-config.service"
import { Location, NgIf, NgFor } from "@angular/common"
import { Subscription } from "rxjs"
import { MatLegacyMenuModule } from "@angular/material/legacy-menu"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { MatIconModule } from "@angular/material/icon"
import { RouterLink } from "@angular/router"
import { MatToolbarModule } from "@angular/material/toolbar"

@Component({
  selector: "ml-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.css"],
  standalone: true,
  imports: [NgIf, MatToolbarModule, RouterLink, MatIconModule, MatLegacyButtonModule, MatLegacyMenuModule, NgFor],
})
export class TopbarComponent implements OnInit, OnDestroy {
  @Input() title: string = ""
  @Input() showTopbar: boolean = false
  @Input() showTopbarMenu: boolean = false
  @Input() showBackButton: boolean
  topbarMenuItems: any[] = []
  topbarMenuActions = {}

  subscriptions: Subscription[]

  constructor(private sharedConfigService: SharedConfigService, private location: Location) {}

  ngOnInit() {
    this.setupSubscriptions()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.sharedConfigService.pageTitle.subscribe((title) => {
        this.title = title
      }),

      this.sharedConfigService.topbarMenuItems.subscribe((items) => {
        this.topbarMenuItems = items
        items.forEach((item) => {
          // @ts-ignore
          this.topbarMenuActions[item.name] = item.callback
        })
      }),

      this.sharedConfigService.backButton.subscribe((visible) => {
        this.showBackButton = visible
      }),
    ]
  }

  //TODO logout functionality
  logout() {}

  onTopbarMenuItemClicked(key: string) {
    // @ts-ignore
    this.topbarMenuActions[key]()
  }

  goBack(): void {
    this.location.back()
  }
}
