import { Component, OnInit } from "@angular/core"
import moment, { Moment } from "moment"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { FormsModule } from "@angular/forms"
import { OwlDateTimeModule } from "@danielmoncada/angular-datetime-picker"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "app-date-element",
  templateUrl: "./date-element.component.html",
  styleUrls: ["./date-element.component.scss"],
  standalone: true,
  imports: [FlexModule, MatLegacyTooltipModule, MatIconModule, OwlDateTimeModule, FormsModule],
})
export class DateElementComponent extends DefaultElementComponent implements OnInit {
  // @ts-ignore
  private _dateBackingField: Moment = null

  get date(): Moment {
    return this._dateBackingField
  }

  set date(newDate: Moment) {
    this._dateBackingField = newDate
    this.setValue(this.date == null ? "" : this.date.format("DD/MM/YYYY"))
  }

  override ngOnInit() {
    if (this.element?.value) {
      this._dateBackingField = this.element?.value.includes("-")
        ? moment(this.element.value, "YYYY-MM-DD")
        : moment(this.element.value, "DD/MM/YYYY")
    }
  }
}
