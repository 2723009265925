import { Component, OnInit } from "@angular/core"
import { ConfirmDialogComponent } from "@dialogs"
import { take } from "rxjs/operators"
import { CameraElement, FormElement, ImageElement } from "../../../models/form-element"
import { FormMessageService } from "../../../services/form-message.service"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { CheckdCommonModule } from "../../../../checkd-ui/checkd.common.module"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { ImageModule } from "primeng/image"
import { NgFor, NgIf } from "@angular/common"

@Component({
  selector: "app-camera-element",
  templateUrl: "./camera-element.component.html",
  styleUrls: ["./camera-element.component.scss"],
  standalone: true,
  imports: [NgFor, ImageModule, NgIf, MatLegacyButtonModule, CheckdCommonModule],
})
export class CameraElementComponent extends DefaultElementComponent implements OnInit {
  imageArray: { thumbnail: string; image: string }[] = []

  override ngOnInit() {
    this.updateImageArray()
  }

  updateImageArray() {
    this.imageArray = this.element
      .values!.filter((element) => element.value != null && element.value.trim() !== "")
      .map((element) => {
        const url = ImageElement.processUrl(element.value)

        return { thumbnail: url, image: url }
      })
  }

  onUpload(result: any) {
    if (result.filesFailed.length > 0) {
      console.error("TODO handle failed image uploads")
    }
    result.filesUploaded.forEach((file: { url: string }) => {
      const formElement = new FormElement()
      // @ts-ignore
      const lastFormElementId = this.element.values.length > 0 ? this.element.values[this.element.values.length - 1].id : 1
      formElement.id = lastFormElementId + 1
      formElement.value = file.url
      this.element.values!.push(formElement)

      const cameraElement = this.element as CameraElement
      cameraElement.images.push(CameraElement.createImageElement(formElement, cameraElement))
    })

    this.updateImageArray()
    this.formMessageService.sendMessage(FormMessageService.IMAGE_ADDED, this)
  }

  deleteImage(imageUrl: string, imageArrayIndex: number) {
    this.dialogRef
      .open(ConfirmDialogComponent, {
        data: { title: "Delete image", content: "Are you sure you want to delete this image? This cannot be undone." },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((response) => {
        if (!response) {
          return
        }

        this.imageArray.splice(imageArrayIndex, 1)
        const cameraElement: CameraElement | undefined = this.element as CameraElement

        if (!cameraElement) {
          return
        }

        for (const [index, element] of cameraElement.images.entries()) {
          if (element.value === imageUrl) {
            cameraElement.images.splice(index, 1)
            this.formMessageService.sendMessage(FormMessageService.IMAGE_ADDED, this)
            break
          }
        }
        for (const [index, element] of (cameraElement?.values ?? []).entries()) {
          if (element.value === imageUrl) {
            cameraElement.values!.splice(index, 1)
            this.formMessageService.sendMessage(FormMessageService.IMAGE_ADDED, this)
            break
          }
        }
      })
  }
}
