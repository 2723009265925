import { Component, Inject, OnInit } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { ExtendedModule } from "@angular/flex-layout/extended"

@Component({
  selector: "checkd-project-duplication-dialog",
  templateUrl: "./project-duplication-dialog.component.html",
  styleUrls: ["./project-duplication-dialog.component.scss"],
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    ExtendedModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyCheckboxModule,
    MatLegacyButtonModule,
  ],
})
export class ProjectDuplicationDialogComponent implements OnInit {
  duplicationForm: UntypedFormGroup

  constructor(
    public dialogRef: MatDialogRef<ProjectDuplicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { currentName: string; currentDescription: String },
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.duplicationForm = this.fb.group({
      newProjectName: [
        `${this.data.currentName} (copy)`,
        [
          Validators.required, // At least one character required
          Validators.pattern("^(?=\\s*\\S).*$"), // at least one non-whitespace character required
        ],
      ],
      newProjectDescription: this.data.currentDescription,
      includeDrawings: false,
      includePeople: false,
      includeTemplates: false,
    })
  }
}
