import { Component, OnInit } from "@angular/core"
import { CardModule } from "primeng/card"

@Component({
  selector: "checkd-publisher-card",
  templateUrl: "./publisher-card.component.html",
  styleUrls: ["./publisher-card.component.scss"],
  standalone: true,
  imports: [CardModule],
})
export class PublisherCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
