import { Component, OnDestroy, OnInit } from "@angular/core"
import { DefaultElementComponent } from "@checkd-form/form-view/elements/default-element/default-element.component"
import { FormsItemsElement, ItemListFormElement } from "@checkd-form/models/form-element"
import { FormMessageService } from "@checkd-form/services/form-message.service"
import { Item } from "@models/common/item"
import { Subscription } from "rxjs"

import { NgIf, NgFor } from "@angular/common"
import { PrimaryButtonDirective } from "../../../../next-ui/button/primary-button.directive"

@Component({
  selector: "checkd-forms-items-element",
  templateUrl: "./forms-items-element.component.html",
  styleUrls: ["./forms-items-element.component.scss"],
  standalone: true,
  imports: [NgIf, NgFor, PrimaryButtonDirective],
})
export class FormsItemsElementComponent extends DefaultElementComponent implements OnInit, OnDestroy {
  get items(): any {
    return (this.formsItemsElement || { items: [] }).items
  }

  get itemLength() {
    return Object.keys((this.formsItemsElement && this.formsItemsElement.itemMap) || {}).length
  }

  get formsItemsElement(): FormsItemsElement {
    return this.element as FormsItemsElement
  }

  get prefill() {
    return this.formsItemsElement.prefill || {}
  }

  itemListFormElement: ItemListFormElement
  subscriptions: Subscription[] = []
  reportItems: Item[] = []
  reportItemsMap: { [itemUid: string]: Item } = {}

  override ngOnInit() {
    this.setupSubscriptions()
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.formMessageService.reportItems$.subscribe((reportItems) => {
        this.reportItems = reportItems
      }),
      this.formMessageService.reportItemsMap$.subscribe((reportItemsMap) => {
        this.reportItemsMap = reportItemsMap

        // If any of these are null, we are in the forms builder view
        if (this.formsItemsElement == null || this.formsItemsElement.updateItemMap == null) {
          return
        }

        this.formsItemsElement.updateItemMap(reportItemsMap)
        // @ts-ignore
        this.itemListFormElement = null
        this.itemListFormElement = this.formsItemsElement.toItemList()
      }),
    ]
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  addItem(item: Item) {
    // If any of these are null, we are in the forms builder view
    if (this.formsItemsElement == null || this.formsItemsElement.addToItemMap == null || this.formsItemsElement.toItemList == null) {
      return
    }

    this.formsItemsElement.items = [...this.formsItemsElement.items, item.uid]
    this.formsItemsElement.addToItemMap(item)
    // @ts-ignore
    this.itemListFormElement = null
    this.itemListFormElement = this.formsItemsElement.toItemList()
  }

  createItem() {
    this.formMessageService.sendMessage(FormMessageService.CREATE_ITEM, { prefill: this.prefill, element: this })
  }

  openItem(item: Item) {
    this.formMessageService.sendMessage(FormMessageService.OPEN_ITEM, item)
  }
}
