import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { CompanyViewComponent } from "@company"
import { ProfileViewComponent } from "@people"
import { authGuard } from "./dashboard/guards/auth.guard"
import { InsightsGuard } from "./dashboard/guards/insights.guard"
import { LoginViewComponent } from "./dashboard/login-view/login-view.component"
import { AccountActivationViewComponent } from "./dashboard/login/account-activation-view/account-activation-view.component"
import { PasswordRecoveryViewComponent } from "./dashboard/login/password-recovery-view/password-recovery-view.component"
import { PasswordResetComponent } from "./dashboard/login/password-reset/password-reset.component"
import { EmailVerificationViewComponent } from "./dashboard/login/email-verification-view/email-verification-view.component"
import { AuthActionComponent } from "./dashboard/login/auth-action/auth-action.component"
import { RouteParameters } from "./dashboard/reports/route-parameters"

const routes: Routes = [
  { path: "", redirectTo: "/projects", pathMatch: "full" },
  { path: "login", component: LoginViewComponent },
  { path: "passwordrecovery", component: PasswordRecoveryViewComponent },
  { path: "useractivation", component: AccountActivationViewComponent },
  { path: "passwordreset", component: PasswordResetComponent },
  { path: "emailverification", component: EmailVerificationViewComponent },
  { path: "authaction", component: AuthActionComponent },
  {
    path: "profile",
    loadComponent: () => import("./dashboard/people/profile-view/profile-view.component").then((c) => c.ProfileViewComponent),
  },
  {
    path: "items",
    loadComponent: () => import("./dashboard/items/my-items/my-items.component").then((c) => c.MyItemsComponent),
    canActivate: [authGuard],
  },
  { path: "company", component: CompanyViewComponent, canActivate: [authGuard] },

  {
    path: "qr",
    loadChildren: () => import("./dashboard/features/qr-views/qr-views.module").then((m) => m.QrViewsModule),
  },

  // REPORT ROUTES
  {
    path: `fieldReports/:${RouteParameters.reportUid}`,
    loadComponent: () =>
      import("src/app/dashboard/reports/general-report-view/general-report-view.component").then((c) => c.GeneralReportViewComponent),
  },
  {
    path: `legacyReports/:${RouteParameters.reportUid}`,
    loadComponent: () =>
      import("src/app/dashboard/reports/general-report-view/general-report-view.component").then((c) => c.GeneralReportViewComponent),
  },
  {
    path: `projects/:projectId/legacyReports/:${RouteParameters.reportUid}`,
    loadComponent: () =>
      import("src/app/dashboard/reports/general-report-view/general-report-view.component").then((c) => c.GeneralReportViewComponent),
  },
  {
    path: `projects/:${RouteParameters.projectUid}/openTemplates/:${RouteParameters.templateUid}/new`,
    loadComponent: () =>
      import("src/app/dashboard/reports/new-open-report-view/new-open-report-view.component").then((c) => c.NewOpenReportViewComponent),
  },
  {
    path: `projects/:${RouteParameters.projectUid}/openReports/:${RouteParameters.reportUid}`,
    loadComponent: () =>
      import("src/app/dashboard/reports/open-report-view/open-report-view.component").then((c) => c.OpenReportViewComponent),
  },
  {
    path: `openReports/:${RouteParameters.reportUid}`,
    loadComponent: () =>
      import("src/app/dashboard/reports/open-report-view/open-report-view.component").then((c) => c.OpenReportViewComponent),
  },
  {
    path: `openReports/:${RouteParameters.reportUid}/receipt`,
    loadComponent: () =>
      import("src/app/dashboard/reports/open-report-view/open-report-view.component").then((c) => c.OpenReportViewComponent),
  },

  // PROJECT ROUTES
  {
    path: "projects/new",
    loadComponent: () =>
      import("src/app/dashboard/project/new-project-view/new-project-view.component").then((c) => c.NewProjectViewComponent),
    canActivate: [authGuard],
  },
  {
    path: "projects",
    loadComponent: () => import("src/app/dashboard/project/projects-view/projects-view.component").then((c) => c.ProjectsViewComponent),
    canActivate: [authGuard],
  },
  {
    path: "projects/:projectId",
    redirectTo: "/projects/:projectId/items",
    pathMatch: "full",
  },
  {
    path: "projects/:projectId/:tabName",
    loadComponent: () => import("src/app/dashboard/project/project-view/project-view.component").then((c) => c.ProjectViewComponent),
    canActivate: [authGuard],
  },
  {
    path: "projects/:projectId/drawings/:drawingId",
    loadComponent: () => import("src/app/dashboard/drawings/drawing-view/drawing-view.component").then((c) => c.DrawingViewComponent),
    canActivate: [authGuard],
  },

  // INVITATION ROUTES
  {
    path: "invitations",
    loadChildren: () => import("./dashboard/invitations/invitation.module").then((m) => m.InvitationModule),
  },

  // FORMS ROUTES
  {
    path: "templateCreator",
    loadChildren: () => import("./dashboard/template-creator/template-creator.module").then((m) => m.TemplateCreatorModule),
    canActivate: [authGuard],
  },
  {
    path: "formsBuilder",
    loadChildren: () => import("./dashboard/template-creator/template-creator.module").then((m) => m.TemplateCreatorModule),
    canActivate: [authGuard],
  },

  {
    path: "forms",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./dashboard/features/public-template-library/public-template-library.module").then((m) => m.PublicTemplateLibraryModule),
      },
      {
        path: "builder",
        loadChildren: () => import("./dashboard/template-creator/template-creator.module").then((m) => m.TemplateCreatorModule),
        canActivate: [authGuard],
      },
      {
        path: "company-templates",
        loadComponent: () => import("./dashboard/not-found/not-found.component").then((c) => c.NotFoundComponent),
      },
    ],
  },
  // {
  //   path: "chat",
  //   loadChildren: () => import("./dashboard/features/chat/chat.module").then((m) => m.ChatModule),
  // },

  // INSIGHTS ROUTES
  {
    path: "insights",
    loadChildren: () => import("./dashboard/features/insights/insights.module").then((m) => m.InsightsModule),
    canActivate: [InsightsGuard],
  },

  {
    path: "404",
    loadComponent: () => import("./dashboard/not-found/not-found.component").then((c) => c.NotFoundComponent),
  },
  { path: "**", redirectTo: "/404" },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
