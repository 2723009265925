import { Component, inject, OnDestroy } from "@angular/core"
import { BehaviorSubject, combineLatest, Observable } from "rxjs"
import { distinctUntilChanged, map } from "rxjs/operators"

import { FieldAuthService, NavigationService, UserService } from "@services"
import { SideNavService } from "@services/side-nav.service"

@Component({
  selector: "checkd-top-bar",
  templateUrl: "./top-bar.component.html",
  styleUrls: ["./top-bar.component.scss"],
})
export class TopBarComponent implements OnDestroy {
  private readonly userService: UserService = inject(UserService)
  private readonly fieldAuthService: FieldAuthService = inject(FieldAuthService)
  public readonly navigationService: NavigationService = inject(NavigationService)
  public readonly sideNavService: SideNavService = inject(SideNavService)

  readonly authority$ = new BehaviorSubject<string | null>(null)
  readonly target$ = new BehaviorSubject<string | null>(null)
  readonly currentUser$ = this.userService.currentUser$
  readonly currentCompany$ = this.userService.currentCompany$

  readonly userName$ = this.currentUser$.pipe(
    map((user) => (user?.name ? user.name : "")),
    distinctUntilChanged()
  )

  get isOneLogin() {
    return !!sessionStorage.getItem("one-login:issuer")
  }

  readonly oneLogin$ = combineLatest([this.fieldAuthService.authority$, this.navigationService.showTopBar$]).pipe(
    map(async ([authority, showToolbar]) => {
      if (!showToolbar) {
        return false
      }

      const target = authority?.replace(/\/\/auth/g, "//account")
      if (authority) {
        const key = `oidc.user:${authority}:field`
        const data = sessionStorage.getItem(key)
        if (data) {
          sessionStorage.setItem(key, data)
          await this.addCustomElements(target)
        }
      }
      this.authority$.next(authority ?? "")
      this.target$.next(target ?? "")

      return true
    }),
    distinctUntilChanged()
  )

  private async addCustomElements(target: string) {
    return document.head.querySelector("script[data-onelogin]")
      ? Promise.resolve(null)
      : new Promise((resolve) => {
          const script = document.createElement("script")
          script.setAttribute("data-onelogin", "true")
          script.onload = resolve
          script.src = `${target}/custom-elements.js`
          document.head.appendChild(script)
        })
  }

  ngOnDestroy() {
    this.authority$.complete()
    this.target$.complete()
    // await this.fieldChatDashboardService.disconnectUser()
  }

  private readonly pathComponents$ = this.navigationService.pathComponents$

  readonly darkTopBar$: Observable<boolean> = this.pathComponents$.pipe(
    map((pathComponents) => pathComponents.includes("insights")),
    distinctUntilChanged()
  )

  readonly useShadow$: Observable<boolean> = this.pathComponents$.pipe(
    map((pathComponents) => {
      const withoutShadow = [
        "insights", // TODO: project page
      ]

      return this.isOneLogin ? false : pathComponents && !!withoutShadow.filter((value) => pathComponents.includes(value))
    }),
    distinctUntilChanged()
  )

  // NOTE: leaving this field chat stuff here until a definite decision of its future is made
  // ----------------------------------------------------------------------------------------
  // This subscription was in ngOnInit
  // this.userCompanyHasFieldChatFeature$
  //   .pipe(
  //     takeUntil(this.ngUnsubscribe),
  //     map((data) => data.includes(true)),
  //     distinctUntilChanged()
  //   )
  //   .subscribe(async (hasChatFeature) => {
  //     if (hasChatFeature) {
  //       try {
  //         await this.fieldChatDashboardService.initializeChatClient()
  //       } catch (e: any) {
  //         console.error(e)
  //       }
  //     }
  //   })

  // async onChatBubbleClicked() {
  //   const isInProject = this.projectService.isInProjectTest(this.router.url)
  //
  //   let url: string
  //   if (isInProject) {
  //     const project = await firstValueFrom(
  //       this.projectService.currentProject$.pipe(
  //         take(1),
  //         timeout(1000),
  //         catchError((err) => of(null))
  //       )
  //     )
  //
  //     if (project === null) {
  //       return console.error("Could not get current project UID")
  //     }
  //
  //     url = this.router.createUrlTree(["/chat"], { queryParams: { projectUid: project.uid, projectName: project.name } }).toString()
  //   } else {
  //     url = this.router.createUrlTree(["/chat"]).toString()
  //   }
  //
  //   window.open(url, "_blank")
  // }
  //
  // // Emits every time navigation occurs
  // private readonly onNavigation$ = this.router.events.pipe(
  //   filter((e): e is NavigationEnd => e instanceof NavigationEnd),
  //   shareReplay({ bufferSize: 1, refCount: true }),
  //   takeUntil(this.ngUnsubscribe)
  // )

  // readonly userCompanyHasFieldChatFeature$ = combineLatest([
  //   this.userService.currentCompanyHasFeature(CompanyFeatures.FIELD_CHAT),
  //   this.projectService.currentProjectHasCompanyFeature(CompanyFeatures.FIELD_CHAT).pipe(startWith(false)),
  // ]).pipe(shareReplay({ bufferSize: 1, refCount: true }))

  // readonly shouldDisplayChatBubbleIcon$: Observable<boolean> = this.onNavigation$.pipe(
  //   switchMap((_) => this.userCompanyHasFieldChatFeature$),
  //   map(([companyHasFieldChatFeature, currentProjectHasFieldChatCompanyFeature]) => {
  //     // Hack to detect whether user is currently inside a project
  //     const isInProject = this.projectService.isInProjectTest(this.router.url)
  //
  //     return companyHasFieldChatFeature || (currentProjectHasFieldChatCompanyFeature && isInProject)
  //   }),
  //   takeUntil(this.ngUnsubscribe)
  // )
}
