import { Directive, HostBinding, Input } from "@angular/core"

@Directive({
  selector: "[danger-button]",
  standalone: true,
})
export class DangerButtonDirective {
  constructor() {}
  @Input() isRounded = false

  @HostBinding("class")
  get classes(): string {
    return "button button__danger"
  }

  @HostBinding("class.button__rounded") get roundedClass(): boolean {
    return this.isRounded
  }
}
