import { Component, OnInit, Input } from "@angular/core"
import { MatIconModule } from "@angular/material/icon"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "app-placeholder",
  templateUrl: "./placeholder.component.html",
  styleUrls: ["./placeholder.component.scss"],
  standalone: true,
  imports: [FlexModule, MatIconModule],
})
export class PlaceholderComponent implements OnInit {
  @Input() heading: string
  @Input() svgIcon: string

  constructor() {}

  ngOnInit() {}
}
