import { inject, Injectable } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { Router } from "@angular/router"
import { environment } from "@environments/environment"
import { COLLECTIONS } from "@models/common"
import { TSessionData } from "@models/common/session.interface"
import * as Sentry from "@sentry/angular-ivy"
import { AgreementsService } from "@services/agreements.service"
import { OAuthCredential, OAuthProvider } from "firebase/auth"
import firebase from "firebase/compat"
import { UserManager } from "oidc-client-ts"
import { BehaviorSubject, finalize, of, Subscription } from "rxjs"
import { distinctUntilChanged, map, shareReplay, switchMap, tap } from "rxjs/operators"

@Injectable()
export class FieldAuthService {
  readonly afAuth = inject(AngularFireAuth)
  readonly db = inject(AngularFirestore)
  readonly router = inject(Router)
  readonly agreementsService = inject(AgreementsService)

  private readonly _authoritySubject = new BehaviorSubject("")
  readonly authority$ = this._authoritySubject.asObservable().pipe(distinctUntilChanged())

  private storage: Storage = sessionStorage

  readonly userManager: UserManager
  private backChannelLogoutSubscription: Subscription | null = null

  constructor() {
    const userManagerSettings = {
      ...environment.oneLogin.authConfig,
    }
    this.userManager = new UserManager(userManagerSettings)
  }

  readonly authState$ = this.afAuth.authState.pipe(
    tap((user) => this.checkBackChannelLogoutListener(user)),
    tap((user) => {
      if (!user) {
        Sentry.configureScope((scope) => scope.setUser(null))
        this.resetAuthority()

        return
      }

      this.updateAuthorityFromStorage()
      Sentry.configureScope((scope) => scope.setUser({ id: user.uid }))
      this.agreementsService.currentUserUid$.next(user.uid)
    }),
    finalize(() => this.checkBackChannelLogoutListener(null)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  private checkBackChannelLogoutListener(user: firebase.User | null) {
    if (!user) {
      this.backChannelLogoutSubscription?.unsubscribe()
      this.backChannelLogoutSubscription = null

      return
    }

    if (user && this.backChannelLogoutSubscription) {
      return
    }

    this.backChannelLogoutSubscription = this.backChannelLogoutListener$.subscribe()
  }

  private updateAuthorityFromStorage() {
    const issuer = this.storage.getItem("one-login:issuer") ?? ""
    this._authoritySubject.next(issuer)
  }

  resetAuthority() {
    this._authoritySubject.next("")
  }

  /**
   * Redirects the user to the OneLogin login page.
   */
  beginSignInToOneLoginFlow() {
    return this.userManager.signinRedirect({})
  }

  signInCallBack() {
    return this.userManager.signinCallback().then((user) => {
      const accessToken = user?.access_token
      if (!accessToken) {
        throw new Error("Access token is missing")
      }
      // save it here to use it in setupOneLoginDataForTopBar
      this.storage.setItem("one-login:access-token", accessToken)

      return user
    })
  }

  private setupOneLoginDataForTopBar(x: firebase.auth.UserCredential) {
    if (x.credential?.providerId === "oidc.hydra") {
      const credentials = x.credential as OAuthCredential
      const { idToken: id_token } = credentials
      const access_token = this.storage.getItem("one-login:access-token") ?? ""
      this.storage.removeItem("one-login:access-token")
      const additionalUserInfo = x.additionalUserInfo!
      // INFO: assume oidc.hydra gives us all this (as it should), rigmarole
      // casting (with additional hidden props)
      const { profile } = additionalUserInfo as unknown as { profile: { iss: string } }
      const { iss: issuer } = profile
      this.storage.setItem("one-login:issuer", issuer)
      this._authoritySubject.next(issuer)
      // INFO: store authentication information in local-storage (firebase auth session is not
      // tied to session).
      this.storage.setItem(`oidc.user:${issuer}:field`, JSON.stringify({ access_token, id_token, profile }))
    } else {
      this._authoritySubject.next("")
    }
  }

  private readonly backChannelLogoutListener$ = this.afAuth.idTokenResult.pipe(
    switchMap((idTokenResult) => {
      if (!idTokenResult) {
        console.debug("No ID token result")

        return of(null)
      }

      const sid: string = idTokenResult?.claims?.["firebase"]?.["sign_in_attributes"]?.["sid"]
      if (!sid) {
        throw new Error("SID not found in claims")
      }

      return of(sid)
    }),
    distinctUntilChanged(),
    switchMap((sid) => (sid ? this.db.collection(COLLECTIONS.SESSIONS).doc(sid).valueChanges() : of(null))),
    tap(async (sessionData: unknown) => {
      if (!sessionData) {
        return console.error("Session data/SID is missing")
      }

      const session = sessionData as TSessionData
      if (session.status === "active") {
        return
      }

      await this.logoutTasks()
    })
  )

  async signInToFirebaseWithCredential(idToken: string) {
    const provider = new OAuthProvider("oidc.hydra")
    const credential = provider.credential({ idToken })

    console.log('about to sign in with credential')

    return this.afAuth.signInWithCredential(credential).then((userCredential) => {
      console.log("bleh")
      this.setupOneLoginDataForTopBar(userCredential)

      return userCredential
    })
  }

  /**
   * This private function should only be called as part of the back-channel logout process.
   * After switching to OneLogin, there shouldn't be any buttons in Field to log out.
   * @private
   */
  private logoutTasks() {
    localStorage.clear()

    return Promise.all([this.userManager.signoutSilent(), this.afAuth.signOut()]).then(() => this.resetAuthority())
  }

  isLoggedIn() {
    return this.authState$.pipe(map((user) => !!user))
  }
}
