import { Component, Input, OnInit } from "@angular/core"
import { Person } from "@models/common"
import { IFormsLibraryMemberCompanyEmailInvitation } from "@models/common/forms-library-member-company-invitation.interface"
import { SignUpComponent } from "../sign-up/sign-up.component"
import { SignInComponent } from "../sign-in/sign-in.component"
import { NgIf } from "@angular/common"

@Component({
  selector: "checkd-not-signed-in",
  templateUrl: "./not-signed-in.component.html",
  styleUrls: ["./not-signed-in.component.scss"],
  standalone: true,
  imports: [NgIf, SignInComponent, SignUpComponent],
})
export class NotSignedInComponent implements OnInit {
  @Input() invitation!: IFormsLibraryMemberCompanyEmailInvitation
  @Input() targetUser!: Person | null

  constructor() {}

  ngOnInit(): void {}
}
