import { Component, OnInit, Input } from "@angular/core"
import { Observable } from "rxjs"
import { Person, Company, Project, LABELS } from "@models/common"
import { PeopleRoleSelectorComponent } from "../people-role-selector/people-role-selector.component"
import { PeopleListItemComponent } from "../people-list-item/people-list-item.component"
import { NgFor, NgIf, AsyncPipe } from "@angular/common"

@Component({
  selector: "app-people-list",
  templateUrl: "./people-list.component.html",
  styleUrls: ["./people-list.component.scss"],
  standalone: true,
  imports: [NgFor, PeopleListItemComponent, NgIf, PeopleRoleSelectorComponent, AsyncPipe],
})
export class PeopleListComponent implements OnInit {
  @Input() people: Person[]
  @Input() peopleListOf: Observable<Company | Project>
  @Input() showRoleSelector: boolean
  // TODO Use actual Role instances here to get the description etc.
  @Input() roles: any[] = [
    { label: LABELS.DEFAULT, description: "Normal user" },
    { label: LABELS.ADMINISTRATOR, description: "Admin user" },
  ]

  constructor() {}

  ngOnInit() {}
}
