import { Injectable } from "@angular/core"
import { ICheckdFormConfig } from "@checkd-form/models/form"
import { COLLECTIONS, Company, FieldReport, LABELS, Person, Project, ReportGenerationSettings, Timeline } from "@models/common"
import { ModelService, RelationService, ReportService, ReportTimelineService } from "@services"
import { from as observableFrom, Observable } from "rxjs"
import { map, switchMap } from "rxjs/operators"

@Injectable({ providedIn: "root" })
export class FieldReportService {
  constructor(
    private modelService: ModelService,
    private relationService: RelationService,
    public reportService: ReportService,
    public reportTimelineService: ReportTimelineService,
  ) {}

  listenToUid(fieldReportUid: string): Observable<FieldReport> {
    return (
      this.modelService
        .listenTo(FieldReport.COLLECTION, fieldReportUid)
        // NB: We need to manually change model from a Report to a
        // FieldReport as our model inference does not yet support
        // multiple models for a given top-level collection
        .pipe(map((report) => new FieldReport(report.data, report.uid, report.ref)))
    )
  }

  listenToCreator(fieldReport: FieldReport): Observable<Person> {
    return this.relationService.listenToFirstTarget(fieldReport, COLLECTIONS.PEOPLE, [LABELS.CREATED_BY])
  }

  listenToCompany(fieldReport: FieldReport): Observable<Company> {
    return this.relationService.listenToFirstTarget(fieldReport, COLLECTIONS.COMPANIES, [LABELS.CONTAINED_BY])
  }

  listenToProject(fieldReport: FieldReport): Observable<Project> {
    return this.relationService.listenToFirstTarget(fieldReport, COLLECTIONS.PROJECTS)
  }

  listenToTimeline(fieldReport: FieldReport): Observable<Timeline> {
    return this.reportTimelineService.listenForReport(fieldReport)
  }

  listenToLastTimelineElementMessage(fieldReport: FieldReport): Observable<string> {
    return this.reportTimelineService.listenToLastTimelineElement(fieldReport).pipe(
      switchMap((timelineElement) => {
        return observableFrom(timelineElement.getCreator()).pipe(
          map((creator) => this.reportTimelineService.createMessageFromTimelineElement(timelineElement, creator)),
        )
      }),
    )
  }
}

export interface IPdfDocDefinitionConfig {
  projectTitle: string
  creator: any
  reportLink?: string
  companyLogoBase64?: string
  localeCreatedDate?: string
  creatorName?: string
  contentTotal?: { totalItems: number; totalDrawings: number }
  settings?: ReportGenerationSettings
  pdfFooter?: string
  config?: ICheckdFormConfig
}
