import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { AngularFireFunctions } from "@angular/fire/compat/functions"
import { ActivatedRoute } from "@angular/router"
import { CheckdColors } from "@checkd-colors"
import { CheckdFormDialogService } from "@checkd-form/services/checkd-form-dialog.service"
import { defaultStatusFilter } from "@checkd-ui"
import { PeopleListDialogComponent } from "@dialogs"
import { ItemImageGalleryDialogComponent, ItemListComponent } from "@items"
import {
  Company,
  CompanyFeatures,
  convertWorkflowStateToStatus,
  ExportGenerationData,
  ExportType,
  FilestackUploadResult,
  Item,
  ItemMenuActions,
  LegacyReport,
  Person,
  Project,
  ReportGenerationData,
  ReportType,
  Role,
  RoleType,
  WorkflowStatusFilter,
} from "@models/common"
import { GeneralReport } from "@models/common/general-report"
import { ReportGenerationDialogComponent, ReportSharingDialogComponent } from "@reports"
import {
  CloudFunctionCsvExportResult,
  CloudFunctionsService,
  FileHandlerService,
  FilestackService,
  ItemFilterService,
  ProjectService,
  ReportService,
  RoleHandlerService,
  TimelineService,
  UserService,
} from "@services"
import { ItemService } from "@services/item.service"
import { uniquifyAndSort } from "@services/utilities"
import { MessageService } from "primeng/api"
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs"
import { map, shareReplay, take } from "rxjs/operators"
import { DialogService } from "../../dialogs/dialog.service"
import { ItemDialogService } from "../../items/item-dialog.service"
import { ActionTypeSelected } from "../../items/items-table-action-bar/items-table-action-bar.component"
import { IDuplicatonSettings, ItemDuplicationDialogComponent } from "../dialogs/item-duplication-dialog/item-duplication-dialog.component"
import { ProjectInvitationDialogComponent } from "../project-invitation-dialog/project-invitation-dialog.component"
import { ProjectDialogService } from "../services/project-dialog.service"
import { ProjectItemsDisplayed } from "./project-items-displayed.enum"
import { NextExperimentalService } from "@services/apis/next-experimental.service"
import { AsyncPipe } from "@angular/common"
import { ToastModule } from "primeng/toast"
import { ItemListComponent as ItemListComponent_1 } from "../../items/item-list/item-list.component"
import { ProjectItemsTopActionBarComponent } from "../project-view/project-items-top-action-bar/project-items-top-action-bar.component"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "checkd-project-items",
  templateUrl: "./project-items.component.html",
  styleUrls: ["./project-items.component.scss"],
  providers: [MessageService],
  standalone: true,
  imports: [FlexModule, ProjectItemsTopActionBarComponent, ItemListComponent_1, ToastModule, AsyncPipe],
})
export class ProjectItemsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  //Empty state

  noProjectItemTitle = "No project items matching your search."
  noProjectItemDesc = "Try using fewer words and letters, or search in your main “My items” page."

  noProjectItemsImgSrc = "./assets/images/ilustrations/not-found-project-item.svg"

  @Input() isLoadingProjectItems: boolean
  @Input() project: Project
  @Input() currentUser: Person
  @Input() currentCompany: Company
  @Input() currentCompanyMembers: Person[]
  @Input() currentCompanyAssociates: Person[]

  @Input() set projectItems(items: Item[]) {
    this.items = items
    this.items$.next(items)
  }

  @Input() userProjectRole: Role
  @Input() userCompanyRole: Role
  @Input() projectMembers: Person[]
  @Input() projectItemsReady: boolean

  @Output() drawingSelectionClicked = new EventEmitter()

  @ViewChild("checkdItemList", { static: true }) itemListComponent: ItemListComponent

  maxItemsForBulkActions: number = 75
  maxItemsForReportGeneration: number = 300

  statusFilter$ = new BehaviorSubject(defaultStatusFilter)
  projectItemsDisplayed$ = new BehaviorSubject(ProjectItemsDisplayed.UserItems)
  items$ = new BehaviorSubject<Item[]>([])
  items: Item[] = []
  selectedItems: Item[] = []

  currentUserCompanyRole: Role

  searchString: string
  numberValues: number[] = []
  titleValues: string[] = []
  tagValues: string[] = []
  creatorValues: string[] = []
  assignerValues: string[] = []
  assigneeValues: string[] = []
  companyValues: string[] = []
  updatedValues: any[] = []
  drawingValues: string[] = []
  reportNameValues: string[] = []

  csvExportInProgress: boolean = false

  roleFilteredItems$: Observable<Item[]> = combineLatest([this.items$, this.projectItemsDisplayed$]).pipe(
    map(([items, projectItemsDisplayed]) => {
      // tslint:disable-next-line:switch-default
      switch (projectItemsDisplayed) {
        case ProjectItemsDisplayed.UserItems:
        case ProjectItemsDisplayed.AllItems:
          const userItemsOnly: boolean = projectItemsDisplayed === ProjectItemsDisplayed.UserItems

          return (items || []).filter((item) => this.itemFilterService.isUserItem(item, this.currentUser, userItemsOnly))
        case ProjectItemsDisplayed.UserCompanyItems:
          const companyMemberUids: Set<string> = new Set((this.currentCompanyMembers ?? []).map((user) => user.uid).filter((it) => it))

          return this.items.filter((item) => this.isItemRelatedToCompany(companyMemberUids, item))
      }
    })
  )

  isItemRelatedToCompany(companyMembersUids: Set<string>, item: Item): boolean {
    // Is the item created by a user in the current user's company?
    if (item.aggregateData?.itemCreatorUid && companyMembersUids.has(item.aggregateData.itemCreatorUid)) return true

    // Is the item delegated to a member of the current company?
    if (item.aggregateData?.taskAssigneeUid && companyMembersUids.has(item.aggregateData.taskAssigneeUid)) return true

    // Is the item delegated by a member of the current company? // TODO: is this necessary?
    if (item.aggregateData?.taskAssignerUid && companyMembersUids.has(item.aggregateData.taskAssignerUid)) return true

    // Does the item have a collaborator that is a member of the current user's company?
    return (item.aggregateData?.taskCollaboratorUids ?? []).some((uid) => companyMembersUids.has(uid))
  }

  statusAndRoleFilteredItems$: Observable<Item[]> = combineLatest([this.roleFilteredItems$, this.statusFilter$]).pipe(
    map((it) => it[0].filter((item) => this.itemFilterService.hasIncludedStatus(item, it[1]))),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  readonly projectItemsStatusCounts$: Observable<{ [status: string]: number }> = this.statusAndRoleFilteredItems$.pipe(
    map((projectItems) => {
      const statusMap: { [status: string]: number } = {}
      for (const item of projectItems) {
        const status = convertWorkflowStateToStatus(item.status || "NONE")
        if (!statusMap[status]) {
          statusMap[status] = 1
          continue
        }
        statusMap[status] += 1
      }

      return statusMap
    })
  )

  roleFilteredItems: Item[]

  subscriptions: Subscription[] = []

  styles: any = {
    buttonText: {
      active: {
        color: CheckdColors.CHECKD_BLUE,
        "border-bottom": `2px solid ${CheckdColors.CHECKD_BLUE}`,
      },
      inactive: {
        color: CheckdColors.CHECKD_GRAY_TRANS,
        "border-bottom": `2px solid ${CheckdColors.CHECKD_GRAY_TRANS}`,
      },
    },
  }

  bulkAddTagsInProgress = false
  bulkAttachItemsToReportInProgress = false
  bulkAssignInProgress = false
  bulkCloseInProgress = false
  bulkAcceptInProgress = false

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private projectDialogService: ProjectDialogService,
    private dialogService: DialogService,
    private formDialogService: CheckdFormDialogService,
    private itemDialogService: ItemDialogService,
    private timelineService: TimelineService,
    private filestackService: FilestackService,
    private itemFilterService: ItemFilterService,
    private reportService: ReportService,
    private roleHandlerService: RoleHandlerService,
    private cloudFunctionsService: CloudFunctionsService,
    private fileHandlerService: FileHandlerService,
    private firebaseFunctions: AngularFireFunctions,
    private messageService: MessageService,
    public itemService: ItemService,
    public projectService: ProjectService,
    public userService: UserService,
    private cd: ChangeDetectorRef // private nextService: NextExperimentalService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["project"]) {
      this.setupSearchValues()
    }
  }

  ngOnInit() {
    this.setupSearchValues()
    this.setupSubscriptions()
  }

  ngAfterViewInit() {
    // Force another change detection cycle to prevent ExpressionChangedAfterItHasBeenCheckedError
    this.cd.detectChanges()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.roleFilteredItems$.subscribe((it) => (this.roleFilteredItems = it)),
      this.userService.currentUserCompanyRole$.subscribe((userCompanyRole) => (this.currentUserCompanyRole = userCompanyRole)),
    ]
  }

  updateStatusFilter(event: WorkflowStatusFilter) {
    this.statusFilter$.next(event)
    this.projectService.statusFilter$.next(event)
  }

  setupSearchValues() {
    this.numberValues = Array(this.project?.lastTaskNumber || 0)
      .fill(0)
      .map((e, i) => i + 1)
    this.tagValues = uniquifyAndSort(this.project?.aggregateData.itemTags || [], this.project?.tags || [])
    this.creatorValues = this.project?.aggregateData.itemCreatorNames || []
    this.assignerValues = this.project?.aggregateData.taskAssignerNames || []
    this.companyValues = this.project?.aggregateData.taskAssignerCompanyNames || []
    this.drawingValues = this.project?.aggregateData.drawingNames || []
    this.assigneeValues = this.project?.aggregateData.taskAssigneeNames || []
    this.reportNameValues = uniquifyAndSort(
      // @ts-ignore
      (this.items || []).map((it) => it.aggregateData.legacyReportName || null).filter((it) => it != null) || []
    )
  }

  itemSelected(item: Item) {
    return this.itemDialogService.openItem(item, this.project, this.projectMembers, this.userProjectRole)
  }

  get canSeeAllItems() {
    return (
      this.userProjectRole &&
      this.userProjectRole.canReadTargetRelations(Item.COLLECTION) &&
      this.userProjectRole.canReadTargetDocuments(Item.COLLECTION)
    )
  }

  get hasSelectedItems() {
    return this.selectedItems.length > 0
  }

  get hasItemsFlowFeature() {
    return this.currentCompany && this.currentCompany.features.includes(CompanyFeatures.FORMS_ITEMFLOW)
  }

  get hasBcfExportFeature() {
    return this.currentCompany && this.currentCompany.features.includes(CompanyFeatures.EXPORT_BCF)
  }

  get hasItemsFlowPermissions(): boolean {
    if (!this.userProjectRole) {
      return false
    }

    return [RoleType.PROJECT_ADMINISTRATOR, RoleType.PROJECT_OWNER].includes(this.userProjectRole.roleType)
  }

  get areAllSelectedItemsAttachable(): boolean {
    return (this.selectedItems || []).every((item) => item.aggregateData.legacyReportUid == null)
  }

  async exportToCsv() {
    const csvColumns: string[] = await this.projectDialogService.showCsvExportDialog()
    // csvColumns being null indicates that the user either clicked "cancel" or outside of the dialog.
    // In that case, we don't proceed.
    if (csvColumns == null) {
      return
    }

    this.csvExportInProgress = true

    const exportGenerationData: ExportGenerationData = {
      name: "checkd_csv_export.csv",
      exportType: ExportType.ITEMS_CSV,
      items: this.selectedItems.map((item) => item.uid),
      csvColumns,
    }

    try {
      const result = await this.cloudFunctionsService.generateCSVExport(exportGenerationData)
      this.csvExportInProgress = false
      this.fileHandlerService.saveCsv(exportGenerationData.name, result.data)
    } catch (err) {
      this.csvExportInProgress = false
      this.messageService.add({ severity: "error", summary: "Error", detail: err.message })
    }
  }

  async exportToBcf() {
    const exportGenerationData: Partial<ExportGenerationData> = {
      exportType: ExportType.ITEMS_BCF,
      items: this.selectedItems.map((item) => item.uid),
    }

    const spinnerDialog = this.dialogService.showSpinner("Please wait while we generate your BCF export.")

    try {
      const batch = this.db.firestore.batch()

      this.selectedItems.forEach((it) => {
        this.timelineService.batchItemExportedToBCF(batch, this.currentUser, it)
      })

      const result: { downloadUrl: string } = await this.cloudFunctionsService.generateBcfExport(exportGenerationData).toPromise()
      spinnerDialog.close()
      if (!result.downloadUrl) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "An error happened while generating the BCF export. Please contact support.",
        })
      }

      this.dialogService.showShareableLink(
        "Your BCF export is ready! ",
        ["You can now download the file or share it with someone."],
        result.downloadUrl
      )

      await batch.commit()
    } catch (err) {
      spinnerDialog.close()
      this.messageService.add({ severity: "error", summary: "Error", detail: err.message })
    }
  }

  openImageGallery(item: Item) {
    return this.dialogService.openDialog(ItemImageGalleryDialogComponent, {
      data: { item },
    })
  }

  private showMessage(
    severity: "success" | "info" | "warn" | "error",
    message: string,
    options?: { headerMessage?: string; duration?: number }
  ) {
    let header = ""
    const duration = options?.duration ? options.duration : undefined

    if (!options?.headerMessage) {
      // tslint:disable-next-line:switch-default
      switch (severity) {
        case "success":
        case "info":
        case "error":
          // capitalize first letter
          header = severity.charAt(0).toUpperCase() + severity.slice(1)
          break
        case "warn":
          header = "Warning"
          break
      }
    } else {
      header = options.headerMessage
    }

    this.messageService.add({
      severity,
      summary: header,
      detail: message,
      life: duration,
    })
  }

  async itemActionClickedHandler(action: ActionTypeSelected) {
    switch (action) {
      case "create_report":
        if (!this.hasSelectedItems) {
          return this.showMessage("warn", "Select at least one item to create a report")
        }

        if (this.tooManyItemsForReportGeneration) {
          return this.showMessage("warn", "Can only generate reports with 300 items or less", { duration: 5000 })
        }

        await this.generateItemReport()
        break
      case "new_item":
        await this.createProjectItem()
        break
      case "export_to_CSV":
        if (!this.hasSelectedItems) {
          return this.showMessage("warn", "Select items to create a CSV export")
        }
        await this.exportToCsv()
        break

      case "export_to_BCF":
        if (!this.hasSelectedItems) {
          return this.showMessage("warn", "Select items to create a BCF export")
        }
        await this.exportToBcf()
        break

      case "attach_to_Report":
        if (!this.hasSelectedItems) {
          return this.showMessage("warn", "Select at least one item to attach to report")
        }

        if (this.bulkAttachItemsToReportInProgress) {
          return this.showMessage("info", "Bulk attach already in progress")
        }

        if (this.tooManyItemsForBulkActions) {
          return this.showMessage("warn", "Can only perform bulk actions on 75 items or less")
        }

        if (!this.areAllSelectedItemsAttachable) {
          return this.showMessage(
            "error",
            "One or more items are already attached to a report. You need to deselect these before you can attach the items.",
            { duration: 5000 }
          )
        }

        await this.bulkAttachItemsToReport()
        break
      case "assign":
        if (!this.hasSelectedItems) {
          return this.showMessage("warn", "Select at least one item to assign")
        }

        if (this.bulkAssignInProgress) {
          return this.showMessage("info", "Bulk assign already in progress")
        }

        if (this.tooManyItemsForBulkActions) {
          return this.showMessage("warn", "Can only perform bulk actions on 75 items or less")
        }

        await this.bulkAssign()
        break
      case "add_tags":
        if (!this.hasSelectedItems) {
          return this.showMessage("warn", "Select at least one item to add tags to")
        }

        if (this.bulkAssignInProgress) {
          return this.showMessage("info", "Bulk add tags is already in progress")
        }

        if (this.tooManyItemsForBulkActions) {
          return this.showMessage("warn", "Can only perform bulk actions on 75 items or less")
        }

        await this.bulkAddTags()
        break
      case "accept":
        if (!this.hasSelectedItems) {
          return this.showMessage("warn", "Select at least one item to accept")
        }

        if (this.bulkAcceptInProgress) {
          return this.showMessage("info", "Bulk accept already in progress")
        }

        if (this.tooManyItemsForBulkActions) {
          return this.showMessage("warn", "Can only perform bulk actions on 75 items or less")
        }
        await this.bulkAccept()
        break
      case "close":
        if (!this.hasSelectedItems) {
          return this.showMessage("warn", "Select at least one item to close")
        }

        if (this.bulkCloseInProgress) {
          return this.showMessage("info", "Bulk close already in progress")
        }

        if (this.tooManyItemsForBulkActions) {
          return this.showMessage("warn", "Can only perform bulk actions on 75 items or less")
        }
        await this.bulkClose()
        break
      default:
        const assertUnreachable: never = action
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  // ITEM OPERATIONS
  ///////////////////////////////////////////////////////////////////////////////

  async createProjectItem() {
    // @ts-ignore
    const { itemData, taskData, isInEditMode, dueDateChanged, selectedNextCustomer } = await this.itemDialogService.createProjectItem(
      this.currentUser,
      this.project,
      this.projectMembers
    )

    const { item } = await this.itemService.createProjectItem(this.currentUser, this.project, itemData, taskData)
    // const nextWorkOrder = await this.nextService.createNextProjectWorkorder(
    //   item.name as string,
    //   selectedNextCustomer.id,
    //   this.project.nextProjectId
    // )
    //
    // console.log(nextWorkOrder)
    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: `Created item ${item.name} in project ${this.project.name}!`,
      life: 5000,
    })
  }

  async duplicateItem(item: Item) {
    const result: IDuplicatonSettings = await this.dialogService.openDialog(ItemDuplicationDialogComponent, { data: { item } })

    if (result != null && result.numberOfDuplicates != null) {
      this.messageService.add({
        severity: "info",
        summary: "Info",
        detail: `Duplicating item ${item.number} ${result.numberOfDuplicates} time(s)...`,
      })

      const duplicationPromises = []

      for (let i = 0; i < result.numberOfDuplicates; i += 1) {
        duplicationPromises.push(this.itemService.duplicateProjectItem(item, this.project, this.currentUser))
      }

      await Promise.all(duplicationPromises)
      this.messageService.add({
        severity: "info",
        summary: "Info",
        detail: `Done duplicating item ${item.number} (${result.numberOfDuplicates} times)!`,
      })
    }
  }

  async onMenuOptionSelected(event: { action: ItemMenuActions; item: Item }) {
    switch (event.action) {
      case ItemMenuActions.REMOVE_ITEM:
        return this.itemService.disable(event.item)
      case ItemMenuActions.SET_AS_CLOSED:
        // NB: Bypasses workflow!
        return this.itemService.close(this.currentUser, event.item)
      case ItemMenuActions.DUPLICATE_ITEM:
        return this.duplicateItem(event.item)
      case ItemMenuActions.EDIT_DETAILS:
        return this.itemService.editItemDetails(event.item, this.currentUser, this.project)
      case ItemMenuActions.UPLOAD_IMAGE:
        return this.uploadImage(event.item)
      case ItemMenuActions.VIEW_IMAGES:
        return this.openImageGallery(event.item)
      case ItemMenuActions.ADD_COLLABORATOR:
        return this.addCollaborators(event.item)
      default: {
        return undefined
      }
    }
  }

  async addCollaborators(item: Item) {
    const assignee = await item.getAssignee()
    if (!assignee) {
      this.dialogService.confirm("Not possible", "There are no assignee assigned", true)
      // throw new Error('No assignee assigned')

      return
    }
    if (this.currentUser.uid !== assignee.uid) {
      this.dialogService.confirm("Not possible", "You are not assignee. Only assignees can add collaborators", true)
      // throw new Error('User is not assignee')

      return
    }
    if (item.status === "REJECTED") {
      this.dialogService.confirm("Not possible", "Assignee has rejected being assignee", true)

      return
    }
    if (item.status === "DELEGATED") {
      this.dialogService.confirm("Not possible", "Assignee has not accepted being assignee yet", true)

      return
    }

    const role: Role = this.currentUserCompanyRole
    const companyMembers: Person[] = this.currentCompanyMembers
    const companyAssociates: Person[] = this.currentCompanyAssociates
    const project: Project = this.project
    const companyName: string = this.currentCompany.name

    const inviteDialogResult = await this.dialogService.openDialog(ProjectInvitationDialogComponent, {
      data: {
        role,
        companyMembers,
        companyAssociates,
        projectName: project.data.name,
        companyName,
      },
      disableClose: true,
    })

    // If user doesn't add anyone
    if (inviteDialogResult === "" || inviteDialogResult === undefined) {
      return
    }

    // Need to merge associates and members to send to addCollaborators
    const collaboratorsAssociates: Person[] = inviteDialogResult.associates.map((associate: { person: any }) => associate.person)
    const collaboratorsMembers: Person[] = inviteDialogResult.members.map((member: { person: any }) => member.person)
    let collaborators: Person[]
    if (
      collaboratorsAssociates !== undefined &&
      collaboratorsMembers !== undefined &&
      collaboratorsMembers.length !== 0 &&
      collaboratorsAssociates.length !== 0
    ) {
      // Merge arrays
      collaborators = [...collaboratorsAssociates, ...collaboratorsMembers]
    } else if (collaboratorsAssociates !== undefined && collaboratorsAssociates.length !== 0) {
      // return associates
      collaborators = collaboratorsAssociates
    } else if (collaboratorsMembers !== undefined && collaboratorsMembers.length !== 0) {
      // return members
      collaborators = collaboratorsMembers
    } else {
      throw new Error("could not add collaborator")
    }

    await this.itemService.addCollaborators(collaborators, item, this.project, this.currentUser)
  }

  ///////////////////////////////////////////////////////////////////////////////
  // REPORT GENERATION
  ///////////////////////////////////////////////////////////////////////////////

  async generateItemReport() {
    const reportGenerationData: ReportGenerationData = await this.dialogService.openDialog(ReportGenerationDialogComponent, {
      data: {
        totalItemCount: this.projectItemsDisplayed$.value ? this.roleFilteredItems.length : this.items.length,
        filteredItemCount: this.selectedItems.length,
        project: this.project,
        creator: this.currentUser,
        reportType: "PROJECT",
      },
    })

    if (reportGenerationData == null) {
      return
    }

    reportGenerationData.items = Array.from(new Set(this.selectedItems.map((item) => item.uid)))

    if (reportGenerationData.name != null && reportGenerationData.name !== "") {
      const reportGenerationFunction = this.firebaseFunctions.httpsCallable("generateFieldReport")
      const spinnerDialog = this.dialogService.showSpinner("Generating report - please wait.")

      try {
        const result = await reportGenerationFunction(reportGenerationData).pipe(take(1)).toPromise()
        spinnerDialog.close()

        const reportSharingDialogCloseResponse = await this.dialogService.openDialog(ReportSharingDialogComponent, {
          data: {
            reportUid: result.reportUid,
            reportSharingLink: this.reportService.createReportSharingLink(result.reportUid, "FIELD"),
            showAnchor: true,
            closeOnCopyLink: false,
            message: `Report "${reportGenerationData.name}" is ready!`,
            project: this.project,
          },
        })

        if (reportSharingDialogCloseResponse?.shareByEmail) {
          await this.sendFieldReportEmails(result.reportUid)
        }
      } catch (err) {
        spinnerDialog.close()
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: 5000,
        })
      }
    }
  }

  async sendFieldReportEmails(reportUid: string) {
    const response = await this.formDialogService.showSendEmailDialog()
    const emails = response?.emails ?? []

    if (emails.length > 0) {
      const cfData = {
        reportDocumentPath: `reports/${reportUid}`,
        emails,
      }
      try {
        this.messageService.add({ severity: "info", summary: "Info", detail: "Sending emails.." })
        await this.cloudFunctionsService.sendPdfEmails(cfData)
        this.messageService.add({ severity: "info", summary: "Info", detail: "Emails sent" })
      } catch (error) {
        this.messageService.add({ severity: "error", summary: "Error", detail: "Error sending PDFs", life: 5000 })
      }
    } else {
      this.messageService.add({ severity: "warn", summary: "Warning", detail: "No emails specified", life: 5000 })
    }
  }

  async uploadImage(item: Item) {
    const result: FilestackUploadResult = await this.filestackService.pick({
      accept: ["image/jpeg", "image/png"],
      storeTo: { location: "gcs", path: "images/" },
    })
    await this.itemService.handleFilesUpload(item, result, this.currentUser)
    this.messageService.add({
      severity: "info",
      summary: "Info",
      detail: `Done uploading image for item ${item.number || ""}`,
    })
  }

  ///////////////////////////////////////////////////////////////////////////////
  // BULK ACTIONS
  ///////////////////////////////////////////////////////////////////////////////

  async bulkAssign() {
    const selectedAssignee = await this.dialogService.openDialog(PeopleListDialogComponent, {
      data: { people: this.projectMembers, title: "Select assignee" },
    })

    if (selectedAssignee != null) {
      try {
        this.bulkAssignInProgress = true
        const items = this.selectedItems
        const assigner = this.currentUser
        const assignerProjectRole = await this.roleHandlerService.getPersonProjectRole(assigner, this.project)
        this.messageService.add({
          severity: "info",
          summary: "Info",
          detail: `Assigning ${items.length} items...`,
        })
        await this.itemService.bulkAssign(assigner, selectedAssignee, assignerProjectRole, this.selectedItems)
        this.messageService.add({
          severity: "info",
          summary: "Info",
          detail: `Done assigning ${items.length} items!`,
        })
        this.bulkAssignInProgress = false
      } catch (err) {
        this.messageService.add({ severity: "error", summary: "Error", detail: err.message, life: 5000 })
        this.bulkAssignInProgress = false
      }
    }
  }

  async bulkAddTags() {
    const newTags: string[] = await this.dialogService.showTagSelectionDialogForItems(this.project.tags)
    const result = await this.dialogService.confirm(
      `Are you sure you want to add the tags: ${newTags.slice(0, 4)} ${newTags.length > 3 ? "..." : ""} to these items?`,
      `${this.selectedItems.length} items will be updated`
    )

    if (result) {
      try {
        this.bulkAddTagsInProgress = true
        this.messageService.add({
          severity: "info",
          summary: "Info",
          detail: `Adding tags to ${this.selectedItems.length} item(s)...`,
        })
        const userProjectRole = await this.roleHandlerService.getPersonProjectRole(this.currentUser, this.project)
        await this.itemService.bulkAddTags(newTags, this.selectedItems, userProjectRole, this.currentUser)
        this.messageService.add({
          severity: "info",
          summary: "Info",
          detail: `Done adding tags to ${this.selectedItems.length} item(s)!`,
        })
        this.bulkAddTagsInProgress = false
      } catch (err) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: 5000,
        })

        this.bulkAddTagsInProgress = false
      }
    }
  }

  async bulkClose() {
    const result = await this.dialogService.confirm(
      "Are you sure you want to close these items?",
      `${this.selectedItems.length} items will be closed`
    )

    if (result) {
      try {
        this.bulkCloseInProgress = true
        this.messageService.add({
          severity: "info",
          summary: "Info",
          detail: `Closing ${this.selectedItems.length} item(s)...`,
        })
        const userProjectRole = await this.roleHandlerService.getPersonProjectRole(this.currentUser, this.project)
        await this.itemService.bulkClose(this.currentUser, userProjectRole, this.selectedItems)
        this.messageService.add({
          severity: "info",
          summary: "Info",
          detail: `Done closing ${this.selectedItems.length} item(s)!`,
        })
        this.bulkCloseInProgress = false
      } catch (err) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: 5000,
        })

        this.bulkCloseInProgress = false
      }
    }
  }

  async bulkAccept() {
    const result = await this.dialogService.confirm(
      "Are you sure you want to accept these tasks?",
      `${this.selectedItems.length} tasks will be accepted`
    )

    if (result) {
      try {
        this.bulkAcceptInProgress = true
        this.messageService.add({
          severity: "info",
          summary: "Info",
          detail: `Accepting ${this.selectedItems.length} task(s)...`,
        })

        await this.itemService.bulkAccept(this.currentUser, this.selectedItems)

        this.messageService.add({
          severity: "info",
          summary: "Info",
          detail: `Done accepting ${this.selectedItems.length} task(s)!`,
        })

        this.bulkAcceptInProgress = false
      } catch (err) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: 5000,
        })

        this.bulkAcceptInProgress = false
      }
    }
  }

  async bulkAttachItemsToReport() {
    const selectedReport = await this.dialogService.showReportSelectionDialog()
    if (!selectedReport) {
      return
    }

    const result = await this.dialogService.confirm(
      "Are you sure you want to attach these items to this report?",
      `${this.selectedItems.length} items will be attached to "${selectedReport.name}". They will show up at the bottom of the report in the "Attached items" section.`
    )

    if (result) {
      try {
        this.bulkAttachItemsToReportInProgress = true
        this.messageService.add({
          severity: "info",
          summary: "Info",
          detail: `Attaching ${this.selectedItems.length} items to "${selectedReport.name}"`,
        })

        const userProjectRole = await this.roleHandlerService.getPersonProjectRole(this.currentUser, this.project)
        await this.reportService.attachItemsToReport(this.selectedItems, selectedReport, userProjectRole, this.currentUser)
        this.messageService.add({
          severity: "info",
          summary: "Info",
          detail: `Done attaching ${this.selectedItems.length} items!`,
        })

        this.bulkAttachItemsToReportInProgress = false
      } catch (err) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: 5000,
        })

        this.bulkAttachItemsToReportInProgress = false
      }
    }
  }

  get tooManyItemsForBulkActions() {
    return (this.selectedItems || []).length > this.maxItemsForBulkActions
  }

  get tooManyItemsForReportGeneration() {
    return (this.selectedItems || []).length > this.maxItemsForReportGeneration
  }

  get isProjectLockedOrArchived() {
    return this.project?.archived || this.project?.lockedByCheckd
  }

  ///////////////////////////////////////////////////////////////////////////////
  // ITEM FILTERING
  ///////////////////////////////////////////////////////////////////////////////

  resetFilters() {
    this.itemListComponent.clear()
  }

  onStatusFilterChange(filter: WorkflowStatusFilter) {
    this.statusFilter$.next(filter)
  }
}
