<mat-divider></mat-divider>

<mat-list>
  <div mat-subheader>Pending invitations</div>
  <mat-list-item *ngFor="let invitation of invitations; trackBy: trackByUid">
    <mat-icon matListItemIcon>{{ getIcon(invitation) }}</mat-icon>

    <div matListItemTitle>{{ getTarget(invitation) }}</div>
    <div matListItemLine>{{ invitation.createdAt / 1000 | amFromUnix | amTimeAgo }}</div>

    <button matListItemMeta *ngIf="canRemoveInvitations" mat-icon-button [matMenuTriggerFor]="invitationsMenu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #invitationsMenu="matMenu">
      <button mat-menu-item (click)="copyInvitationLink(invitation)">
        <mat-icon>content_copy</mat-icon>
        <span>Copy invitation link</span>
      </button>

      <button style="color: red" mat-menu-item (click)="removePendingInvitationClicked(invitation)">
        <mat-icon>delete</mat-icon>
        <span>Remove invitation</span>
      </button>
    </mat-menu>
  </mat-list-item>
</mat-list>
