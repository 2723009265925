import { Component, OnInit } from "@angular/core"
import { MatDialogRef, MatDialogModule } from "@angular/material/dialog"
import { Router } from "@angular/router"
import { UserService } from "../../services"
import { CheckdButtonComponent } from "../../checkd-ui/checkd-button/checkd-button.component"

@Component({
  selector: "checkd-sso-activation-success-dialog",
  templateUrl: "./sso-activation-success-dialog.component.html",
  styleUrls: ["./sso-activation-success-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule, CheckdButtonComponent],
})
export class SsoActivationSuccessDialogComponent implements OnInit {
  constructor(
    public userService: UserService,
    public router: Router,
    public dialogRef: MatDialogRef<SsoActivationSuccessDialogComponent>
  ) {}

  ngOnInit(): void {}

  public async goToLogin() {
    await this.userService.logout()
    await this.router.navigate(["/login"])
    this.dialogRef.close()
  }
}
