<div *ngIf="form" class="form-pages">
  <!-- COMPANY LOGO -->
  <img alt="company logo" *ngIf="logo" src="{{ logo }}" height="80" />

  <div class="form-header" *ngIf="form?.header?.values?.length > 0">
    <mat-card>
      <mat-card-content>
        <app-form-header
          (contentChange)="onHeaderContentsChange($event)"
          [element]="form?.header"
          [readOnly]="report?.readOnly || report?.archived || report?.lockedByCheckd"
        >
        </app-form-header>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="form?.details" class="form-details">
    <mat-card>
      <mat-card-content>
        <app-form-details
          (contentChange)="onDetailsContentsChange($event)"
          [element]="form?.details"
          [readOnly]="report?.readOnly || report?.archived || report?.lockedByCheckd || report?.status === 'CLOSED'"
        >
        </app-form-details>
      </mat-card-content>
    </mat-card>
  </div>

  <ng-container *ngIf="fetchAttachedItems">
    <ng-container *ngIf="attachedItems$ | async as attachedItems">
      <div *ngIf="attachedItems && attachedItems.length > 0" class="form-attached-items">
        <mat-card>
          <mat-card-content>
            <div class="attached-items-header-container">
              <p>Attached Items</p>
              <mat-icon (click)="openAttachedItemsInfoDialog()">info</mat-icon>
            </div>

            <checkd-item-list
              [restrictedItemMenuActions]="restrictedItemMenuActions"
              [additionalItemMenuActions]="additionalItemMenuActions"
              [isProjectLockedOrArchived]="report.readOnly"
              [tableFor]="'reports'"
              (itemSelected)="itemSelected.emit($event)"
              [hideNewItemButton]="true"
              [hideItemsSelectedText]="true"
              [hideItemsActions]="true"
              (menuOptionSelected)="itemMenuOptionSelected.emit($event)"
              [isItemListReady]="finishedLoadingAttachedItems$ | async"
              [items]="attachedItems"
            >
            </checkd-item-list>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </ng-container>
</div>
