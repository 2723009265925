import { AngularSignaturePadModule } from "@almothafar/angular-signature-pad"
import { CommonModule } from "@angular/common"
import { HttpClientJsonpModule } from "@angular/common/http"
import { NgModule } from "@angular/core"
import { FlexLayoutModule } from "@angular/flex-layout"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { GoogleMapsModule } from "@angular/google-maps"
import { MatBadgeModule } from "@angular/material/badge"
import { CheckdFormDialogService } from "@checkd-form/services/checkd-form-dialog.service"
import { ProgressDialogComponent } from "@dialogs"
import { GalleryModule } from "@ks89/angular-modal-gallery"
import { ExportService, GeocodingService, ImageService, PdfDataService } from "@services"
import { PdfViewerModule } from "ng2-pdf-viewer"
import { ClipboardModule } from "ngx-clipboard"
import { LightboxModule } from "ngx-lightbox"
import { DateFormatPipe, MomentModule } from "ngx-moment"
import { ImageModule } from "primeng/image"
import { CheckdCommonModule } from "../../dashboard/checkd-ui"
import { ItemsModule } from "../../dashboard/items/items.module"
import { ReportDrawingModule } from "../reports/drawing-report/report-drawing/report-drawing.module"
import { ReportBodyComponent } from "./components/report-body/report-body.component"
import { ReportFooterComponent } from "./components/report-footer/report-footer.component"
import { ReportHeaderComponent } from "./components/report-header/report-header.component"
import { ReportTopbarInfoComponent } from "./components/report-topbar-info/report-topbar-info.component"
import { ReportVisibilityToggleComponent } from "./components/report-visibility-toggle/report-visibility-toggle.component"
import { ReportPdfLinkDialogComponent } from "./dialogs/report-pdf-link-dialog/report-pdf-link-dialog.component"
import { ReportStatusPipe } from "./filters/report-status.pipe"
import { AddressElementComponent } from "./form-view/elements/address-element/address-element.component"
import { CameraElementComponent } from "./form-view/elements/camera-element/camera-element.component"
import { CheckboxCollectionElementComponent } from "./form-view/elements/checkbox-collection-element/checkbox-collection-element.component"
import { CheckboxElementComponent } from "./form-view/elements/checkbox-element/checkbox-element.component"
import { CheckboxRadioElementComponent } from "./form-view/elements/checkbox-radio-element/checkbox-radio-element.component"
import { CounterElementComponent } from "./form-view/elements/counter-element/counter-element.component"
import { DateElementComponent } from "./form-view/elements/date-element/date-element.component"
import { DefaultElementComponent } from "./form-view/elements/default-element/default-element.component"
import { DrawingElementComponent } from "./form-view/elements/drawing-element/drawing-element.component"
import { AddOptionDialogComponent } from "./form-view/elements/dropdown-element/add-option-dialog/add-option-dialog.component"
import { DropdownElementComponent } from "./form-view/elements/dropdown-element/dropdown-element.component"
import { DropdownItemElementComponent } from "./form-view/elements/dropdown-element/dropdown-item-element/dropdown-item-element.component"
import { EmptyElementComponent } from "./form-view/elements/empty-element/empty-element.component"
import { FieldReportElementComponent } from "./form-view/elements/field-report-element/field-report-element.component"
import { FormDetailsComponent } from "./form-view/elements/form-details/form-details.component"
import { FormElementComponent } from "./form-view/elements/form-element/form-element.component"
import { InfoDialogComponent } from "./form-view/elements/form-element/info-dialog/info-dialog.component"
import { FormHeaderComponent } from "./form-view/elements/form-header/form-header.component"
import { FormsItemsElementComponent } from "./form-view/elements/forms-items-element/forms-items-element.component"
import { GpsCoordinateElementComponent } from "./form-view/elements/gps-element/gps-coordinate-element/gps-coordinate-element.component"
import { GpsElementComponent } from "./form-view/elements/gps-element/gps-element.component"
import { ImageElementComponent } from "./form-view/elements/image-element/image-element.component"
import { InvisibleElementComponent } from "./form-view/elements/invisible-element/invisible-element.component"
import { ItemListElementComponent } from "./form-view/elements/item-list-element/item-list-element.component"
import { PointDescriptionModalDialogComponent } from "./form-view/elements/item-list-element/point-description-modal-dialog/point-description-modal-dialog.component"
import { ReportThumbnailRowComponent } from "./form-view/elements/item-list-element/report-thumbnail-row/report-thumbnail-row.component"
import { MainAndSubfieldElementComponent } from "./form-view/elements/main-and-subfield-element/main-and-subfield-element.component"
import { MainfieldElementComponent } from "./form-view/elements/main-and-subfield-element/mainfield-element/mainfield-element.component"
import { SubfieldElementComponent } from "./form-view/elements/main-and-subfield-element/subfield-element/subfield-element.component"
import { SignatureDialogComponent } from "./form-view/elements/signature-element/signature-dialog/signature-dialog.component"
import { SignatureElementComponent } from "./form-view/elements/signature-element/signature-element.component"
import { SliderElementComponent } from "./form-view/elements/slider-element/slider-element.component"
import { TableElementComponent } from "./form-view/elements/table-element/table-element.component"
import { TabularFormElementComponent } from "./form-view/elements/tabular-form-element/tabular-form-element.component"
import { TextElementComponent } from "./form-view/elements/text-element/text-element.component"
import { TextfieldElementComponent } from "./form-view/elements/textfield-element/textfield-element.component"
import { TimeElementComponent } from "./form-view/elements/time-element/time-element.component"
import { ToggleElementComponent } from "./form-view/elements/toggle-element/toggle-element.component"
import { PdfPreviewDialogComponent } from "./form-view/pdf-preview-dialog/pdf-preview-dialog.component"
import { LegacyReportCreationDialogComponent } from "./legacy-report-creation-dialog/legacy-report-creation-dialog.component"
import { LegacyTemplateEditDialogComponent } from "./legacy-template-edit-dialog/legacy-template-edit-dialog.component"
import { LegacyTemplateListComponent } from "./legacy-template-list/legacy-template-list.component"
import { FieldReportService } from "./services/field-report.service"
import { LegacyReportService } from "./services/legacy-report.service"

@NgModule({
  imports: [
    CommonModule,
    CheckdCommonModule,
    FlexLayoutModule,
    GoogleMapsModule,
    FormsModule,
    MomentModule,
    AngularSignaturePadModule,
    PdfViewerModule,
    ReportDrawingModule,
    ItemsModule,
    MatBadgeModule,
    GalleryModule,
    LightboxModule,
    ClipboardModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    ImageModule,
    FormHeaderComponent,
    FormDetailsComponent,
    FormElementComponent,
    DefaultElementComponent,
    CheckboxElementComponent,
    TimeElementComponent,
    DateElementComponent,
    GpsElementComponent,
    DropdownElementComponent,
    TextfieldElementComponent,
    GpsCoordinateElementComponent,
    DropdownItemElementComponent,
    SignatureElementComponent,
    MainAndSubfieldElementComponent,
    MainfieldElementComponent,
    SubfieldElementComponent,
    AddressElementComponent,
    SliderElementComponent,
    CameraElementComponent,
    TabularFormElementComponent,
    ToggleElementComponent,
    InvisibleElementComponent,
    ImageElementComponent,
    CheckboxCollectionElementComponent,
    EmptyElementComponent,
    CounterElementComponent,
    ReportStatusPipe,
    SignatureDialogComponent,
    AddOptionDialogComponent,
    ProgressDialogComponent,
    PdfPreviewDialogComponent,
    CheckboxRadioElementComponent,
    LegacyReportCreationDialogComponent,
    LegacyTemplateListComponent,
    FieldReportElementComponent,
    DrawingElementComponent,
    ItemListElementComponent,
    LegacyTemplateEditDialogComponent,
    InfoDialogComponent,
    ReportThumbnailRowComponent,
    PointDescriptionModalDialogComponent,
    ReportTopbarInfoComponent,
    ReportBodyComponent,
    ReportFooterComponent,
    ReportHeaderComponent,
    FormsItemsElementComponent,
    ReportVisibilityToggleComponent,
    ReportPdfLinkDialogComponent,
    TextElementComponent,
    TableElementComponent,
  ],
  providers: [
    ExportService,
    PdfDataService,
    ImageService,
    GeocodingService,
    LegacyReportService,
    FieldReportService,
    DateFormatPipe,
    CheckdFormDialogService,
  ],
  exports: [
    SignatureElementComponent,
    SignatureDialogComponent,
    FormElementComponent,
    FormHeaderComponent,
    FormDetailsComponent,
    ReportBodyComponent,
    ReportFooterComponent,
    ReportHeaderComponent,
    DropdownElementComponent,
  ],
})
export class CheckdFormModule {}
