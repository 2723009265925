import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { AngularFireFunctions } from "@angular/fire/compat/functions/"
import { ExportGenerationData, UserCompanyRemovalData, UserRegistrationData } from "@models/common"
import { IInvitationAcceptData } from "@models/common/invitation.interface"
import { EmptyError, lastValueFrom, Observable, timeout } from "rxjs"
import { catchError } from "rxjs/operators"
import { of as observableOf } from "rxjs"
import { IDocxGenerationOptions } from "../../../../functions/src/exports/docx/docx-generation-options.interface"
import { IProjectDuplicationCfPayload, IProjectDuplicationSettings } from "../../../../functions/src/projects/project-duplication.interface"
import { environment } from "../../../environments/environment"
import { IGeneralInsightsQueryParams } from "../features/insights/models"

export interface CloudFunctionResult {
  result: string
  data: any
}

export interface CloudFunctionCsvExportResult extends CloudFunctionResult {
  data: string
}

interface IUpdateFormsMemberCompanyInvitationData {
  invitationUid: string
  action: "accept" | "cancel"
}

@Injectable({
  providedIn: "root",
})
export class CloudFunctionsService {
  private _testFunction: (data: any) => Observable<any>
  private _generateReport: (data: any) => Observable<any>
  private _generateCSVExport: (data: any) => Observable<any>
  private _generateBCFExport: (data: any) => Observable<any>
  private _createAuthUserAndCompany: (data: UserRegistrationData) => Observable<{ success: boolean; error?: string }>
  private _acceptInvitation: (data: any) => Observable<any>
  private _subscribeToCompany: (data: any) => Observable<any>
  private _subscribeToTemplate: (data: any) => Observable<any>
  private _updateFormsTemplate: (data: { templateUid: string }) => Observable<any>
  private _removeCompanyUser: (data: any) => Observable<any>
  private companyInsights: (data: any) => Observable<any>
  private updateProjectArchiveState: any
  private duplicateProjectCf: (data: any) => Observable<any>
  private _sendPdfsEmails: (data: any) => Observable<any>
  private readonly _updateFormsMemberCompanyInvitation: (data: IUpdateFormsMemberCompanyInvitationData) => Observable<any>

  private _zipImages: (data: string[]) => Observable<any>
  private _integrationsNextProject: (data: any) => Observable<any>

  constructor(private fns: AngularFireFunctions, private http: HttpClient) {
    this._testFunction = fns.httpsCallable("testFunction")
    this._generateReport = fns.httpsCallable("generateReport")
    this._generateCSVExport = fns.httpsCallable("generateCSVExport", { timeout: 3200000 })
    this._generateBCFExport = fns.httpsCallable("generateBcfExport")
    this._createAuthUserAndCompany = fns.httpsCallable("user-registration")
    this._removeCompanyUser = fns.httpsCallable("user-removeCompanyUser")
    this.companyInsights = fns.httpsCallable("getCompanyInsights")
    this._acceptInvitation = fns.httpsCallable("invitations-acceptInvitation")
    this._updateFormsMemberCompanyInvitation = fns.httpsCallable("invitations-updateFormsMemberCompanyInvitation")
    this._zipImages = fns.httpsCallable("images-fetchImagesZip")
    this._subscribeToCompany = fns.httpsCallable("subscribeToCompany")
    this._subscribeToTemplate = fns.httpsCallable("subscribeToTemplate")
    this._sendPdfsEmails = fns.httpsCallable("sendPdfEmails", { timeout: 3200000 })
    this.updateProjectArchiveState = fns.httpsCallable("projects-updateArchivedState", { timeout: 3200000 })
    this.duplicateProjectCf = fns.httpsCallable("projects-duplicateProject", { timeout: 3200000 })
    this._integrationsNextProject = fns.httpsCallable("integrations-nextProject")
    this._updateFormsTemplate = fns.httpsCallable("updateFormsTemplate")
  }

  public integrationsNextProject(data: { userUid: string; path: string }) {
    return lastValueFrom(this._integrationsNextProject(data))
  }

  public generateCSVExport(exportGenerationData: ExportGenerationData) {
    return lastValueFrom(this._generateCSVExport(exportGenerationData))
  }

  public generateBcfExport(exportGenerationData: Partial<ExportGenerationData>) {
    return this._generateBCFExport(exportGenerationData)
  }

  public generateDocxFromItemReport(options: IDocxGenerationOptions) {
    return this.http.post(environment.DOCX_GENERATOR_CF, options).toPromise()
  }

  public createAuthUserAndCompany(data: UserRegistrationData) {
    return this._createAuthUserAndCompany(data)
  }

  public acceptInvitation(data: IInvitationAcceptData) {
    return this._acceptInvitation(data)
  }

  public zipImages(data: string[]) {
    return this._zipImages(data)
  }

  public getCompanyInsights(data: IGeneralInsightsQueryParams) {
    return this.companyInsights(data)
  }

  public removeCompanyUser(data: UserCompanyRemovalData) {
    return this._removeCompanyUser(data)
  }

  public updateProjectArchivedState(data: { projectUid: string; archived: boolean }) {
    return lastValueFrom(this.updateProjectArchiveState(data))
  }

  public sendPdfEmails(data: { reportDocumentPath: string; emails: string[] }) {
    return lastValueFrom(this._sendPdfsEmails(data))
  }

  public subscribeToCompany(data: { publisherCompanyUid: string }) {
    return this._subscribeToCompany(data)
  }

  public subscribeToTemplate(data: { templateUid: string }) {
    return this._subscribeToTemplate(data)
  }

  public updateFormsLibraryCompanyMemberInvite(data: IUpdateFormsMemberCompanyInvitationData) {
    return this._updateFormsMemberCompanyInvitation(data).pipe(
      timeout(10_000),
      catchError((err) => {
        if (err instanceof EmptyError) {
          return observableOf("Operation timed out")
        }

        return observableOf(err.message)
      })
    )
  }

  async duplicateProject(projectToDuplicateUid: string, duplicationSettings: IProjectDuplicationSettings) {
    const payload: IProjectDuplicationCfPayload = { projectToDuplicateUid, duplicationSettings }

    return lastValueFrom(this.duplicateProjectCf(payload))
  }

  updateFormsTemplate(templateUid: string) {
    return lastValueFrom(this._updateFormsTemplate({ templateUid }))
  }
}
