import { Component, EventEmitter, Input, Output } from "@angular/core"
import { LegacyTemplate } from "@models/common"
import { Company } from "@models/common/company"
import { CompanyFeatures } from "@models/common/company.interface"
import {
  IEditFormsTemplateDialogSaveData,
  EditFormsTemplateDialogComponent,
} from "../../../features/public-template-library/edit-forms-template-dialog/edit-forms-template-dialog/edit-forms-template-dialog.component"
import { Template } from "../../models/template"
import { LegacyTemplateService } from "../../services/legacy-template.service"
import { TemplateCreationService } from "../../services/template-creation.service"
import { PrimaryButtonDirective } from "../../../next-ui/button/primary-button.directive"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { NgIf, AsyncPipe } from "@angular/common"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "checkd-template-creation-action-bar",
  templateUrl: "./template-creation-action-bar.component.html",
  styleUrls: ["./template-creation-action-bar.component.scss"],
  standalone: true,
  imports: [FlexModule, NgIf, MatLegacyButtonModule, MatIconModule, EditFormsTemplateDialogComponent, PrimaryButtonDirective, AsyncPipe],
})
export class TemplateCreationActionBarComponent {
  @Input() isDraft: boolean = false
  @Input() currentCompany: Company

  legacyTemplate: LegacyTemplate
  _template: Template
  @Input()
  set template(template: Template) {
    this._template = template
    this.legacyTemplate = this.legacyTemplateService.transformTemplate(template)
  }
  get template(): Template {
    return this._template
  }

  @Output() onNewTemplate = new EventEmitter<void>()
  @Output() onSave = new EventEmitter<Template>()
  @Output() onPublish = new EventEmitter<IEditFormsTemplateDialogSaveData>()
  @Output() onEdit = new EventEmitter<void>()

  @Output() onExportJson = new EventEmitter<void>()

  editFormDialogOpen = false

  constructor(public templateCreationService: TemplateCreationService, private legacyTemplateService: LegacyTemplateService) {}

  get canExportToJson() {
    return (
      this.currentCompany &&
      this.currentCompany.features &&
      this.currentCompany.features.includes(CompanyFeatures.FORMS_TEMPLATE_JSON_EXPORT)
    )
  }

  get hasDescription() {
    return this.template.description && this.template.description.length > 0
  }
}
