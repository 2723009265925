import { Component, Input, OnInit } from "@angular/core"
import { FormsLibraryViewService } from "../../../../services/forms-library-view.service"
import { RippleModule } from "primeng/ripple"
import { ButtonModule } from "primeng/button"

@Component({
  selector: "checkd-template-cards-section",
  templateUrl: "./template-cards-section.component.html",
  styleUrls: ["./template-cards-section.component.scss"],
  standalone: true,
  imports: [ButtonModule, RippleModule],
})
export class TemplateCardsSectionComponent implements OnInit {
  constructor(private flViewService: FormsLibraryViewService) {}

  ngOnInit(): void {}

  @Input() sectionTitle!: string

  onBrowseAllTemplatesClicked() {
    // TODO: reset any filters here when merged with other tasks
    this.flViewService.toggleDisplayedComponent()
  }
}
