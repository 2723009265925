import { Component, Input, OnInit } from "@angular/core"
import { MomentModule } from "ngx-moment"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { NgIf } from "@angular/common"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "checkd-report-topbar-info",
  templateUrl: "./report-topbar-info.component.html",
  styleUrls: ["./report-topbar-info.component.scss"],
  standalone: true,
  imports: [FlexModule, NgIf, ExtendedModule, MomentModule],
})
export class ReportTopbarInfoComponent {
  @Input() creatorName: string
  @Input() projectTitle: string
  @Input() createdAt: number
  @Input() lastTimelineElementMessage: string
  @Input() templatePublicVersion: string | null
  @Input() reportStatus: string
}
