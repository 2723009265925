import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { Person, Project, ProjectUserData } from "@models/common"
import { Subject, Subscription } from "rxjs"
import { debounceTime, distinctUntilChanged } from "rxjs/operators"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { NgIf } from "@angular/common"

@Component({
  selector: "people-position-item",
  templateUrl: "./people-position-item.component.html",
  styleUrls: ["./people-position-item.component.scss"],
  standalone: true,
  imports: [NgIf, MatLegacyFormFieldModule, MatLegacyInputModule],
})
export class PeoplePositionItemComponent implements OnInit, OnDestroy {
  @Input() person: Person
  @Input() positionFor: Project
  @Input() canEdit: Boolean

  inputString$ = new Subject<string>()
  inputStringSubscription: Subscription
  position: string = ""
  private THROTTLE = 1000

  constructor() {
    this.inputStringSubscription = this.inputString$.pipe(debounceTime(this.THROTTLE), distinctUntilChanged()).subscribe((data) => {
      if (this.positionFor != null && this.person != null) {
        const projectUserData = { position: data } as ProjectUserData
        this.positionFor.setProjectUserData(this.person.uid, projectUserData)
      }
    })
  }

  ngOnInit() {
    const projectUserData = this.positionFor.getProjectUserData(this.person.uid)
    // @ts-ignore
    this.position = projectUserData != null ? projectUserData.position : ""
  }

  ngOnDestroy() {
    this.inputStringSubscription.unsubscribe()
  }
}
