import { Injectable, Optional } from "@angular/core"
import { AngularFireAnalytics } from "@angular/fire/compat/analytics"
import { Company } from "@models/common/company"
import { CheckdEventName, ICheckdEventData, TIMELINE_EVENT_MAP } from "@models/common/events"
import { Person } from "@models/common/person"
import { TimelineType } from "@models/common/timeline.interface"
import { Intercom } from "@supy-io/ngx-intercom"
import { environment } from "../../../environments/environment"

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  defaultIntercomData = {
    app_id: environment.INTERCOM_APP_ID,
    custom_launcher_selector: "#intercom",
    hide_default_launcher: true,
  }

  timelineEventMap = TIMELINE_EVENT_MAP

  constructor(
    @Optional() private intercom: Intercom | null,
    private angularFireAnalytics: AngularFireAnalytics,
  ) {}

  public async logEvent(eventName: CheckdEventName, data?: ICheckdEventData) {
    if (eventName === "none") {
      return
    }

    if (!this.intercom) {
      return
    }

    this.intercom.trackEvent(eventName, data)
    await this.angularFireAnalytics.logEvent(eventName, data)
  }

  public async logTimelineEvent(type: TimelineType, data?: ICheckdEventData) {
    return this.logEvent(this.timelineEventMap[type], data)
  }

  public updateIntercomData(user: Person, company: Company) {
    const companyData =
      company == null
        ? {}
        : {
            id: company.uid || "",
            name: company.name || "",
            email: company.email || "",
          }

    this.intercom?.update({
      ...this.defaultIntercomData,
      name: user.name || "",
      email: user.email || "",
      company: companyData,
      user_uid: user.uid,
    })
  }

  public initializeIntercom() {
    this.intercom?.boot(this.defaultIntercomData)
  }
}
