import { Component, forwardRef, OnInit } from "@angular/core"
import { DefaultElementComponent } from "../../default-element/default-element.component"
import { FormElementComponent } from "../../form-element/form-element.component"
import { NgFor } from "@angular/common"

@Component({
  selector: "app-mainfield-element",
  templateUrl: "./mainfield-element.component.html",
  styleUrls: ["./mainfield-element.component.scss"],
  standalone: true,
  imports: [NgFor, forwardRef(() => FormElementComponent)],
})
export class MainfieldElementComponent extends DefaultElementComponent {
  override ngOnInit() {}
}
