import { Component, OnDestroy, OnInit } from "@angular/core"
import { Router, RouterLink } from "@angular/router"
import { CompanyFeatures, LegacyTemplate } from "@models/common"
import { UserService } from "@services"
import { naturalSortObjectsByProperty } from "@services/utilities"
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs"
import { map, shareReplay } from "rxjs/operators"
import { FormsLibraryViewService } from "../../../../services/forms-library-view.service"
import { PublicTemplatesViewService } from "../../../../services/public-templates-view.service"
import { TagModule } from "primeng/tag"
import { SharedModule } from "primeng/api"
import { TableModule } from "primeng/table"
import { PrimaryButtonDirective } from "../../../../../../next-ui/button/primary-button.directive"
import { ProgressSpinnerModule } from "primeng/progressspinner"
import { NgIf, NgFor, NgSwitch, NgSwitchCase, AsyncPipe, DatePipe } from "@angular/common"
import { FormsLibrarySearchBarComponent } from "../forms-library-search-bar/forms-library-search-bar.component"

@Component({
  selector: "checkd-browse-all-templates",
  templateUrl: "./browse-all-templates.component.html",
  styleUrls: ["./browse-all-templates.component.scss"],
  standalone: true,
  imports: [
    FormsLibrarySearchBarComponent,
    NgIf,
    ProgressSpinnerModule,
    PrimaryButtonDirective,
    RouterLink,
    TableModule,
    SharedModule,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    TagModule,
    AsyncPipe,
    DatePipe,
  ],
})
export class BrowseAllTemplatesComponent implements OnInit, OnDestroy {
  readonly currentCompanyHasFormsFeature$ = this.userService.currentCompanyHasFeature(CompanyFeatures.FORMS)

  constructor(
    private publicTemplatesViewService: PublicTemplatesViewService,
    private router: Router,
    private userService: UserService,
    public flViewService: FormsLibraryViewService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  public async openTemplateInfo(templateUid: string) {
    await this.router.navigate([`/forms/templates/${templateUid}`])
  }

  trackTemplatesByUid(index: number, element: LegacyTemplate): string {
    return element.uid
  }

  cols = [
    { field: "name", header: "TITLE" },
    { field: "tags", header: "TAGS" },
    { field: "updatedAt", header: "LAST UPDATE" },

    // This column is temporarily removed until we find out a better way to the difference between subscribed templates
    // and templates owned by your company
    // { field: "type", header: "TYPE" },
    { field: "templateCreatorCompanyName", header: "CREATOR COMPANY" },
    { field: "published", header: "PUBLISHED" },
    { field: "addedToCompany", header: "ADDED TO COMPANY" },
  ]

  async navigateToTemplateLandingPage(rowData: LegacyTemplate) {
    await this.publicTemplatesViewService.navigateToLandingPageForTemplate(rowData.uid)
  }
}
