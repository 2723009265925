<div class="button-container">
  <button mat-icon-button (click)="onShareLink()">
    <mat-icon class="share-icon" svgIcon="share-outline"></mat-icon>
  </button>
  <span style="color: white; font-size: 13px; margin-top: -2px">Share</span>
</div>

<div class="button-container">
  <button mat-icon-button style="color: white" (click)="onPreviewPdf()">
    <mat-icon class="pdf-icon" svgIcon="download-pdf-outline"></mat-icon>
  </button>
  <span style="color: white; font-size: 13px; margin-top: -2px">Download</span>
</div>

<div class="button-container" *ngIf="hasReportLockFeature && !report?.archived && report?.status !== 'CLOSED'">
  <button [disabled]="!canLockAndUnlockReport" mat-icon-button (click)="onLockReport()">
    <mat-icon *ngIf="report?.readOnly" class="lock-icon" svgIcon="locked-outline"></mat-icon>
    <mat-icon *ngIf="!report?.readOnly" class="unlock-icon" svgIcon="unlocked-outline"></mat-icon>
  </button>
  <span style="color: white; font-size: 13px; margin-top: -2px">{{ report?.readOnly == false ? "Lock Form" : "Unlock Form" }}</span>
</div>

<div
  *ngIf="isFormChanged; else formIsNotChanged"
  class="button-container"
  style="background: #f3eeec; width: 53px; height: 53px; border-radius: 8.3px; margin-top: 5px"
>
  <button mat-icon-button style="color: white" (click)="onSaveForm()">
    <mat-icon class="save-icon" svgIcon="blue-circle-filled-esclamation"></mat-icon>
  </button>
  <span style="color: #415bd8; font-size: 13px; margin-top: -7px">Save</span>
</div>

<ng-template #formIsNotChanged>
  <div class="button-container">
    <button mat-icon-button disabled style="color: white">
      <mat-icon class="saved-icon" svgIcon="checkmark-filled"></mat-icon>
    </button>
    <span style="color: white; font-size: 13px; margin-top: -2px">Saved</span>
  </div>
</ng-template>
