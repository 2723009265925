import { Component, inject } from "@angular/core"
import { MatIconRegistry } from "@angular/material/icon"
import { DomSanitizer } from "@angular/platform-browser"
import { NavigationEnd, Router } from "@angular/router"
import { filter } from "rxjs/operators"

enum IconNamespaces {
  NOTIFICATIONS = "notifications",
  FORMS = "forms",
  PROJECT_VIEW = "project_view",
}

@Component({
  selector: "app-root",
  template: "<app-dashboard></app-dashboard>",
})
export class AppComponent {
  private readonly mdIconRegistry: MatIconRegistry = inject(MatIconRegistry)
  private readonly domSanitizer: DomSanitizer = inject(DomSanitizer)

  constructor() {
    this.registerIcons(this.mdIconRegistry, this.domSanitizer)
    inject(Router).events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(console.log)
  }

  registerIcons(mdIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    // SVG icons need their URLs to be explicitly trusted using the DomSanitizer
    mdIconRegistry.addSvgIcon("next-logo-full-white", domSanitizer.bypassSecurityTrustResourceUrl("assets/logos/next-logo-full-white.svg"))
    mdIconRegistry.addSvgIcon("next-logo-icon", domSanitizer.bypassSecurityTrustResourceUrl("assets/logos/next-logo-icon.svg"))
    mdIconRegistry.addSvgIcon("next-logo-full-navy", domSanitizer.bypassSecurityTrustResourceUrl("assets/logos/next-logo-full-navy.svg"))

    mdIconRegistry.addSvgIcon("buildingconstruct", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/buildingconstruct_icon.svg"))
    mdIconRegistry.addSvgIcon("project", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/projects_briefcase_icon.svg"))
    mdIconRegistry.addSvgIcon("project-dark", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/briefcase_dark.svg"))
    mdIconRegistry.addSvgIcon("briefcase", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/projects_briefcase_icon.svg"))
    mdIconRegistry.addSvgIcon("peoplehat", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/peoplehat_icon.svg"))
    mdIconRegistry.addSvgIcon("tag", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/Tag_icon.svg"))
    mdIconRegistry.addSvgIcon("ship", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/Ship_icon.svg"))
    mdIconRegistry.addSvgIcon("camera", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/Camera_icon.svg"))
    mdIconRegistry.addSvgIcon("twinkle", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/twinkle_white.svg"))
    mdIconRegistry.addSvgIcon("company", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/company_icon.svg"))
    mdIconRegistry.addSvgIcon("report", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/Report_icon.svg"))
    mdIconRegistry.addSvgIcon("email", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/mail_icon.svg"))
    mdIconRegistry.addSvgIcon("password", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/Password_icon.svg"))
    mdIconRegistry.addSvgIcon("no_reports", domSanitizer.bypassSecurityTrustResourceUrl("assets/images/no_reports.svg"))

    mdIconRegistry.addSvgIcon("tasklist_empty", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/tasklist_empty_icon.svg"))

    mdIconRegistry.addSvgIcon("tasklist_white", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/tasklist_empty_icon_white.svg"))

    mdIconRegistry.addSvgIcon("personscreen_empty", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/personscreen_empty_icon.svg"))

    mdIconRegistry.addSvgIcon(
      "drawingscreen_empty",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/drawingscreen_empty_icon.svg")
    )

    mdIconRegistry.addSvgIcon("reportlist_empty", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/reportlistempty_icon.svg"))

    mdIconRegistry.addSvgIcon("settings_edit_off", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/ic_create_white_24px.svg"))

    mdIconRegistry.addSvgIcon("settings_edit_on", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/ic_create_black_24px.svg"))

    mdIconRegistry.addSvgIcon("marker_status_open", domSanitizer.bypassSecurityTrustResourceUrl("assets/open.svg"))

    mdIconRegistry.addSvgIcon("user", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/User_icon.svg"))

    mdIconRegistry.addSvgIcon("send", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/Send_icon.svg"))

    mdIconRegistry.addSvgIcon("report-dark", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/reports_icon.svg"))

    mdIconRegistry.addSvgIcon("drawing-dark", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/drawings_icon.svg"))

    mdIconRegistry.addSvgIcon("item-dark", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/checkmark_icon.svg"))

    mdIconRegistry.addSvgIcon("calendar", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/Calendar_icon.svg"))

    mdIconRegistry.addSvgIcon("forms-report", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/forms_report_icon.svg"))

    mdIconRegistry.addSvgIcon("items-report", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/item_report_icon.svg"))

    // NOTIFICATIONS ICONS
    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.NOTIFICATIONS,
      "task-assigned",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/notifications_icons/delegated@1x.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.NOTIFICATIONS,
      "task-fixed",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/notifications_icons/fixed@1x.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.NOTIFICATIONS,
      "task-completed",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/notifications_icons/closed@1x.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.NOTIFICATIONS,
      "project-archived",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/notifications_icons/archived_project_icon.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.NOTIFICATIONS,
      "collaborator-added",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/notifications_icons/collaborator_icon@3x.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.NOTIFICATIONS,
      "fix-rejected",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/notifications_icons/reject_icon.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.NOTIFICATIONS,
      "project-invitation",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/notifications_icons/project_invitation.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.PROJECT_VIEW,
      "items",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/project_view/items.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.PROJECT_VIEW,
      "reports",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/project_view/reports.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.PROJECT_VIEW,
      "drawings",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/project_view/drawings.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.PROJECT_VIEW,
      "person",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/project_view/people.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.PROJECT_VIEW,
      "gallery",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/project-gallery/outline_gallery_icon.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.PROJECT_VIEW,
      "info",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/project_view/info.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.PROJECT_VIEW,
      "people_group",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/project_view/group.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.FORMS,
      "text-field",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/formsbuilder/textfield.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.FORMS,
      "checkbox",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/formsbuilder/checkbox.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.FORMS,
      "time",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/formsbuilder/time.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.FORMS,
      "main-and-subfield",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/formsbuilder/main_and_subfield.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.FORMS,
      "toggle",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/formsbuilder/toggle.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.FORMS,
      "camera",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/formsbuilder/camera.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.FORMS,
      "tabular",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/formsbuilder/tabular.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.FORMS,
      "item-element",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/formsbuilder/item_element.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.FORMS,
      "date",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/formsbuilder/date.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.FORMS,
      "dropdown",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/formsbuilder/dropdown.svg")
    )

    mdIconRegistry.addSvgIconInNamespace(
      IconNamespaces.FORMS,
      "signature",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/formsbuilder/signature.svg")
    )

    mdIconRegistry.addSvgIcon(
      "locked-outline",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/report-action-bar/locked-outline.svg")
    )
    mdIconRegistry.addSvgIcon(
      "unlocked-outline",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/report-action-bar/unlocked-outline.svg")
    )
    mdIconRegistry.addSvgIcon(
      "share-outline",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/report-action-bar/share-outline.svg")
    )
    mdIconRegistry.addSvgIcon(
      "download-pdf-outline",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/report-action-bar/download-pdf-outline.svg")
    )
    mdIconRegistry.addSvgIcon(
      "blue-circle-filled-esclamation",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/report-action-bar/blue-circle-filled-esclamation.svg")
    )
    mdIconRegistry.addSvgIcon(
      "checkmark-filled",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/report-action-bar/checkmark-filled.svg")
    )

    mdIconRegistry.addSvgIcon("camera_icon_white", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/camera_icon_white.svg"))

    mdIconRegistry.addSvgIcon("user_icon_white", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/user_icon_white.svg"))

    mdIconRegistry.addSvgIcon("send_icon_white", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/send_icon_white.svg"))

    mdIconRegistry.addSvgIcon("tag_icon_white", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/tag_icon_white.svg"))

    mdIconRegistry.addSvgIcon("coronavirus", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/coronavirus_02.svg"))

    mdIconRegistry.addSvgIcon("template_icon_grey", domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/template_icon_grey.svg"))
  }
}
