import { Component, Inject, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { Person } from "@models/common"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { NgIf, NgClass } from "@angular/common"

export interface IMemberRemovalDialogData {
  member: Person
  canRemoveMember: boolean
  selfRemove: boolean
  errorMsg: string
  numActiveItems: number
}

@Component({
  selector: "checkd-project-member-removal-dialog",
  templateUrl: "./project-member-removal-dialog.component.html",
  styleUrls: ["./project-member-removal-dialog.component.scss"],
  standalone: true,
  imports: [NgIf, MatDialogModule, NgClass, ExtendedModule],
})
export class ProjectMemberRemovalDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IMemberRemovalDialogData) {}

  ngOnInit() {
    return
  }

  get confirmButtonText(): string {
    if (!this.data.canRemoveMember) {
      return "Ok"
    }

    if (this.data.selfRemove) {
      return "Leave"
    }

    return "Remove"
  }
}
