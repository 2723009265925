import firebase from "firebase/compat/app"
import { OpenReport } from "../../reports/models/open-report"
import firestore = firebase.firestore
import { ModelInCollection } from "./collections"
import { Company } from "./company"
import { Drawing } from "./drawing"
import { Image } from "./image"
import { Invitation } from "./invitation"
import { Item } from "./item"
import { Project } from "./project"
import { Report } from "./report"
import { Task } from "./task"
import { Person } from "./person"
import { LegacyTemplate } from "./legacy-template"
import { LegacyReport } from "./legacy-report"

import { FieldReport } from "./field-report"
import { Notification } from "./notification"
import { DocumentReference } from "@angular/fire/compat/firestore"
import { COLLECTIONS } from "./collections.interface"
import { FormElement } from "@checkd-form/models/form-element"
import { ITemplateData } from "src/app/dashboard/template-creator/models/template"

export function createNewModelInstanceFrom<M extends ModelInCollection>(
  model: M,
  data: any,
  uid: string = "",
  ref: firestore.DocumentReference | null = null
) {
  return createNewModelInstance(model.collectionName, data, uid, ref)
}

export function createNewModelInstance(
  collectionName: string,
  data: any,
  uid: string = "",
  ref: firestore.DocumentReference | null = null
): any {
  switch (collectionName) {
    case COLLECTIONS.COMPANIES:
      return new Company(data, uid, ref ?? undefined)
    case COLLECTIONS.DRAWINGS:
      return new Drawing(data, uid, ref ?? undefined)
    case COLLECTIONS.IMAGES:
      return new Image(data, uid, ref ?? undefined)
    case COLLECTIONS.INVITATIONS:
      return new Invitation(data, uid, ref ?? undefined)
    case COLLECTIONS.ITEMS:
      return new Item(data, uid, ref ?? undefined)
    case COLLECTIONS.PROJECTS:
      return new Project(data, uid, ref ?? undefined)
    case COLLECTIONS.REPORTS:
      return new Report(data, uid, ref ?? undefined)
    case COLLECTIONS.TASKS:
      return new Task(data, uid, ref ?? undefined)
    case COLLECTIONS.USERS:
      return new Person(data, uid, ref ?? undefined)
    case COLLECTIONS.PEOPLE:
      return new Person(data, uid, ref ?? undefined)
    case COLLECTIONS.LEGACY_TEMPLATES:
      return new LegacyTemplate(data, uid, ref ?? undefined)
    case COLLECTIONS.LEGACY_REPORTS:
      return new LegacyReport(data, uid, ref ?? undefined)
    case COLLECTIONS.FIELD_REPORTS:
      return new FieldReport(data, uid, ref ?? undefined)
    case COLLECTIONS.NOTIFICATIONS:
      return new Notification(data, uid, ref ?? undefined)
    case COLLECTIONS.OPEN_REPORTS:
      return new OpenReport(data, uid, ref ?? undefined)
    default:
      throw new Error(`Unrecognized top-level collection name: ${collectionName}`)
  }
}

export function uniquifyModels(models: ModelInCollection[]) {
  let modelMap = {}

  for (const model of models) {
    // @ts-ignore
    modelMap[model.uid] = model
  }

  // @ts-ignore
  return Object.keys(modelMap).map((k) => modelMap[k])
}

export interface IModelPathComponents {
  collection: string
  uid: string
}

export function parseModelPath(model: ModelInCollection): IModelPathComponents {
  return parseDocumentReferencePath(model.ref!)
}

export function parseDocumentReferencePath(ref: DocumentReference): IModelPathComponents {
  if (ref == null || ref.path == null) {
    // @ts-ignore
    return null
  }

  const components = ref.path
    .split("/")
    .map((it) => it.trim())
    .filter((it) => it)

  return {
    collection: components[0],
    uid: components[1],
  }
}

//return an array of objects according to key, value, or key and value matching
export function getObjects(obj: any, key: string, val: string) {
  var objects: any[] = []
  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) continue
    if (typeof obj[i] == "object") {
      objects = objects.concat(getObjects(obj[i], key, val))
    }
    //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
    else if ((i == key && obj[i] == val) || (i == key && val == "")) {
      //
      objects.push(obj)
    } else if (obj[i] == val && key == "") {
      //only add if the object is not already in the array
      if (objects.lastIndexOf(obj) == -1) {
        objects.push(obj)
      }
    }
  }
  return objects
}
