import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core"
import { AngularFirestore, DocumentReference } from "@angular/fire/compat/firestore"
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { AlertTypes, COLLECTIONS, LegacyTemplate, Person, Project } from "@models/common"
import { ProjectService } from "@services"
import { naturalSortObjectsByProperty } from "@services/utilities"
import { combineLatest, ReplaySubject, Subscription } from "rxjs"
import { distinctUntilChanged, map, switchMap } from "rxjs/operators"
import { PrimaryButtonDirective } from "../../next-ui/button/primary-button.directive"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { MatLegacyOptionModule } from "@angular/material/legacy-core"
import { MatLegacySelectModule } from "@angular/material/legacy-select"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { NgIf, NgFor } from "@angular/common"

interface ICompanyGroup {
  companyName: string
  members: Person[]
}

export interface IProjectAlerts {
  recipient: DocumentReference
  deliveryMethod: "email"
  subject: string // collection
  forTarget: DocumentReference
}

@Component({
  selector: "checkd-project-forms-notifications-rules",
  templateUrl: "./project-forms-notifications-rules.component.html",
  styleUrls: ["./project-forms-notifications-rules.component.scss"],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgFor,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    MatLegacyOptionModule,
    MatLegacyButtonModule,
    MatIconModule,
    PrimaryButtonDirective,
  ],
})
export class ProjectFormsNotificationsRulesComponent implements OnInit, OnDestroy {
  private project$ = new ReplaySubject<Project>(1)
  private projectOwnersAndAdmins$ = new ReplaySubject(1)
  private companyTemplates$ = new ReplaySubject(1)

  private _project: Project
  @Input() set project(project: Project) {
    this._project = project
    this.project$.next(project)
  }

  @Input() set projectOwnersAndAdmins(people: Person[]) {
    if (people === null || people === undefined) {
      return
    }
    const companyGroups: { [key: string]: ICompanyGroup } = {}

    for (const person of people) {
      if (!companyGroups.hasOwnProperty(person.companyName)) {
        companyGroups[person.companyName] = { companyName: person.companyName, members: [] }
      }
      companyGroups[person.companyName].members.push(person)
    }

    this._projectOwnersAndAdminsOrderedByCompany = naturalSortObjectsByProperty(Object.values(companyGroups), "companyName")
    this.projectOwnersAndAdmins$.next(this._projectOwnersAndAdminsOrderedByCompany)
  }

  _projectOwnersAndAdminsOrderedByCompany: ICompanyGroup[] = []
  private subscriptions = new Subscription()

  private _companyTemplates: LegacyTemplate[] = []
  @Input()
  set companyTemplates(templates: LegacyTemplate[]) {
    if (templates === undefined) {
      return
    }
    this._companyTemplates = templates
    this.companyTemplates$.next(templates)
  }

  get companyTemplates() {
    return this._companyTemplates
  }

  @Input() projectTemplates: LegacyTemplate[] = []

  @Input() readOnly = true

  private notificationRules$ = combineLatest([this.project$, this.projectOwnersAndAdmins$, this.companyTemplates$]).pipe(
    switchMap(([project, _, __]) => this.projectService.listenToAlerts(project, AlertTypes.Forms)),
    map((alerts) => alerts.payload.get("alerts")),
    distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
  )

  readonly form = new UntypedFormGroup({
    rules: new UntypedFormArray([]),
  })

  constructor(
    private fb: UntypedFormBuilder,
    private db: AngularFirestore,
    public projectService: ProjectService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.notificationRules$.subscribe((rules) => this.updateRuleForms(rules)))
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  async onSaveClicked() {
    // mark as pristine right away to disable the save button to avoid double-clicks
    this.form.markAsPristine()
    const transformedRules: IProjectAlerts[] = this.ruleForms.getRawValue().map((rule) => {
      return {
        recipient: rule.member.ref,
        deliveryMethod: "email",
        subject: COLLECTIONS.REPORTS,
        forTarget: rule.forTarget.ref,
      }
    })

    await this.db.doc(this._project.ref!.path).collection(COLLECTIONS.ALERTS).doc(AlertTypes.Forms).set({ alerts: transformedRules })
  }

  get ruleForms(): UntypedFormArray {
    return this.form.get("rules") as UntypedFormArray
  }

  private findUserObject(uid: string): Person | undefined {
    for (const group of this._projectOwnersAndAdminsOrderedByCompany) {
      const user = group.members.find((member) => member.uid === uid)

      if (user) {
        return user
      }
    }

    return undefined
  }

  private updateRuleForms(data: IProjectAlerts[]) {
    this.ruleForms.clear()

    for (const rule of data ?? []) {
      const person = this.findUserObject(rule.recipient.id)
      const target = (this.companyTemplates || []).find((template) => template.uid === rule.forTarget.id)

      // template got removed or person is not in the project any more and the rule has not been removed elsewhere
      if (person === undefined || target === undefined) {
        console.error(`Error when loading notification rules from database: Person: ${person}, target: ${target}`)
        continue
      }

      const newRule = this.getEmptyNotificationRule()
      newRule.get("member")!.setValue(person)
      newRule.get("forTarget")!.setValue(target)

      this.ruleForms.push(newRule)
    }

    this.cdr.detectChanges()
  }

  addEmptyNotificationRule() {
    const rule = this.getEmptyNotificationRule()

    // Mark the required controls in this group as touched to trigger the mat-errors.
    rule.get("member")!.markAsTouched()
    rule.get("forTarget")!.markAsTouched()

    this.ruleForms.push(rule)
  }

  private getEmptyNotificationRule() {
    return this.fb.group({
      member: [{ value: null, disabled: this.readOnly }, [Validators.required]],
      deliveryMethod: [{ value: "email", disabled: true }],
      subject: [{ value: "FormsReport", disabled: true }],
      forTarget: [{ value: null, disabled: this.readOnly }, [Validators.required]],
    })
  }

  deleteNotificationRule(ruleIndex: number) {
    this.ruleForms.removeAt(ruleIndex)
    this.ruleForms.markAsDirty()
  }
}
