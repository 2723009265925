import { Component, OnInit } from "@angular/core"
import { DefaultElementComponent } from "../default-element/default-element.component"

@Component({
  selector: "app-address-element",
  templateUrl: "./address-element.component.html",
  styleUrls: ["./address-element.component.scss"],
  standalone: true,
})
export class AddressElementComponent extends DefaultElementComponent {
  override ngOnInit() {}
}
