import { Component, Input, OnInit } from "@angular/core"
import { isBetaElement, TemplateElement } from "../../models/template-elements/template-element"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyCardModule } from "@angular/material/legacy-card"
import { NgIf } from "@angular/common"

@Component({
  selector: "checkd-template-element",
  templateUrl: "./template-element.component.html",
  styleUrls: ["./template-element.component.scss"],
  standalone: true,
  imports: [NgIf, MatLegacyCardModule, MatIconModule, MatLegacyTooltipModule],
})
export class TemplateElementComponent implements OnInit {
  @Input() element: TemplateElement

  constructor() {
    return
  }

  ngOnInit() {
    return
  }

  readonly isBetaElement = isBetaElement
}
