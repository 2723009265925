import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms"
import { ErrorStateMatcher } from "@angular/material/core"
import { Invitation, UserRegistrationData } from "@models/common"
import { ICheckdAgreements } from "@models/common/agreements.interface"
import { CloudFunctionsService, SnackbarService, UserService } from "@services"
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { MatLegacyCardModule } from "@angular/material/legacy-card"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { NgIf } from "@angular/common"

// Informs the reactive form about *when* to inform the user that the second email input field doesn't match the first one
class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control?.invalid && control?.parent?.dirty)
    const invalidParent = !!(control?.parent?.invalid && control?.parent?.dirty)

    return invalidCtrl || invalidParent
  }
}

@Component({
  selector: "checkd-email-address-submission",
  templateUrl: "./email-address-submission.component.html",
  styleUrls: ["./email-address-submission.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatLegacyButtonModule,
    MatLegacyCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyCheckboxModule,
  ],
})
export class EmailAddressSubmissionComponent implements OnInit {
  @Input() invitation: Invitation
  @Input() checkdAgreements: ICheckdAgreements

  @Output() triggerLogin = new EventEmitter()

  checkEmailsMatch: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const email = group.get("email")!.value
    const confirmEmail = group.get("confirmEmail")!.value

    return email === confirmEmail ? null : { notSame: true }
  }

  checkPasswordsMatch: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const password = group.get("password")!.value
    const confirmPassword = group.get("confirmPassword")!.value

    return password === confirmPassword ? null : { notSame: true }
  }

  readonly matcher = new CustomErrorStateMatcher()
  formSubmissionInProgress = false
  formSubmissionDone = false

  readonly registrationForm = this.fb.group({
    emailGroup: this.fb.group(
      {
        email: ["", [Validators.required, Validators.email]],
        confirmEmail: "",
      },
      { validators: this.checkEmailsMatch }
    ),
    userName: ["", [Validators.required, Validators.pattern("^(?=\\s*\\S).*$")]], // no whitespaces only
    passwordGroup: this.fb.group(
      {
        password: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: "",
      },
      { validators: this.checkPasswordsMatch }
    ),
    agree: [false, [Validators.requiredTrue]],
  })

  get emailGroup() {
    return this.registrationForm.get("emailGroup")
  }

  get email() {
    return this.registrationForm.get("emailGroup.email")
  }

  get confirmEmail() {
    return this.registrationForm.get("emailGroup.confirmEmail")
  }

  get passwordGroup() {
    return this.registrationForm.get("passwordGroup")
  }

  get password() {
    return this.registrationForm.get("passwordGroup.password")
  }

  get confirmPassword() {
    return this.registrationForm.get("passwordGroup.confirmPassword")
  }

  get userName() {
    return this.registrationForm.get("userName")
  }

  constructor(
    private fb: UntypedFormBuilder,
    private cfs: CloudFunctionsService,
    private snackbar: SnackbarService,
    private userService: UserService
  ) {}

  ngOnInit(): void {}

  async onSubmitButtonClicked() {
    this.registrationForm.disable()
    this.formSubmissionInProgress = true

    const companyName = this.invitation.aggregateData?.["sourceCompanyName"] ?? "Untitled Company"
    const phone = this.invitation.targetPhoneNumber

    const data: UserRegistrationData = {
      company: companyName,
      password: this.password!.value,
      personData: {
        email: this.email!.value,
        name: this.userName!.value,
        fullName: this.userName!.value,
        phone,
        companyName,
      },
    }

    try {
      const result = await this.userService.createAuthUserAndUserData(data)

      if (!result) {
        throw new Error("An error occured during the user creation process. Please contact support")
      }

      this.formSubmissionDone = true
      await this.userService.sendEmailVerificationEmail()
    } catch (e) {
      this.snackbar.showMessage("An error occured during the user creation process. Please contact support")
      this.registrationForm.enable()
      this.formSubmissionInProgress = false

      return
    }

    // TODO: update invitation status?
  }
}
