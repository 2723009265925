import { Component, OnInit } from "@angular/core"
import { MatLegacyListModule } from "@angular/material/legacy-list"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { MatSidenavModule } from "@angular/material/sidenav"

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
  standalone: true,
  imports: [MatSidenavModule, ExtendedModule, MatLegacyListModule],
})
export class SidenavComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
