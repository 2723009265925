import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import {
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions,
} from "@angular/material/legacy-form-field"
import { MatGridListModule } from "@angular/material/grid-list"
import { Routes, RouterModule, Route } from "@angular/router"
import { FlexLayoutModule } from "@angular/flex-layout"
import { CheckdCommonModule } from "@checkd-ui"
import { DragDropModule } from "@angular/cdk/drag-drop"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { InputNumberModule } from "primeng/inputnumber"
import { EditFormsTemplateDialogModule } from "../features/public-template-library/edit-forms-template-dialog/edit-forms-template-dialog.module"

import { TemplateCreationComponent } from "./components/template-creation/template-creation.component"
import { LegacyReportPreviewComponent } from "./components/legacy-report-preview/legacy-report-preview.component"
import { TemplateCreationActionBarComponent } from "./components/template-creation-action-bar/template-creation-action-bar.component"
import { TemplateElementComponent } from "./components/template-element/template-element.component"
import { TemplateElementConfigComponent } from "./components/template-element-config/template-element-config.component"

import { TemplateCreationViewComponent } from "./views/template-creation-view/template-creation-view.component"
import { CheckdFormModule } from "@checkd-form"

import { SaveTemplateDialogComponent } from "./components/save-template-dialog/save-template-dialog.component"
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component"
import { TableCellConfigDialogComponent } from "./components/table-cell-config-dialog/table-cell-config-dialog.component"
import { ElementSelectionMenuComponent } from "./components/element-selection-menu/element-selection-menu.component"

const routes: Routes = [
  { path: ":templateUid", component: TemplateCreationViewComponent },
  { path: "", component: TemplateCreationViewComponent },
]

// makes input boxes etc in <mat-form-field> use the 'outline' look
const appearance: MatFormFieldDefaultOptions = {
  appearance: "outline",
}

const PRIME_NG_MODULES = [InputNumberModule]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FlexLayoutModule,
    DragDropModule,
    FormsModule,
    CheckdFormModule,
    CheckdCommonModule,
    MatGridListModule,
    ...PRIME_NG_MODULES,
    ReactiveFormsModule,
    EditFormsTemplateDialogModule,
    TemplateCreationViewComponent,
    TemplateCreationComponent,
    LegacyReportPreviewComponent,
    TemplateCreationActionBarComponent,
    TemplateElementComponent,
    TemplateElementConfigComponent,
    SaveTemplateDialogComponent,
    ConfirmDialogComponent,
    TableCellConfigDialogComponent,
    ElementSelectionMenuComponent,
  ],
  exports: [
    TemplateCreationViewComponent,
    TemplateCreationComponent,
    LegacyReportPreviewComponent,
    TemplateCreationActionBarComponent,
    TemplateElementComponent,
    TemplateElementConfigComponent,
    SaveTemplateDialogComponent,
    ConfirmDialogComponent,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
  ],
})
export class TemplateCreatorModule {}
