import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import {
  MatLegacyTabChangeEvent as MatTabChangeEvent,
  MatLegacyTabGroup as MatTabGroup,
  MatLegacyTabsModule,
} from "@angular/material/legacy-tabs"
import { PdfPreviewDialogComponent } from "@checkd-form/form-view/pdf-preview-dialog/pdf-preview-dialog.component"
import { Form } from "@checkd-form/models/form"
import { Company, LegacyReport, LegacyReportData } from "@models/common"
import { CompanyService, ExportService } from "@services"
import { Subscription } from "rxjs"
import { take, throttleTime } from "rxjs/operators"
import { Template } from "../../models/template"
import { TemplateSharingOption } from "@models/common/legacy-template.interface"
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { CheckdButtonComponent } from "../../../checkd-ui/checkd-button/checkd-button.component"
import { MatIconModule } from "@angular/material/icon"
import { FormElementComponent } from "../../../checkd-form/form-view/elements/form-element/form-element.component"
import { NgIf, NgFor } from "@angular/common"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"
import { MatLegacySlideToggleModule } from "@angular/material/legacy-slide-toggle"
import { MatLegacyCardModule } from "@angular/material/legacy-card"

export enum TemplateOptionsLogoType {
  TEMPLATE_LOGO = "TEMPLATE_LOGO",
  FOOTER_LOGO = "FOOTER_LOGO",
}

@Component({
  selector: "checkd-legacy-report-preview",
  templateUrl: "./legacy-report-preview.component.html",
  styleUrls: ["./legacy-report-preview.component.scss"],
  standalone: true,
  imports: [
    MatLegacyTabsModule,
    MatLegacyCardModule,
    MatLegacySlideToggleModule,
    MatLegacyTooltipModule,
    NgIf,
    NgFor,
    FormElementComponent,
    MatIconModule,
    CheckdButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyCheckboxModule,
  ],
})
export class LegacyReportPreviewComponent implements OnInit, OnDestroy {
  @Input() previewData: any[] = []
  @Input() template: Template

  @Input() company: Company

  @Output() onTextChanged = new EventEmitter<void>()
  @Output() uploadLogo = new EventEmitter<TemplateOptionsLogoType>()
  @Output() previewEnabledChanged = new EventEmitter<boolean>()

  @ViewChild("tabs", { static: true }) tabGroup: MatTabGroup
  logoTooltip: string = `
This logo will appear on all reports created from this template.
  `

  footerLogoTooltip: string = `
This logo will appear inside the footer of all reports created from this template.
  `

  readonly templateSharingOptions = TemplateSharingOption

  readonly MAX_PDF_FOOTER_LENGTH = 75
  templateOptionsForm: UntypedFormGroup
  subscriptions: Subscription = new Subscription()

  constructor(
    private dialogRef: MatDialog,
    private exportService: ExportService,
    private companyService: CompanyService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.templateOptionsForm = this.fb.group({
      footerText: this.template.pdfFooterText || "",
      enableAnonymousReports: this.template.enableAnonymousReports || false,
    })

    this.subscriptions.add(
      this.templateOptionsForm.valueChanges.subscribe((values) => {
        const enableAnonymousReports = values.enableAnonymousReports || false
        let footerTextValue = values.footerText.trim()

        // Set the new value to null in the DB so that the company's default PDF footer text will appear in the PDF's instead
        if (footerTextValue.length === 0) {
          footerTextValue = null
        }

        this.template.enableAnonymousReports = enableAnonymousReports
        this.template.pdfFooterText = footerTextValue
      })
    )

    this.subscriptions.add(
      this.templateOptionsForm.valueChanges
        // This prevents checking if templates are equal (an expensive operation) from running too often
        .pipe(throttleTime(2))
        .subscribe((_) => this.onTextChanged.emit())
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  get logo() {
    return this.template && this.template.logo ? this.template.logo : this.company && this.company.logo ? this.company.logo : ""
  }

  get footerLogo() {
    return this.template && this.template.footerLogo ? this.template.footerLogo : "assets/logos/next-logo-icon.svg"
  }

  get isTemplatePublisher() {
    return this.company && this.company.isTemplatePublisher
  }

  onTabChange(event: MatTabChangeEvent) {
    if (event.index === 2) {
      this.previewPdf()
    }
  }

  async previewPdf() {
    const legacyReportData = {
      headerTemplateData: "[]",
      detailTemplateData: JSON.stringify(this.previewData),
      reportName: this.template.name,
      name: this.template.name,
      status: "",
      description: "",
    } as LegacyReportData
    const form = Form.fromJson(new LegacyReport(legacyReportData, ""))
    const logoBase64 = await this.companyService.logoUrlToBase64(this.logo)
    // @ts-ignore
    const footerLogoBase64 = await this.companyService.logoUrlToBase64(this.template.footerLogo ? this.template.footerLogo : null)

    const pdfPreviewDialog = this.dialogRef.open(PdfPreviewDialogComponent, {
      data: {
        form,
        projectTitle: "",
        user: null,
        companyLogo: logoBase64,
        pdfFooterText: this.template.pdfFooterText || this.company.pdfFooter || "",
        templateVersion: this.template.publicVersion,
        footerLogo: footerLogoBase64,
      },
    })

    const result = await pdfPreviewDialog.afterClosed().pipe(take(1)).toPromise()

    switch (result) {
      case PdfPreviewDialogComponent.ACTION_CLOSE: {
        break
      }
      case PdfPreviewDialogComponent.ACTION_SAVE: {
        this.exportService.exportReportToPdf(
          form,
          "",
          null,
          // @ts-ignore
          null,
          logoBase64,
          null,
          null,
          null,
          this.template.pdfFooterText || this.company.pdfFooter || "",
          this.template.publicVersion,
          footerLogoBase64
        )
        break
      }
    }
    this.tabGroup.selectedIndex = 0
  }

  uploadLogoClicked() {
    this.uploadLogo.emit(TemplateOptionsLogoType.TEMPLATE_LOGO)
  }

  uploadFooterLogoClicked() {
    this.uploadLogo.emit(TemplateOptionsLogoType.FOOTER_LOGO)
  }
}
