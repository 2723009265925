import { Component, Inject, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"

@Component({
  selector: "checkd-project-descripton-modal-dialog",
  templateUrl: "./point-description-modal-dialog.component.html",
  styleUrls: ["./point-description-modal-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule],
})
export class PointDescriptionModalDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { description: string }) {}

  ngOnInit() {}
}
