import { chunk, flatten } from "lodash-es"
import * as moment from "moment-timezone"
import { take } from "rxjs/operators"
import { IMAGE_NOT_FOUND_URL } from "../../../../../functions/src/pdf/assets"
import { createImageCollageUrl } from "../../../../../functions/src/pdf/utilities"
import { convertWorkflowStateToStatus, Item, ItemReportData, ReportGenerationSettings } from "../../models/common"
import { ImageService } from "../../services/image.service"
import { orderImagesByNumberAndTimestamp } from "../../services/utilities"
import { ElementOptionCode } from "../../template-creator/models/template-elements/template-element"
import { checkBox } from "../../_templates/checkd-template/docdefinitions/checkbox.docdefinition"
import { formCombo, text } from "../../_templates/checkd-template/docdefinitions/general.docdefinition"
import { table, tableBody, tableCell, tableRow } from "../../_templates/checkd-template/docdefinitions/table.docdefinition"
import { PDF_DEFAULT_IMAGES } from "../../_templates/default-images"
import { FormElementOption } from "./form-element-option"
import { getCorrectOptionValue } from "./utilities"

///////////////////////////////////////////////////////////////////////////////
/// NB: Due to the way webpack handles its dependency graph, we have
/// to add all subclasses of FormElement in this file to ensure that
/// the class definition of FormElement exists before the subclasses
/// reference to it. At some later point we could fix this by making a
/// module. We could also try to setup a top-level index.ts that loads
/// the definitions first.
///////////////////////////////////////////////////////////////////////////////

const FONT_SIZES = {
  small: 10,
  medium: 16,
  large: 20,
} as const

const CHECKBOX_SIZES = {
  small: 20,
  medium: 24,
  large: 28,
}

export class FormElement {
  id: number
  name: string
  type: string
  value: any

  code?: string
  info?: string
  typeAlias?: string
  templateType?: string
  title?: string
  options?: FormElementOption[]
  values?: FormElement[]
  child?: FormElement[]
  rejected?: FormElement[]
  accepted?: FormElement[]

  addNew?: boolean
  filledBy?: string
  isMultiSelect?: boolean
  isVertical?: boolean
  sortOrder?: number
  condition?: boolean

  padding?: number = 0

  constructor(attrs: any = {}) {
    Object.assign(this, attrs)
  }

  // Used for compability with the Forms API
  public transformAttributes(): any {
    if (this.type === "empty") {
      return this.values!.map((elm) => elm.transformAttributes())
    }

    const formElementTransformed: any = {}

    formElementTransformed.id = this.id
    formElementTransformed.templateType = this.templateType
    formElementTransformed.title = this.title
    formElementTransformed.name = this.name
    formElementTransformed.code = this.code
    formElementTransformed.info = this.info
    formElementTransformed.type = this.type
    formElementTransformed.typeAlies = this.typeAlias
    formElementTransformed.value = this.value

    if (this.options) {
      formElementTransformed.options = this.options.length > 0 ? this.options.map((elm) => elm.transformAttributes()) : []
    }

    if (this.values) {
      formElementTransformed.values = this.values.length > 0 ? this.values.map((elm) => elm.transformAttributes()) : []
    }

    if (this.child) {
      formElementTransformed.child = this.child.length > 0 ? this.child.map((elm) => elm.transformAttributes()) : []
    }

    if (this.rejected) {
      formElementTransformed.rejected = this.rejected.length > 0 ? this.rejected.map((elm) => elm.transformAttributes()) : null
    }

    if (this.accepted) {
      formElementTransformed.accepted = this.accepted.length > 0 ? this.accepted.map((elm) => elm.transformAttributes()) : null
    }

    formElementTransformed.addNew = this.addNew
    formElementTransformed.filledBy = this.filledBy
    formElementTransformed.isMultiSelect = this.isMultiSelect
    formElementTransformed.isVertical = this.isVertical
    formElementTransformed.sortOrder = this.sortOrder
    formElementTransformed.condition = this.condition

    if (this instanceof FormsItemsElement) {
      formElementTransformed.items = this.items
      formElementTransformed.prefill = this.prefill
    }

    return formElementTransformed
  }

  getValue(value: any) {
    if (Array.isArray(this.value)) {
      return this.value.join(", ")
    }

    return this.value ? this.value : " "
  }

  public calculateWidth(): number {
    const width = 100
    if (this.options && this.options.length > 0) {
      for (const option of this.options) {
        switch (option.code) {
          case "quarter":
            if (getCorrectOptionValue(option.value)) {
              return 25
            }
            break
          case "quater":
            if (getCorrectOptionValue(option.value)) {
              return 25
            }
            break
          case "half":
            if (getCorrectOptionValue(option.value)) {
              return 50
            }
            break
          default:
            break
        }
      }
    }

    return width
  }

  public toPdfDocDefinition() {
    let docDefinition: any[] = []

    if (this.title) {
      const pdfData = {
        title: this.title ? this.title : "",
        value: this.getValue(this.value),
        width: this.calculateWidth(),
        type: "",
        padding: this.padding,
        bold: false,
      }

      if (this.options && this.options.length > 0) {
        for (const option of this.options) {
          switch (option.code) {
            case "hideTitle":
              pdfData.title = ""
              break
            case "bold":
              pdfData.bold = option.value === "true"
              break
            default:
              break
          }
        }
      }
      docDefinition.push(pdfData)
    }

    const childElements = (this.values ? this.values : []).concat(this.child ? this.child : [])

    docDefinition = docDefinition.concat(...childElements.map((v: FormElement) => v.toPdfDocDefinition()))

    return docDefinition
  }

  public increasePaddingForChildNodes() {
    const childElements = (this.values ? this.values : []).concat(this.child ? this.child : [])
    childElements.forEach((child) => {
      child.padding! += 1
      child.increasePaddingForChildNodes()
    })
  }

  public hasValue(): boolean {
    if (this.value == null) {
      return false
    }
    if (typeof this.value === "string" && !this.value.trim()) {
      return false
    }

    return true
  }

  static fromJson(element: any) {
    const formElement: FormElement = FormElement.createFormElementModel(element)

    // NB: There are some edge cases where a child/values property is
    // an array of arrays instead of an array of elements (e.g. the
    // tabularform 'child' proprty), so we need to check for those.
    //
    // NB2: Look out for loads of empty FormElement objects (from
    // empty arrays), if they become too many we might have a problem.
    if (Array.isArray(element)) {
      formElement.type = "empty"
      formElement.values = element.map((elm) => FormElement.fromJson(elm))

      return formElement
    }

    formElement.id = element.id
    formElement.name = element.name
    formElement.code = element.code
    formElement.type = element.type
    formElement.templateType = element.templateType
    formElement.typeAlias = element.typeAlies
    formElement.value = element.value
    formElement.title = element.title
    formElement.info = element.info

    formElement.options = element.options ? element.options.map((option: any) => FormElementOption.fromJson(option)) : []
    formElement.values = element.values ? element.values.map((value: any) => FormElement.fromJson(value)) : []
    formElement.child = element.child ? element.child.map((child: any) => FormElement.fromJson(child)) : []
    formElement.rejected = element.rejected ? element.rejected.map((rejected: any) => FormElement.fromJson(rejected)) : []
    formElement.accepted = element.accepted ? element.accepted.map((accepted: any) => FormElement.fromJson(accepted)) : []

    formElement.addNew = element.addNew
    formElement.filledBy = element.filledBy
    formElement.isMultiSelect = element.isMultiSelect
    formElement.isVertical = element.isVertical
    formElement.sortOrder = element.sortOrder
    formElement.condition = element.condition

    if (formElement instanceof FormsItemsElement) {
      const formsItemsElement = formElement as FormsItemsElement
      formsItemsElement.prefill = element.prefill || {}
      formsItemsElement.items = element.items && element.items.length > 0 ? element.items : []
    }

    if (formElement instanceof SignatureElement && element.imageBlob) {
      formElement.imageBlob = element.imageBlob
    }

    return formElement
  }

  static createFormElementModel(data: any) {
    const elementType = data.elementType || data.type || data.name
    const modelMap = {
      default: FormElement,
      signature: SignatureElement,
      checkbox: CheckBoxElement,
      camera: CameraElement,
      image: StaticImageElement,
      mainandsubfield: MainAndSubfieldElement,
      tabularform: TabularFormElement,
      yesno: ToggleElement,
      drawing: DrawingFormElement,
      itemlist: ItemListFormElement,
      formsitems: FormsItemsElement,
      table: TableElement,
      table_child: TableChildElement,
      text: TextElement,
      textfield: TextFieldElement,
      dropdown: DropdownFormElement,
      page_break: PageBreakElement,
    }
    // @ts-ignore
    const klass = elementType && modelMap.hasOwnProperty(elementType) ? modelMap[elementType] : modelMap.default

    return new klass()
  }
}

function getPdfMakeColumnWidths(input: "content" | "evenly") {
  // tslint:disable-next-line:switch-default
  switch (input) {
    case "content":
      return "auto"
    case "evenly":
      return "*"
  }
}

export class StaticImageElement extends FormElement {
  public override toPdfDocDefinition() {
    if (this.url.includes("http"))
      return [{ type: "staticimage", title: this.title, value: this.url || PDF_DEFAULT_IMAGES.imagePlaceHolder, width: 100 }]
    return []
  }

  get url(): string {
    const url = this.value
    if (!(url || "").trim()) {
      return ""
    }

    return url
  }
}

export class TableElement extends FormElement {
  constructor(attrs: any = {}) {
    super(attrs)
  }

  // TODO This is now a mess of a couple of hacks and a mix of method calls with and without side effects.
  //      Clean this up.
  override toPdfDocDefinition(): any[] {
    const tableColumnsOption = (this.options || []).find((option) => option.code === ElementOptionCode.TABLE_COLUMNS.toLowerCase())
    const tableColumnWidthOption = (this.options || []).find(
      (option) => option.code === ElementOptionCode.TABLE_COLUMN_WIDTHS.toLowerCase()
    )
    const tableColumnWidths = (((tableColumnWidthOption ? tableColumnWidthOption.value : []) as unknown as string[]) || []).map(
      // @ts-ignore
      getPdfMakeColumnWidths
    )

    const tableColumns = (tableColumnsOption && tableColumnsOption.value) || 2
    const childElements = Array.from(this.child ? this.child : [])
    const actualChildElements = childElements[0].values
    let childDocDefinitions = flatten(actualChildElements!.map((v) => v.toPdfDocDefinition()))
    this.processChildDocDefinitions(childDocDefinitions, actualChildElements!)
    childDocDefinitions = this.processColspan(childDocDefinitions)
    const chunkedChildDocDefinitions = chunk(childDocDefinitions, tableColumns as number)
    this.processRowspan(chunkedChildDocDefinitions)
    const docDefinition = {
      ...table(
        tableBody(...chunkedChildDocDefinitions),
        // tableBody(...rowProcessedChunkedChildDocDefinitions),
        { columnWidths: tableColumnWidths }
      ),
      type: "table",
      width: this.calculateWidth(),
    }

    return [docDefinition]
  }

  processRowspan(rows: any[][]) {
    const finalRow = rows[rows.length - 1]
    const maxRowspan = Math.max(...(finalRow || []).map((cell) => cell.rowSpan || 1)) - 1

    for (let i = 0; i < maxRowspan; i++) {
      rows.push([{ text: " ", colSpan: 1, rowSpan: 1 }])
    }
  }

  processColspan(childDocDefinitions: any) {
    const processedDocDefinitions = []

    for (const docDefinition of childDocDefinitions) {
      processedDocDefinitions.push(docDefinition)
      const colSpan = docDefinition.colSpan || 1
      if (colSpan > 1) {
        for (let i = 0; i < colSpan - 1; i++) {
          processedDocDefinitions.push(tableCell(text(" "), { colSpan: 1, rowSpan: 1 }))
        }
      }
    }

    return processedDocDefinitions
  }

  makeCheckboxes(elm: any) {
    const layout = {
      hLineWidth: (_: number) => 0,
      vLineWidth: (_: number) => 0,
      // paddingLeft: _ => 0,
      // paddingRight: _ => 0,
      // paddingTop: _ => 0,
      // paddingBottom: _ => 0,
    }

    const isVerticalOption = elm.options.find((e: any) => e.code === ElementOptionCode.ISVERTICAL.toLowerCase())
    const isVertical = (isVerticalOption && isVerticalOption.value) || false
    const checkboxSizeOption = elm.options.find((e: any) => e.code === ElementOptionCode.CHECKBOX_SIZE.toLowerCase())
    const checkboxSize =
      // @ts-ignore
      checkboxSizeOption && checkboxSizeOption.value ? CHECKBOX_SIZES[checkboxSizeOption.value as string] : CHECKBOX_SIZES.small

    const borderColorOption = elm.options.find((e: any) => e.code === ElementOptionCode.CHECKBOX_BORDER_COLOR.toLowerCase())

    const borderColor: string = borderColorOption && borderColorOption.value ? (borderColorOption.value as string) : "#000000"

    const chunkedCells = (elm.values || []).map((checkbox: any) => {
      const checkboxChecked = getCorrectOptionValue(checkbox.value)

      // @ts-ignore
      return [checkBox(checkbox.name || "", checkboxSize, checkboxChecked, checkboxChecked === false, borderColor)]
    })

    const rows = isVertical ? chunkedCells.map((c: any) => tableRow(...c)) : tableRow(...[].concat(...chunkedCells))

    const subTable = table(
      isVertical ? tableBody(...rows) : tableBody(rows),
      { layout } // <-- comment out this line if you need to see where the inner borders are
    )

    return subTable
  }

  processChildDocDefinitions(childDocDefinitions: any, childElements: FormElement[]) {
    for (let i = 0; i < childElements.length; i++) {
      try {
        // @ts-ignore
        const elm = childElements[i].child[0].values[0]

        const textFieldHeightOption = (elm.options || []).find((it) => it.code === ElementOptionCode.TEXT_FIELD_HEIGHT.toLowerCase())
        const height = textFieldHeightOption ? textFieldHeightOption.value : 15

        const hideTitleInPdfOptionElem = (elm.options || []).find((it) => it.code === ElementOptionCode.HIDE_TITLE_IN_PDF.toLowerCase())
        const hideTitleInPdf = hideTitleInPdfOptionElem ? hideTitleInPdfOptionElem.value : false
        const index = hideTitleInPdf ? i : i + 1

        switch (elm.type) {
          case "textfield":
          case "date":
          case "time": {
            const nextDD = childDocDefinitions[index]
            const rowSpan = nextDD.rowSpan
            const colSpan = nextDD.colSpan
            if (elm.value) {
              nextDD.text = elm.value || " "
            } else {
              childDocDefinitions[index] = {
                canvas: [{ x: 0, y: 0, w: 1, h: height, type: "formText", fillCellWidth: true }],
                rowSpan,
                colSpan,
              }
            }
            break
          }
          case "checkbox": {
            const nextDD = childDocDefinitions[index]
            const rowSpan = nextDD.rowSpan
            const colSpan = nextDD.colSpan

            /*
            // TODO: Rune shall fix this mess (make the background color be *inside* the checkboxes
            const outsideBGColor = elm.options.find(e => e.code === ElementOptionCode.CHECKBOX_BACKGROUND_COLOR_OUTSIDE.toLowerCase())
            const fillColor: string = outsideBGColor?.value ? outsideBGColor.value as string : '#FFFFFF'
             */

            childDocDefinitions[index] = {
              ...this.makeCheckboxes(elm),
              rowSpan,
              colSpan,
            }
            break
          }
          case "table": {
            // TODO
            break
          }
          case "image": {
            // TODO
            break
          }
          default:
            break
        }
      } catch (e) {}
    }
  }
}

export class TableChildElement extends FormElement {
  protected colSpan = 1
  protected rowSpan = 1

  constructor(attrs: any = {}) {
    super(attrs)
  }

  override toPdfDocDefinition(): any[] {
    if (this.options && this.options.length > 0) {
      for (const option of this.options) {
        switch (option.code) {
          case ElementOptionCode.TABLE_CHILD_ROW_SPAN.toString().toLowerCase():
            if (typeof option.value === "number") {
              this.rowSpan = option.value
            }
            break
          case ElementOptionCode.TABLE_CHILD_COL_SPAN.toString().toLowerCase():
            if (typeof option.value === "number") {
              this.colSpan = option.value
            }
            break
        }
      }
    }

    const childElements = this.child![0].values || []

    if (childElements.length < 1) {
      return [{ text: " ", rowSpan: this.rowSpan, colSpan: this.colSpan }]
    }

    const childPdfDocDefinitions = childElements.map((t) => this.generateTablePdfDocdefinition(t)).filter((it) => it)

    return childPdfDocDefinitions
  }

  generateTablePdfDocdefinition(formElement: FormElement) {
    try {
      switch (formElement.type) {
        case "textfield":
          return tableCell(text(formElement.title || " "), { rowSpan: this.rowSpan, colSpan: this.colSpan })
        case "checkbox":
          return tableCell(text(formElement.title || " "), { rowSpan: this.rowSpan, colSpan: this.colSpan })
        case "date":
          return tableCell(text(formElement.title || " "), { rowSpan: this.rowSpan, colSpan: this.colSpan })
        case "time":
          return tableCell(text(formElement.title || " "), { rowSpan: this.rowSpan, colSpan: this.colSpan })
        case "table":
          return this.processChildTableElement(formElement.toPdfDocDefinition()[0])
        // case 'image': return tableCell(text(`TODO Image in table`), { rowSpan: this.rowSpan, colSpan: this.colSpan} )
        case "image":
          return {
            image: formElement.value || PDF_DEFAULT_IMAGES.imagePlaceHolder,
            fit: [100, 100],
            rowSpan: this.rowSpan,
            colSpan: this.colSpan,
            alignment: "center",
          }
        case "text": {
          const defaultDocDefinition = formElement.toPdfDocDefinition()[0]

          const docDef = tableCell(
            {
              text: defaultDocDefinition.title,
              ...defaultDocDefinition.style,
            },
            {
              colSpan: this.colSpan,
              rowSpan: this.rowSpan,
            }
          )

          return docDef
        }

        default: {
          return tableCell(text(formElement.value || " "), { rowSpan: this.rowSpan, colSpan: this.colSpan })
        }
      }
    } catch (e) {
      console.error(e)

      return tableCell(text(" "), { rowSpan: this.rowSpan, colSpan: this.colSpan })
    }
  }

  processChildTableElement(tableDocDefinition: any) {
    const layout = tableDocDefinition.layout || {}
    const processedChildTableElement = {
      ...tableDocDefinition,
      margin: [0, 0],
      layout: {
        ...layout,
        paddingLeft: (_: number) => 0,
        paddingRight: (_: number) => 0,
        paddingTop: (_: number) => 0,
        paddingBottom: (_: number) => 0,
        hLineWidth: (i: number, node: any) => (i === 0 || i === node.table.body.length ? 0 : 1),
        vLineWidth: (i: number, node: any) => (i === 0 || i === node.table.body.length ? 0 : 1),
      },
    }

    return processedChildTableElement
  }
}

export class ImageElement extends FormElement {
  public imageBlob: string

  constructor(attrs: any = {}) {
    super(attrs)
  }

  get url(): string {
    return ImageElement.processUrl(this.value)
  }

  public convertImage() {
    /// #if environment=="web"
    ImageService.convertFileToDataURLviaFileReader(this.url)
      .pipe(take(1))
      .subscribe((imageBlob) => (this.imageBlob = imageBlob))
    /// #endif

    // TODO add cases for "cf" and "scripts" here if we need it
  }

  public override toPdfDocDefinition() {
    return [{ type: "image", title: this.title, value: this.url, width: 25, image: this.imageBlob }]
  }

  static isCompleteUrl(url: string) {
    return url.startsWith("http")
  }

  static processUrl(url: string) {
    if (!(url || "").trim()) {
      return ""
    }

    return url
  }
}

export class SignatureElement extends ImageElement {
  constructor(attrs: any = {}) {
    super(attrs)
  }

  public override toPdfDocDefinition() {
    if (this.value && this.value.length > 0) {
      return [{ type: "signature", title: this.title, value: this.url, width: 50, image: this.imageBlob }]
    }

    return []
  }
}

export class CameraElement extends FormElement {
  images: ImageElement[] = []

  public filestackApiKey: string
  public includeItemImageThumbnails: boolean = false
  public includeItemListImageSection: boolean = false

  constructor(attrs: any = {}) {
    super(attrs)
  }

  public override toPdfDocDefinition() {
    if (this.includeItemImageThumbnails) {
      const collageImages = this.images.map((image) => image.value)
      const collage = createImageCollageUrl(this.filestackApiKey, collageImages, { width: 500, height: 150, borderColor: "white" })

      return [{ type: "image", title: this.title, value: "", width: 100, collage: collage }]
    } else {
      const imageDocDefinitions = this.images.map((image) => image.toPdfDocDefinition()[0])
      // console.log("Calculated width for each image: ", this.calculateWidth())
      // imageDocDefinitions.forEach(imageDocDef => imageDocDef.width = 25)

      return Array.prototype.concat(...imageDocDefinitions)
    }
  }

  // Used from field-report-generator-pdf to create image elements out of all values in camera
  createImageElements() {
    const images = this.values!.map((v: FormElement) => CameraElement.createImageElement(v, this))
    this.images = Array.prototype.concat(...images)
  }

  public static createImageElement(element: FormElement, cameraElement: CameraElement) {
    const imageElement = new ImageElement()
    imageElement.value = element.value
    imageElement.title = cameraElement.title || element.title || ""

    return imageElement
  }
}

export class CheckBoxElement extends FormElement {
  constructor(attrs: any = {}) {
    super(attrs)
  }

  public override toPdfDocDefinition() {
    const [isVerticalOption, checkboxSizeOption, borderColorOption] = [
      (this.options || []).find((option) => option.code === ElementOptionCode.ISVERTICAL.toLowerCase()),
      (this.options || []).find((option) => option.code === ElementOptionCode.CHECKBOX_SIZE.toLowerCase()),
      (this.options || []).find((option) => option.code === ElementOptionCode.CHECKBOX_BORDER_COLOR.toLowerCase()),
    ]

    const isVertical = isVerticalOption ? getCorrectOptionValue(isVerticalOption.value) : false
    // TODO: fix type assertions
    const checkboxSize =
      // @ts-ignore
      checkboxSizeOption && checkboxSizeOption.value ? CHECKBOX_SIZES[checkboxSizeOption.value as string] : CHECKBOX_SIZES.small
    // @ts-ignore
    const fontSize = checkboxSizeOption && checkboxSizeOption.value ? FONT_SIZES[checkboxSizeOption.value as string] : FONT_SIZES.small

    const lineColor: string = borderColorOption && borderColorOption.value ? (borderColorOption.value as string) : "#000000"

    const hideTitleInPDFOptionElement = (this.options || []).find(
      (option) => option.code === ElementOptionCode.HIDE_TITLE_IN_PDF.toLowerCase()
    )
    const hideTitleOption = ((hideTitleInPDFOptionElement && hideTitleInPDFOptionElement.value) || false) as boolean

    let docDefinition = []
    const pdfData = {
      title: this.title ? this.title : "",
      type: this.type,
      width: 100,
      values: [] as any[],
      isvertical: isVertical,
      hideTitleInPdf: hideTitleOption,
      checkboxSize,
      fontSize,
      lineColor,
    }

    if (this.options && this.options.length > 0) {
      for (const option of this.options) {
        switch (option.code) {
          case "isvertical":
            if (option.value === "true") {
              pdfData.isvertical = true
            }
        }
      }
    }

    let childElements: any[] = []
    this.increasePaddingForChildNodes()
    for (const value of this.values!) {
      pdfData.values.push({ title: value.name, value: this.getValue(value.value) })
      if (this.getValue(value.value)) {
        childElements = childElements.concat(...(value.values ? value.values : []).concat(value.child ? value.child : []))
      }
    }

    docDefinition.push(pdfData)
    // this.increasePaddingForChildNodes()
    // let childElements =
    //   (this.values ? this.values : [])
    //     .concat(this.child ? this.child : []);

    docDefinition = docDefinition.concat(...childElements.map((v) => v.toPdfDocDefinition()))

    return docDefinition
  }

  override getValue(value: string) {
    return value === "true"
  }

  public override hasValue(): boolean {
    const childCheckboxes = this.values!.filter((checkbox) => checkbox.value !== null && checkbox.value === "true")

    return childCheckboxes.length > 0
  }
}

export class PageBreakElement extends FormElement {
  public override toPdfDocDefinition() {
    return [{ type: this.type, width: 100 }]
  }
}

export class MainAndSubfieldElement extends FormElement {
  constructor(attrs: any = {}) {
    super(attrs)
  }

  public override toPdfDocDefinition() {
    const textColorOption = (this.options || []).find((option) => option.code === ElementOptionCode.MAIN_SUB_FIELD_TEXT_COLOR.toLowerCase())

    const backgroundColorOption = (this.options || []).find(
      (option) => option.code === ElementOptionCode.MAIN_SUB_FIELD_BACKGROUND_COLOR.toLowerCase()
    )

    const colorOptions: { [key: string]: any } = {}

    if (textColorOption && textColorOption.value) {
      colorOptions["color"] = textColorOption.value
    }

    if (backgroundColorOption && backgroundColorOption.value) {
      colorOptions["fillColor"] = backgroundColorOption.value
    }

    const docDefinition = {
      title: this.title,
      value: this.info,
      type: this.type,
      width: 100,
    }

    return [{ ...docDefinition, ...colorOptions }]
  }
}

export class TabularFormElement extends FormElement {
  constructor(attrs: any = {}) {
    super(attrs)
  }

  public override toPdfDocDefinition() {
    let docDefinition = []

    const childElements = Array.from(this.child ? this.child : [])
    childElements.shift()

    childElements.forEach((child) => {
      child.padding! += 2
      child.increasePaddingForChildNodes()
    })
    if (childElements.length > 0) {
      docDefinition.push({
        title: this.title ? this.title : "",
        width: 100,
        type: "text",
        style: {
          fillColor: "#FFFFFF",
        },
      })
    }
    docDefinition = docDefinition.concat(...childElements.map((v) => v.toPdfDocDefinition()))

    return docDefinition
  }
}

export class FormsItemsElement extends FormElement {
  public prefill: {
    [key: string]: any
  }
  public items: string[] = []
  public itemMap: { [itemUid: string]: Item } = {}
  public itemImages: any[] = []
  public filestackApiKey: string
  public includeItemImageThumbnails: boolean = false
  public includeItemListImageSection: boolean = false

  constructor(attrs: any = {}) {
    super(attrs)
  }

  public addToItemMap(item: Item) {
    this.itemMap = { ...this.itemMap, [item.uid]: item }
  }

  public updateItemMap(reportItemMap: { [itemUid: string]: Item }) {
    this.itemMap = {}
    for (const itemUid of this.items || []) {
      if (reportItemMap[itemUid] != null) {
        this.itemMap[itemUid] = reportItemMap[itemUid]
      }
    }
  }

  public toItemList() {
    const itemListFormElement: ItemListFormElement = FormElement.fromJson({
      title: this.title || "",
      type: "itemlist",
      templateType: "typeitemlist",
      typeAlies: "Items",
      info: "",
      value: this.items
        .map((itemUid) => this.itemMap[itemUid])
        .filter((it) => it)
        .map((it) => it.data),
      values: [],
      options: this.options,
    }) as ItemListFormElement
    itemListFormElement.itemListImages = this.itemImages || []
    itemListFormElement.filestackApiKey = this.filestackApiKey
    itemListFormElement.includeItemImageThumbnails = this.includeItemImageThumbnails
    itemListFormElement.includeItemListImageSection = this.includeItemListImageSection
    return itemListFormElement
  }

  public override toPdfDocDefinition(): any[] {
    return this.toItemList().toPdfDocDefinition()
  }
}

export class TextElement extends FormElement {
  constructor(attrs: any = {}) {
    super(attrs)
  }

  public override toPdfDocDefinition() {
    const textColorOption = (this.options || []).find((option) => option.code === ElementOptionCode.TEXT_COLOR.toLowerCase())
    const textBackgroundColorOption = (this.options || []).find(
      (option) => option.code === ElementOptionCode.TEXT_BACKGROUND_COLOR.toLowerCase()
    )
    const fontSizeOption = (this.options || []).find((option) => option.code === ElementOptionCode.FONT_SIZE.toLowerCase())
    const boldOption = (this.options || []).find((option) => option.code === ElementOptionCode.FONT_BOLD.toLowerCase())
    const cursiveOption = (this.options || []).find((option) => option.code === ElementOptionCode.FONT_CURSIVE.toLowerCase())

    const color = (textColorOption && textColorOption.value) || "#000000"
    const fillColor = (textBackgroundColorOption && textBackgroundColorOption.value) || "#ffffff"
    // TODO: fix type assertions
    const fontSize =
      fontSizeOption && fontSizeOption.value && Object.keys(FONT_SIZES).includes(fontSizeOption.value as string)
        ? // @ts-ignore
          FONT_SIZES[fontSizeOption.value as string]
        : FONT_SIZES.small
    const bold = boldOption && boldOption.value ? getCorrectOptionValue(boldOption.value) : false
    const italics = cursiveOption && cursiveOption.value ? getCorrectOptionValue(cursiveOption.value) : false

    const width = this.calculateWidth()

    return [
      {
        type: "text",
        title: this.title,
        width,
        style: {
          color,
          fillColor,
          fontSize,
          bold,
          italics,
        },
      },
    ]
  }
}

export class TextFieldElement extends FormElement {
  constructor(attrs: any = {}) {
    super(attrs)
  }

  public override toPdfDocDefinition() {
    const fontSizeOptionFormElement = (this.options || []).find((option) => option.code === ElementOptionCode.FONT_SIZE.toLowerCase())
    const textFieldHeightOption = (this.options || []).find((option) => option.code === ElementOptionCode.TEXT_FIELD_HEIGHT.toLowerCase())
    const height = textFieldHeightOption ? textFieldHeightOption.value : 15

    const fontSizeOption = fontSizeOptionFormElement ? (fontSizeOptionFormElement.value as string) : null
    // @ts-ignore
    const fontSize: number = fontSizeOption && FONT_SIZES[fontSizeOption] ? FONT_SIZES[fontSizeOption] : FONT_SIZES.small

    const width = this.calculateWidth()

    const hideTitleInPDFOptionElement = (this.options || []).find(
      (option) => option.code === ElementOptionCode.HIDE_TITLE_IN_PDF.toLowerCase()
    )
    const hideTitleOption = ((hideTitleInPDFOptionElement && hideTitleInPDFOptionElement.value) || false) as boolean

    return [
      {
        type: "textfield",
        title: this.title,
        value: this.getValue(this.value),
        hideTitleInPdf: hideTitleOption,
        width,
        height,
        style: {
          fontSize,
        },
      },
    ]
  }
}

export class DropdownFormElement extends FormElement {
  constructor(attrs: any = {}) {
    super(attrs)
  }

  public override toPdfDocDefinition(): any[] {
    const docDefinition = {
      value: formCombo(
        this.getValue(this.value),
        (this.values || []).map((e) => e.name),
        true
      ),
      title: this.title,
      type: "dropdown",
      width: 100,
    }

    return [docDefinition]
  }
}

export class ToggleElement extends FormElement {
  constructor(attrs: any = {}) {
    super(attrs)
  }

  public override toPdfDocDefinition() {
    let docDefinition = []
    docDefinition.push({
      title: this.title,
      value: this.getValue(this.value),
      width: 100,
    })

    let childElements
    // Add all childs inside accepted
    if (getCorrectOptionValue(this.value) === true) {
      childElements = this.accepted ? this.accepted : []
    } else {
      childElements = this.rejected ? this.rejected : []
    }

    childElements.forEach((child) => {
      child.padding! += 1
      child.increasePaddingForChildNodes()
    })

    docDefinition = docDefinition.concat(...childElements.map((v) => v.toPdfDocDefinition()))

    return docDefinition
  }

  override getValue(value: string | number | boolean) {
    let toggleValue = ""
    switch (getCorrectOptionValue(value)) {
      case true:
        toggleValue = "Yes"
        break
      case false:
        toggleValue = "No"
        break
      default:
        break
    }

    return toggleValue
  }
}

export class DrawingFormElement extends FormElement {
  public drawingImage: string

  constructor(attrs: any = {}) {
    super(attrs)
  }

  public override toPdfDocDefinition() {
    let docDefinition = []

    if (this.drawingImage) {
      docDefinition.push({
        title: this.value.name,
        value: this.drawingImage ? this.drawingImage : "",
        type: this.type,
        width: 100,
      })
    }

    const childElements = this.values ? this.values : []
    docDefinition = docDefinition.concat(...childElements.map((v) => v.toPdfDocDefinition()))

    return docDefinition
  }
}

export class BreakingHeadingElement extends FormElement {
  override title: string

  constructor(attrs: any = {}) {
    super(attrs)
  }

  public override toPdfDocDefinition(): any[] {
    if (this.title == null || this.title.trim() === "") {
      return []
    }

    return [
      {
        title: this.title,
        type: "breaking-heading",
        width: 100,
      },
    ]
  }
}

export class ImageAppendixElement extends FormElement {
  public override title = "Images"
  public images: any[] = []

  constructor(attrs: any = {}) {
    super(attrs)
  }

  public override toPdfDocDefinition(): any[] {
    if (this.images == null || this.images.length < 1) {
      return []
    }

    const orderedItemListImages = orderImagesByNumberAndTimestamp(this.images)

    const images = orderedItemListImages.map((image) => {
      const date = (image.imageData || {}).createdAt
      const imageCreatedAt = date ? moment(new Date(date)).format("DD/MM/YYYY HH:mm") : ""

      return {
        title: image.number || "",
        value: image.image,
        status: image.status,
        createdAt: imageCreatedAt,
      }
    })

    const appendix = {
      title: this.title,
      type: "imageappendix",
      values: images,
      width: 100,
    }

    return [appendix]
  }
}

export class ItemListFormElement extends FormElement {
  public itemListImages: any[] = []
  public itemListImagesShortenedUrls = {}
  public filestackApiKey: string
  public includeItemImageThumbnails: boolean = false
  public includeItemListImageSection: boolean = false

  constructor(attrs: any = {}) {
    super(attrs)
  }

  getCorrectWorkflowStatus(type: string): string {
    const workflowType = convertWorkflowStateToStatus(type)

    return workflowType === null ? type : workflowType
  }

  public override toPdfDocDefinition() {
    const docDefinition = []
    const settings: ReportGenerationSettings = {
      projectName: true,
      drawing: true,
      drawingName: true,
      itemTitle: true,
      itemDescription: true,
      itemTags: true,
      taskStatus: true,
      creator: true,
      assignee: true,
      collaborators: true,
      createdDate: true,
      modifiedDate: true,
      company: true,
      dueDate: true,
      qrCode: true,
    }

    ;(this.options || []).map((option) => {
      // @ts-ignore
      if (settings[option.code]) {
        // @ts-ignore
        settings[option.code] = getCorrectOptionValue(option.value)
      }
    })

    const itemsReportData = (this.value || []) as ItemReportData[]
    const itemTable = {
      title: "",
      type: "itemlist",
      values: {
        header: [] as any[],
        body: [] as any[],
      },
      width: 100,
    }

    if (((this.options || []).find((option) => option.code === "include_title_in_pdf") || { value: null }).value === "true") {
      docDefinition.push({
        title: `${this.title} (${itemsReportData.length} items)`,
        width: 100,
        type: itemsReportData.length > 0 ? "itemlisttitle" : "itemlisttitle_no_items",
      })
    }

    itemsReportData
      .sort((item1, item2) => item1.number! - item2.number!)
      .map((item) => {
        const values = []

        if (settings.taskStatus) {
          itemTable.values.header.includes("ID\nStatus") == false ? itemTable.values.header.push("ID\nStatus") : ""
          const itemStatus = item.status != null ? this.getCorrectWorkflowStatus(item.status) : ""
          const itemNumber = item.number != null ? item.number : ""
          values.push(`${itemNumber}\n${itemStatus}`)
        }
        if (settings.itemTitle) {
          itemTable.values.header.includes("Title") == false ? itemTable.values.header.push("Title") : ""
          values.push(item.name != null ? item.name : "")
        }
        if (settings.itemDescription) {
          itemTable.values.header.includes("Description") == false ? itemTable.values.header.push("Description") : ""
          values.push(item.description != null ? item.description : "")
        }
        if (settings.itemTags) {
          itemTable.values.header.includes("Tags") == false ? itemTable.values.header.push("Tags") : ""
          values.push(item.tags != null ? item.tags.join(", ") : "")
        }
        if (settings.creator) {
          itemTable.values.header.includes("Created by") == false ? itemTable.values.header.push("Created by") : ""
          values.push(this.getCreatedBy(item))
        }
        if (settings.assignee) {
          itemTable.values.header.includes("Assignee") == false ? itemTable.values.header.push("Assignee") : ""
          values.push(this.getAssignee(item))
        }

        if (settings.drawingName) {
          itemTable.values.header.includes("Drawing") == false ? itemTable.values.header.push("Drawing") : ""
          values.push(this.getDrawingName(item))
        }

        if (settings.createdDate && settings.dueDate) {
          itemTable.values.header.includes("Created at\nDue at") == false ? itemTable.values.header.push("Created at\nDue at") : ""
          const created = item["createdAt"] != null ? moment(new Date(item["createdAt"])).format("DD/MM/YYYY HH:mm") : " "
          const due = item["dueDate"] != null ? moment(new Date(item["dueDate"])).format("DD/MM/YYYY HH:mm") : " "
          values.push(`${created}\n${due}`)
        } else {
          if (settings.createdDate) {
            itemTable.values.header.includes("Created date") == false ? itemTable.values.header.push("Created date") : ""
            values.push(item["createdAt"] != null ? moment(new Date(item["createdAt"])).format("DD/MM/YYYY HH:mm") : "")
          }

          if (settings.dueDate) {
            itemTable.values.header.includes("Due at") == false ? itemTable.values.header.push("Due at") : ""
            values.push(item["dueDate"] != null ? moment(new Date(item["dueDate"])).format("DD/MM/YYYY HH:mm") : "")
          }
        }

        const orderedCollageImages = orderImagesByNumberAndTimestamp(item.images || [])
          .map((image) => {
            if (ImageElement.isCompleteUrl(image.url)) return image.url
            return IMAGE_NOT_FOUND_URL
          })
          .filter((i) => i)

        const formsItemImages =
          this.itemListImages
            .filter((i) => i.number == item.number)
            .map((i: any) => {
              if (ImageElement.isCompleteUrl(i.imageData.url)) return i.imageData.url
              return IMAGE_NOT_FOUND_URL
            })
            .filter((i) => i) || []

        const collage = createImageCollageUrl(
          this.filestackApiKey,
          orderedCollageImages.length > 0 ? orderedCollageImages : formsItemImages,
          { width: 500, height: 150, borderColor: "white" }
        )

        return {
          type: "item",
          title: "",
          values,
          images: item.images,
          collage: this.includeItemImageThumbnails ? collage : null,
        }
      })
      .forEach((item) => {
        itemTable.values.body.push(item)
      })

    const images: { title: any; value: any; status: any; createdAt: string }[] = []

    const orderedItemListImages = orderImagesByNumberAndTimestamp(this.itemListImages)

    orderedItemListImages.map((itemImage) => {
      const date = itemImage.imageData.createdAt
      const imageCreatedAt = date ? moment(new Date(date)).format("DD/MM/YYYY HH:mm") : ""

      if (itemImage) {
        images.push({
          title: itemImage.number != null ? itemImage.number : "",
          value: itemImage.image,
          status: itemImage.status,
          createdAt: imageCreatedAt,
        })
      }
    })

    const itemImages = {
      title: "Appendix",
      type: "itemlistimages",
      values: images,
      width: 100,
    }

    itemTable.values.body.length > 0 ? docDefinition.push(itemTable) : ""
    if (this.includeItemListImageSection) {
      itemImages.values.length > 0 ? docDefinition.push(itemImages) : ""
    }

    return docDefinition
  }

  private getCreatedBy(item: ItemReportData) {
    if (item["aggregateData"]) {
      return item["aggregateData"].itemCreatorName ? item["aggregateData"].itemCreatorName : ""
    }

    return ""
  }

  private getAssignee(item: ItemReportData) {
    if (item["aggregateData"] && item["aggregateData"].taskAssigneeName) {
      return item["aggregateData"].taskAssigneeName
    }

    return ""
  }

  private getDrawingName(item: ItemReportData) {
    if (item["aggregateData"]) {
      const drawingName = item["aggregateData"].drawingName || ""
      const chunks = drawingName.match(/.{1,10}/g)

      return chunks ? chunks.join("\n") : ""
    }

    return ""
  }
}
