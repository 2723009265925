import { Component, forwardRef, OnInit } from "@angular/core"
import { DefaultElementComponent } from "@checkd-form/form-view/elements/default-element/default-element.component"
import { FormElementComponent } from "../form-element/form-element.component"
import { NgFor, NgIf } from "@angular/common"
import { MatExpansionModule } from "@angular/material/expansion"

@Component({
  selector: "checkd-table-element",
  templateUrl: "./table-element.component.html",
  styleUrls: ["./table-element.component.scss"],
  standalone: true,
  imports: [MatExpansionModule, NgFor, NgIf, forwardRef(() => FormElementComponent)],
})
export class TableElementComponent extends DefaultElementComponent implements OnInit {
  override ngOnInit(): void {}

  get tableChild() {
    // @ts-ignore
    if (Array.isArray(this.element?.child[0])) {
      // @ts-ignore
      return this.element?.child[0] ?? []
    }

    // @ts-ignore
    return this.element?.child[0].values ?? []
  }
}
