import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FlexLayoutModule } from "@angular/flex-layout"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule, Routes } from "@angular/router"
import { CheckdCommonModule } from "@checkd-ui"
import { ConfirmationService } from "primeng/api"
import { CardModule } from "primeng/card"
import { ConfirmDialogModule } from "primeng/confirmdialog"
import { DataViewModule } from "primeng/dataview"
import { DialogModule } from "primeng/dialog"
import { DynamicDialogModule } from "primeng/dynamicdialog"
import { InputTextModule } from "primeng/inputtext"
import { InputTextareaModule } from "primeng/inputtextarea"
import { MenuModule } from "primeng/menu"
import { MessagesModule } from "primeng/messages"
import { MultiSelectModule } from "primeng/multiselect"
import { PasswordModule } from "primeng/password"
import { RadioButtonModule } from "primeng/radiobutton"
import { ProgressSpinnerModule } from "primeng/progressspinner"
import { RippleModule } from "primeng/ripple"
import { SplitButtonModule } from "primeng/splitbutton"
import { TableModule } from "primeng/table"
import { ToastModule } from "primeng/toast"
import { TooltipModule } from "primeng/tooltip"
import { PublisherService } from "./services/publisher.service"
import { CompanyFormsViewComponent } from "./views/company-forms-view/company-forms-view.component"
import { FormLandingPageComponent } from "./views/form-landing-page/form-landing-page.component"
import { FormsLibraryViewComponent } from "./views/forms-library-view/forms-library-view.component"
import { CheckdFormModule } from "@checkd-form"
import { EditFormsTemplateDialogModule } from "./edit-forms-template-dialog/edit-forms-template-dialog.module"
import { FormsLibraryAcceptInvitationViewComponent } from "./views/forms-library-accept-invitation-view/forms-library-accept-invitation-view.component"
import { AcceptInvitationWithSignedInUserComponent } from "./components/accept-invitation-with-signed-in-user/accept-invitation-with-signed-in-user.component"
import { NotSignedInComponent } from "./components/not-signed-in/not-signed-in.component"
import { SignInComponent } from "./components/sign-in/sign-in.component"
import { SignUpComponent } from "./components/sign-up/sign-up.component"
import { MemberCompaniesPageComponent } from "./views/member-companies-page/member-companies-page.component"
import { PaginatorModule } from "primeng/paginator"
import { ConfirmPopupModule } from "primeng/confirmpopup"
import { SelectButtonModule } from "primeng/selectbutton"
import { TemplateUpdateDialogComponent } from "./components/template-update-dialog/template-update-dialog.component"
import { DividerModule } from "primeng/divider"
import { ChangelogDialogComponent } from "./components/changelog-dialog/changelog-dialog.component"
import { TagModule } from "primeng/tag"
import { SkeletonModule } from "primeng/skeleton"

import { TemplateShowcaseComponent } from "./views/forms-library-view/components/template-showcase/template-showcase.component"
import { BrowseAllTemplatesComponent } from "./views/forms-library-view/components/browse-all-templates/browse-all-templates.component"
import { FormsLibrarySearchBarComponent } from "./views/forms-library-view/components/forms-library-search-bar/forms-library-search-bar.component"
import { TagComponent } from "./views/forms-library-view/components/tag/tag.component"
import { CheckboxModule } from "primeng/checkbox"
import { SubscriptorCardComponent } from "./views/forms-library-view/components/subscriptor-card/subscriptor-card.component"
import { PublisherCardComponent } from "./views/forms-library-view/components/publisher-card/publisher-card.component"
import { TemplateCardComponent } from "./views/forms-library-view/components/template-card/template-card.component"
import { TemplateCardsSectionComponent } from "./views/forms-library-view/components/template-cards-section/template-cards-section.component"
import { FilterTemplatesComponent } from "./views/forms-library-view/components/filter-templates/filter-templates.component"
import { AccordionModule } from "primeng/accordion"

const PRIME_NG_MODULES = [
  CardModule,
  ConfirmDialogModule,
  DataViewModule,
  DialogModule,
  DynamicDialogModule,
  InputTextareaModule,
  InputTextModule,
  MenuModule,
  MultiSelectModule,
  PasswordModule,
  RadioButtonModule,
  RippleModule,
  PaginatorModule,
  SplitButtonModule,
  TableModule,
  ToastModule,
  TooltipModule,
  ProgressSpinnerModule,
  ConfirmPopupModule,
  SelectButtonModule,
  DividerModule,
  TagModule,
  CheckboxModule,
  AccordionModule,
  SkeletonModule,
]

const routes: Routes = [
  { path: "templates/:templateUid", component: FormLandingPageComponent },
  { path: "invitations/:invitationUid", component: FormsLibraryAcceptInvitationViewComponent },
  { path: "library", component: FormsLibraryViewComponent },
  { path: "company", component: CompanyFormsViewComponent },
  { path: "member-companies", component: MemberCompaniesPageComponent },
  { path: "", redirectTo: "company", pathMatch: "full" },
]

@NgModule({
  imports: [
    CommonModule,
    CheckdCommonModule,
    RouterModule.forChild(routes),
    FlexLayoutModule,
    ...PRIME_NG_MODULES,
    CheckdFormModule,
    FormsModule,
    ReactiveFormsModule,
    EditFormsTemplateDialogModule,
    MessagesModule,
    CompanyFormsViewComponent,
    FormsLibraryViewComponent,
    FormLandingPageComponent,
    FormsLibraryAcceptInvitationViewComponent,
    AcceptInvitationWithSignedInUserComponent,
    NotSignedInComponent,
    SignInComponent,
    SignUpComponent,
    TemplateUpdateDialogComponent,
    ChangelogDialogComponent,
    MemberCompaniesPageComponent,
    TemplateShowcaseComponent,
    BrowseAllTemplatesComponent,
    FormsLibrarySearchBarComponent,
    TagComponent,
    SubscriptorCardComponent,
    PublisherCardComponent,
    TemplateCardComponent,
    TemplateCardsSectionComponent,
    FilterTemplatesComponent,
  ],
  providers: [PublisherService, ConfirmationService],
  exports: [],
})
export class PublicTemplateLibraryModule {}
